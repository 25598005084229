
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import {
    Directive,
    Component,
    HostListener,
    OnDestroy,
    Input,
    ComponentRef,
    Injector,
    ComponentFactoryResolver,
    ViewContainerRef,
    ElementRef,
    ComponentFactory,
    Inject,
    Renderer2,
    TemplateRef,
    ChangeDetectorRef
  } from "@angular/core";
  import { DOCUMENT } from "@angular/common";
  import { PlacementArray, positionElements } from "positioning";
  import { CalendarEvent } from "calendar-utils";
  import { Observable, of, Subject, timer } from "rxjs";
  import { takeUntil, take, filter } from "rxjs/operators";
import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { CommonService } from "src/app/services/ common.service.";
import { ToastService } from "src/app/common/providers/toast.service";
import { CalendarRepository } from "../../repositories/calendar.repository";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { ElectronService } from "src/app/services/electron.service";
import { MailConstants } from "src/app/common/utils/mail-constants";
import * as moment from "moment-timezone";
import jstimezonedetect from "jstimezonedetect";
import { CalenderUtils } from "../../utils/calender-utils";
import { Store } from "@ngrx/store";
import { MailRootState } from "src/app/mail/store";
import { getOnlineStatus, getUserProfile, RootState } from "src/app/reducers";
import { CalendarState } from "src/app/reducers/calendar.reducer";
import { DatabaseService } from "src/app/services/db/database.service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { MatDialog } from "@angular/material/dialog";

  @Component({
    selector: "vp-calendar-tooltip-window",
    templateUrl: "./calendar-tooltip-window.component.html",
    styleUrls: ["./calendar-tooltip-window.component.scss"],

  })
  export class CalendarTooltipWindowComponent {
    @Input() contents: string;
    @Input() placement: string;
    @Input() event: CalendarEvent;
    @Input() customTemplate: TemplateRef<any>;
    currentLocal: string = "en";
    isAllDayMultiple: boolean = false;
  }

  @Directive({
    selector: "[vpCalendarTooltip]"
  })
  export class CalendarTooltipDirective implements OnDestroy {
    @Input("vpCalendarTooltip") contents: string; // tslint:disable-line no-input-rename
    @Input("tooltipPlacement") placement: PlacementArray = "auto"; // tslint:disable-line no-input-rename
    @Input("tooltipTemplate") customTemplate: TemplateRef<any>; // tslint:disable-line no-input-rename
    @Input("tooltipEvent") event: any; // tslint:disable-line no-input-rename
    @Input("tooltipAppendToBody") appendToBody: boolean; // tslint:disable-line no-input-rename
    @Input("tooltipDelay") delay: number | null = null; // tslint:disable-line no-input-rename

    private tooltipFactory: ComponentFactory<CalendarTooltipWindowComponent>;
    private tooltipRef: ComponentRef<CalendarTooltipWindowComponent>;
    private cancelTooltipDelay$ = new Subject();
    private isAlive$ = new Subject<boolean>();
    isMobileView: boolean = false;
    organizer: string = "";
    replyType: string = "";
    description: string = "";
    attendee: any[] = [];
    currentLanguage: string = "en";
    requestSubscription$: any;
    userEmail: string;
    isOnline = false;

    constructor(
      private elementRef: ElementRef,
      private injector: Injector,
      private appStore: Store<MailRootState | RootState>,
      private componentFactoryResolver: ComponentFactoryResolver,
      private viewContainerRef: ViewContainerRef,
      private breakpointObserver: BreakpointObserver,
      private commonService: CommonService,
      private toastService: ToastService,
      private calendarRepository: CalendarRepository,
      private changeDetectorRef: ChangeDetectorRef,
      private electronService: ElectronService,
      private store: Store<CalendarState>,
      private databaseService: DatabaseService,
      private matDialog: MatDialog,
      @Inject(DOCUMENT) private document //tslint:disable-line
    ) {
      this.store.select(getOnlineStatus).subscribe(res => {
        this.isOnline = res;
      });
      this.tooltipFactory = this.componentFactoryResolver.resolveComponentFactory(
        CalendarTooltipWindowComponent
      );
      this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.HandsetPortrait]).pipe(takeUntil(this.isAlive$))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
         this.isMobileView = true;
        } else {
          this.isMobileView = false;
        }
      });
      this.currentLanguage =  this.electronService.isElectron
      ? this.electronService.getFromStorage(MailConstants.MAIL_LANGUAGE) : localStorage.getItem(MailConstants.MAIL_LANGUAGE);

      this.appStore.select(getUserProfile).pipe(filter(v => !!v), takeUntil(this.isAlive$)).subscribe(res => {
        if (!!res) {
          this.userEmail = res.email;
        }
      });
    }

    ngOnDestroy(): void {
      this.hide();
    }

    @HostListener("mouseenter")
    onMouseOver(): void {
      if (this.isMobileView) {
        return;
      }

      const delay$: Observable<any> =
        this.delay === null ? timer(1500) : timer(this.delay);
        delay$.pipe(takeUntil(this.cancelTooltipDelay$)).subscribe(() => {
        const ev = <any> this.event;
        if (ev) {
          // console.log("CALENDARTOOLTIPEVENT: ", ev);
          const body = {
            id:   ev.invId,
            ridz: (!!ev.inst && !!ev.inst[0] && !!ev.inst[0].ridZ) ? ev.inst[0].ridZ : 0
          };
          const openId = !!ev.invId ? ev.invId : ev.id;
          this.databaseService.getAppointmentsById(openId).pipe(take(1)).subscribe((res: any) => {
              if (res?.isMapped) {
                const appt = res;
                let multipleAllday = false;
                setTimeout(() => {
                  if (multipleAllday) {
                    this.commonService.$eventData.next({ appointment: appt, event: body , multipleAllday: true, ev: this.event });
                  }
                  else {
                    this.commonService.$eventData.next({ appointment: appt, event: body });
                  }
                }, 100);
                this.organizer = "";
                this.replyType = "";
                this.description = "";
                this.attendee = [];
                if (appt.or) {
                  this.organizer = appt.or.a;
                }
                if (appt.reply) {
                  this.replyType = appt.reply[0].ptst;
                }
                this.description = this.getAppointmentBody(appt);
                if (this.description !== "") {
                  this.description = this.description.length > 100 ? this.description.substr(0, 95) + "..." : this.description;
                }
                if (this.event.isOrganizer) {
                  this.replyType = "AC";
                }
                if (appt.at && appt.at.length > 0) {
                  const particiapnt: any[] = [];
                  appt.at.map(apt => {
                    particiapnt.push({
                      email: apt.a,
                      ptst: apt.ptst
                    });
                  });
                  this.event.organizer = this.organizer;
                  this.event.replyType = this.replyType;
                  this.event.description = this.description;
                  this.event.attendee = particiapnt;
                  this.event.at = particiapnt;
                  this.changeDetectorRef.markForCheck();
                  let clientTimeZoneId = jstimezonedetect.determine().name();
                  clientTimeZoneId = clientTimeZoneId.replace("Asia/Calcutta", "Asia/Kolkata");
                  const inviteTimeZone = appt.startDateData[0].tz;
                  if (appt.startDateData) {
                    this.event.start = moment(appt.startDateData[0].d).toDate();
                    if (!!inviteTimeZone) {
                      if (clientTimeZoneId !== inviteTimeZone) {
                        this.event.start = MailUtils.convertDateToTimezone(
                          moment(appt.startDateData[0].d).toDate(),
                          inviteTimeZone,
                          clientTimeZoneId
                        );
                      }
                    } else {
                      this.event.start = moment(appt.startDateData[0].d).toDate();
                    }
                  }
                  if (appt.endDateData) {
                    if(appt?.allDay && appt?.endDateData[0]?.d !== appt?.startDateData[0]?.d) {
                      multipleAllday = true;
                    } else {
                      multipleAllday = false;
                      this.event.end = moment(appt.endDateData[0].d).toDate();
                      if (!!inviteTimeZone) {
                        if (clientTimeZoneId !== inviteTimeZone) {
                          this.event.end = MailUtils.convertDateToTimezone(
                            moment(appt.endDateData[0].d).toDate(),
                            inviteTimeZone,
                            clientTimeZoneId
                          );
                        }
                      } else {
                        this.event.end = moment(appt.endDateData[0].d).toDate();
                      }
                    }
                  }
                }
                if (appt?.endDateData) {
                  if(appt?.allDay && appt?.endDateData[0]?.d !== appt?.startDateData[0]?.d) {
                    multipleAllday = true;
                  } else {
                    multipleAllday = false;
                  }
                }
                this.changeDetectorRef.markForCheck();
                this.show();

              } else {
                if (this.isOnline) {
                  this.requestSubscription$ = this.commonService.getMsgRequest(body).pipe(take(1)).subscribe(res => {
                    const appt = this.calendarRepository.mapAppointmentFromMsg(res.m[0]);
                    let multipleAllday = false;
                    setTimeout(() => {
                      if (multipleAllday) {
                        this.commonService.$eventData.next({ appointment: appt, event: body , multipleAllday: true, ev: this.event });
                      }
                      else {
                        this.commonService.$eventData.next({ appointment: appt, event: body });
                      }
                    }, 100);
                    this.organizer = "";
                    this.replyType = "";
                    this.description = "";
                    this.attendee = [];
                    if (appt.or) {
                      this.organizer = appt.or.a;
                    }
                    if (appt.reply) {
                      this.replyType = appt.reply[0].ptst;
                    }
                    this.description = this.getAppointmentBody(appt);
                    if (this.description !== "") {
                      this.description = this.description.length > 100 ? this.description.substr(0, 95) + "..." : this.description;
                    }
                    if (this.event.isOrganizer) {
                      this.replyType = "AC";
                    }
                    if (appt.at && appt.at.length > 0) {
                      const particiapnt: any[] = [];
                      appt.at.map(apt => {
                        particiapnt.push({
                          email: apt.a,
                          ptst: apt.ptst
                        });
                      });
                      this.event.organizer = this.organizer;
                      this.event.replyType = this.replyType;
                      this.event.description = this.description;
                      this.event.attendee = particiapnt;
                      this.event.at = particiapnt;
                      this.changeDetectorRef.markForCheck();
                      let clientTimeZoneId = jstimezonedetect.determine().name();
                      clientTimeZoneId = clientTimeZoneId.replace("Asia/Calcutta", "Asia/Kolkata");
                      const inviteTimeZone = appt.startDateData[0].tz;
                      if (appt.startDateData) {
                        this.event.start = moment(appt.startDateData[0].d).toDate();
                        if (!!inviteTimeZone) {
                          if (clientTimeZoneId !== inviteTimeZone) {
                            this.event.start = MailUtils.convertDateToTimezone(
                              moment(appt.startDateData[0].d).toDate(),
                              inviteTimeZone,
                              clientTimeZoneId
                            );
                          }
                        } else {
                          this.event.start = moment(appt.startDateData[0].d).toDate();
                        }
                      }
                      if (appt.endDateData) {
                        if(appt?.allDay && appt?.endDateData[0]?.d !== appt?.startDateData[0]?.d) {
                          multipleAllday = true;
                        }
                        else {
                          multipleAllday = false;
                          this.event.end = moment(appt.endDateData[0].d).toDate();
                          if (!!inviteTimeZone) {
                            if (clientTimeZoneId !== inviteTimeZone) {
                              this.event.end = MailUtils.convertDateToTimezone(
                                moment(appt.endDateData[0].d).toDate(),
                                inviteTimeZone,
                                clientTimeZoneId
                              );
                            }
                          } else {
                            this.event.end = moment(appt.endDateData[0].d).toDate();
                          }
                        }
                      }
                    }
                    if (appt?.endDateData) {
                      if(appt?.allDay && appt?.endDateData[0]?.d !== appt?.startDateData[0]?.d) {
                        multipleAllday = true;
                      }
                      else {
                        multipleAllday = false;
                      }
                    }
                    this.changeDetectorRef.markForCheck();
                    this.show();
                  }, error => {
                    this.toastService.showPlainMessage(error);
                  });
                }

              }

              return;
//            }

            // start
            /*
            setTimeout(() => {
              this.commonService.$eventData.next({ appointment: res.msg_request, event: body });
            }, 100);

            this.organizer = "";
            this.replyType = "";
            this.description = "";
            this.attendee = [];
            if (res.msg_request?.or) {
              this.organizer = res.msg_request.or.a;
            }
            if (res.msg_request?.reply) {
              this.replyType = res.msg_request.reply[0].ptst;
            }
            if (res.msg_request) {
              this.description = this.getAppointmentBody(res.msg_request);
            }
            if (this.description !== "") {
              this.description =  this.description.length > 100 ? this.description.substr(0, 95) + "..." : this.description;
            }
            if (this.event.isOrganizer) {
              this.replyType = "AC";
            }
            if (res.msg_request?.at && res.msg_request?.at.length > 0) {
              const particiapnt: any[] = [];
              res.msg_request.at.map( apt => {
                particiapnt.push({
                  email: apt.a,
                  ptst: apt.ptst
                });
              });
              this.event.organizer = this.organizer;
              this.event.replyType = this.replyType;
              this.event.description = this.description;
              this.event.attendee = particiapnt;
              this.event.at = particiapnt;
              this.changeDetectorRef.markForCheck();
              let clientTimeZoneId = jstimezonedetect.determine().name();
              clientTimeZoneId = clientTimeZoneId.replace("Asia/Calcutta", "Asia/Kolkata");
              const inviteTimeZone = res.msg_request.startDateData[0].tz;
              if (res.msg_request.startDateData) {
                this.event.start = moment(res.msg_request.startDateData[0].d).toDate();
                if (!!inviteTimeZone) {
                  if (clientTimeZoneId !== inviteTimeZone) {
                    this.event.start = MailUtils.convertDateToTimezone(
                      moment(res.msg_request.startDateData[0].d).toDate(),
                      inviteTimeZone,
                      clientTimeZoneId
                      );
                  }
                } else {
                  this.event.start = moment(res.msg_request.startDateData[0].d).toDate();
                }
              }
              if (res.msg_request?.endDateData) {
                this.event.end = moment(res.msg_request.endDateData[0].d).toDate();
                if (!!inviteTimeZone) {
                  if (clientTimeZoneId !== inviteTimeZone) {
                    this.event.end = MailUtils.convertDateToTimezone(
                      moment(res.msg_request.endDateData[0].d).toDate(),
                      inviteTimeZone,
                      clientTimeZoneId
                      );
                  }
                } else {
                  this.event.end = moment(res.msg_request.endDateData[0].d).toDate();
                }
              }
            }
            this.changeDetectorRef.markForCheck();
            this.show();

            */
          });

        }
      });
    }

    @HostListener("mouseleave")
    onMouseOut(): void {
      if (this.requestSubscription$) {
        this.requestSubscription$.unsubscribe();
      }
      this.hide();
    }



    private show(): void {
      this.event.organizer = this.organizer;
      this.event.replyType = this.replyType;
      this.event.description = this.description;
      this.event.attendee = this.attendee;
      if (!this.tooltipRef && this.event) {
        this.tooltipRef = this.viewContainerRef.createComponent(
          this.tooltipFactory,
          0,
          this.injector,
          []
        );
        this.tooltipRef.instance.contents = this.contents;
        this.tooltipRef.instance.customTemplate = this.customTemplate;
        this.tooltipRef.instance.event = this.event;
        if (!!this.currentLanguage && this.currentLanguage !== null) {
          this.tooltipRef.instance.currentLocal = this.currentLanguage;
        } else {
          this.tooltipRef.instance.currentLocal = "en";
        }
        if (this.appendToBody) {
          this.document.body.appendChild(this.tooltipRef.location.nativeElement);
        }
        if (this.event.allDay) {
          const diff = this.getDifferenceDay(this.event.start, this.event.end);
          if (diff > 0) {
            this.tooltipRef.instance.isAllDayMultiple = true;
          }
        }
        requestAnimationFrame(() => {
          this.positionTooltip();
        });
      }
    }

    private hide(): void {
      if (this.tooltipRef) {
        this.viewContainerRef.remove(
          this.viewContainerRef.indexOf(this.tooltipRef.hostView)
        );
        this.tooltipRef = null;
      }
      this.cancelTooltipDelay$.next(true);
      this.isAlive$.next(false);
      this.isAlive$.complete();
    }

    private positionTooltip(previousPosition?: string): void {
      if (this.tooltipRef) {
        this.tooltipRef.changeDetectorRef.detectChanges();
        this.tooltipRef.instance.placement = positionElements(
          this.elementRef.nativeElement,
          this.tooltipRef.location.nativeElement.children[0],
          this.placement,
          this.appendToBody
        );
        // keep re-positioning the tooltip until the arrow position doesn"t make a difference
        if (previousPosition !== this.tooltipRef.instance.placement) {
          this.positionTooltip(this.tooltipRef.instance.placement);
        }
      }
    }

    getDifferenceDay(start: any, end: any): any {
      const startDate = moment(start);
      const endDate = moment(end);
      const result = endDate.diff(startDate, "days");
      if (!!result) {
        return result;
      } else {
        return 0;
      }
    }

    getAppointmentBody(appointment: any): string {
      let body: string = "";
      if (appointment.descHTML) {
        body = appointment.descHTML;
      } else if (appointment.desc) {
        body = CalenderUtils.plainTextToHTML(appointment.desc);
      }
      if (body.indexOf("~*~*~*~*~*~*~*~*") > -1 || body.indexOf("~ ~ ~ ~ ~ ~ ~ ~ ~") > -1) {
        if (body.indexOf("*~*~*~*~*~*~*~*~*~*") !== -1) {
          body = body.replace("</body></html>", "").split("<div>*~*~*~*~*~*~*~*~*~*</div><br>")[1] ||
          body.split("*~*~*~*~*~*~*~*~*~*")[1] || "";
        }
        if (body.indexOf("~ ~ ~ ~ ~ ~ ~ ~ ~") !== -1) {
          body = body.replace("</body></html>", "").split("~ ~ ~ ~ ~ ~ ~ ~ ~")[1] || "";
        }
      }
      const description = MailUtils.getPlainText(body)
                .replace(/&#34;/g, "\"")
                .replace(/&gt;/g, ">")
                .replace(/&lt;/g, "<")
                .replace(/&#64;/g, "@");
      return description;
    }

  }
