
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div>
    <div class="header">
        {{'CALENDARS.SEND_UPDATE_TO_ATTENDEES' | translate}}
    </div>
    <div class="messagae-content">
        {{ 'CALENDARS.YOU_CHANGE_ATTENDEE_LIST' | translate }}:<br />
        {{ 'CALENDARS.ADDED_LBL' | translate }}:
    </div>
    <div class="attendee-list">
        <div *ngFor="let attendee of attendees">
            {{ attendee.a }}
        </div>
    </div>
    <div>
        <mat-radio-group [(ngModel)]="selectedOption">
            <mat-radio-button value="selected">{{ 'CALENDARS.SEND_UPDATE_ONLY_ADDED_REMOVE_ATTENDEE' | translate }}.
            </mat-radio-button>
            <mat-radio-button value="all">{{ 'CALENDARS.SEND_UPDATE_ALL_ATTENDEE' | translate }}.</mat-radio-button>
        </mat-radio-group>
    </div>
    <div class="submit-buttons">
        <button mat-button (click)="submit()">
            {{ 'COMMON.OK'| translate }}
        </button>
        <button mat-button (click)="close()">
            {{ 'CANCEL'| translate }}
        </button>
    </div>
</div>