
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, Inject, OnDestroy, NgZone, ViewChild, TemplateRef, ElementRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs/internal/Subject";
import { CalendarConfirmData } from "src/app/common/models/calendar.model";
import { CalendarConfirmTemplate } from "./../../common/utils/calendar-constants";

@Component({
  selector: "vp-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html"
})
export class ConfirmDialogComponent implements OnInit, OnDestroy {
  confirmTemplate: CalendarConfirmTemplate;
  CalendarConfirmTemplate = CalendarConfirmTemplate;

  selectedAppointmentTitle: string = "";
  isDeleteAppointmentIntanceOnly: boolean = true;

  private isAlive$ = new Subject<boolean>();

  constructor(
    private dialogRef: MatDialogRef<ConfirmDialogComponent>,
    private ngZone: NgZone,
    @Inject(MAT_DIALOG_DATA) public confirmArgs: CalendarConfirmData
  ) {
    this.confirmTemplate = CalendarConfirmTemplate[confirmArgs.confirmTemplate];
    this.selectedAppointmentTitle = "\"" + confirmArgs.event.title + "\"";
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  cancel(): void {
    this.close();
  }

  deleteCalendarAppointment() {
    this.dialogRef.close({ confirm: true, data: this.confirmArgs });
  }

  deleteAppointmentWithInstanceOrSeries() {
    this.dialogRef.close({ confirm: true, data: { isInstance: this.isDeleteAppointmentIntanceOnly, confirmArgs: this.confirmArgs } });
  }

  close(): void {
    this.dialogRef.close({ confirm: false });
  }
}
