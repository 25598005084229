
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div id="add_delegate_popup" class="mail_folder-create_dialog add-delegate-dialog">
  <div class="content add-delegate-content">
    <vnc-header
    class="message"
    [title]="!isEdit ? ('PREFERENCES.ADD_DELEGATE' | translate) : ('EDIT_DELEGATE_PERMISSIONS' | translate) + ' - ' + searchFromEmail.value"
    type="title-close"
    size="l"
    (onCloseClick)="closeDialog()"
    [showCloseIcon]="true"
    closeIconToolTip="{{ 'CLOSE' | translate }}">
  </vnc-header>
    <div class="search-share-data dialog-body" *ngIf="!isEdit">
      <mat-form-field class="input-field" appearance="outline">
        <mat-label>{{'EMAIL_LBL' | translate }}</mat-label>
        <input appearance="outline" type="text" [formControl]="searchFromEmail" matInput [matAutocomplete]="auto">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)" class="vnc_mail_autocomplete_panel" [autoActiveFirstOption]=true>
          <mat-option *ngFor="let emailItem of filteredFromEmails" [value]="emailItem">
            <span class="suggestion">
              <vp-avatar [type]="'mail-detail-avatar'" [user]="{firstLastCharacters: emailItem.email?.charAt(0), color: '#317bbc', email: emailItem.email}">
              </vp-avatar>
              <div class="email_name_division">
                <h3 class="disable-select">{{emailItem.name}}</h3>
                <p class="disable-select">{{emailItem.email}}</p>
              </div>
            </span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div>
    <div class="delegate-rights-checkbox">
      <vnc-checkbox label="{{ 'SENDAS' | translate }}" (change)="sendAs = $event" [checked]="sendAs"></vnc-checkbox>
      <vnc-checkbox label="{{ 'SENDONBEHALFOF' | translate }}" (change)="sendOnBehalfOf = $event" [checked]="sendOnBehalfOf"></vnc-checkbox>
    </div>
    <vnc-footer [secondaryLabel]="'CANCEL_MAIL_LABEL' | translate | uppercase"
    [primaryLabel]="'OK_LBL' | translate | uppercase"
    [isPrimaryDisabled]="!(searchFromEmail.value && searchFromEmail.value !== '') || (!sendAs && !sendOnBehalfOf)"
    [type]="'secondary-primary'"
    [color]="'white'"
    (onSecondaryClick)="closeDialog()"
    (onPrimaryClick)="AddDelegateAction()"></vnc-footer>
  </div>
</div>