
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-confirm-dialog">
  <div class="confirm-dialog-content" [ngSwitch]="confirmTemplate">
    <ng-template
      [ngTemplateOutlet]="deleteAppointmentConfirmTemplate"
      *ngSwitchCase="CalendarConfirmTemplate.CalendarAppointmentDeleteConfirm"
    ></ng-template>
    <ng-template
      [ngTemplateOutlet]="deleteAppointmentRecurShortcutConfirmTemplate"
      *ngSwitchCase="
        CalendarConfirmTemplate.CalendarAppointmentShortcutDeleteRecurConfirm
      "
    ></ng-template>
  </div>
</div>

<ng-template #deleteAppointmentConfirmTemplate>
  <div class="confirm-header" fxLayout="row" fxLayoutAlign="start center">
    <span>{{ "CALENDARS.DELETE_APPOINTMENT" | translate }}</span>
  </div>
  <div class="confirm-message" fxLayout="row" fxLayoutAlign="start center">
    <span>{{ "CALENDARS.DELETE_APPOINTMENT_MESSAGE" | translate }}</span>
  </div>
  <div
    class="confirm-actions"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="15px"
  >
    <button id="confirmBoxCancelDeleteAppointment" mat-button (click)="cancel()">
      {{ "CANCEL" | translate }}
    </button>
    <button
      id="confirmBoxDeleteAppointment"
      mat-button
      (click)="deleteCalendarAppointment()"
    >
      {{ "CALENDARS.DELETE" | translate }}
    </button>
  </div>
</ng-template>

<ng-template #deleteAppointmentRecurShortcutConfirmTemplate>
  <div class="confirm-header" fxLayout="row" fxLayoutAlign="start center">
    <span>{{ "CALENDARS.DELETE_RECURRING_ITEM_TITLE" | translate }}</span>
  </div>
  <div
    class="confirm-message"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxLayoutGap="20px"
  >
    <div fxLayout="row" fxLayoutAlign="center center">
      <span>
        {{ selectedAppointmentTitle }}
        {{ "CALENDARS.DELETE_RECURRING_ITEM_MSG" | translate }}
      </span>
    </div>
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="15px">
      <mat-radio-group [(ngModel)]="isDeleteAppointmentIntanceOnly">
        <mat-radio-button [value]="true">
          {{ "CALENDARS.DELETE_THIS_INSTANCE_OPTION" | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="false">
          {{ "CALENDARS.DELETE_THE_SERIES_OPTION" | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div
    class="confirm-actions"
    fxLayout="row"
    fxLayoutAlign="end center"
    fxLayoutGap="15px"
  >
    <button
      id="confirmBoxCancelDeleteInstanceOrSeriesAppointment"
      mat-button
      (click)="cancel()"
    >
      {{ "COMMON.CANCEL" | translate }}
    </button>
    <button
      id="confirmBoxDeleteInstanceOrSeriesAppointment"
      mat-button
      (click)="deleteAppointmentWithInstanceOrSeries()"
    >
      {{ "CALENDARS.DELETE" | translate }}
    </button>
  </div>
</ng-template>
