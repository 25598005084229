
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { MailFolder } from "../../models/mail-folder.model";
import { map, catchError, take, tap, bufferTime, distinctUntilChanged } from "rxjs/operators";
import { MailUtils } from "../../utils/mail-utils";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { ConfigService } from "src/app/config.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { isArray } from "util";
import { Store } from "@ngrx/store";
import { MailRootState } from "../../store";
import { SaveContactProfiles, SetSession } from "../../../actions/app";
import { Observable, forkJoin, Subject, BehaviorSubject, of, lastValueFrom } from "rxjs";
import { SearchRequest, Message, SaveSendMessage } from "../models";
import { IsLoadingAction, UpdateManyMessagesSuccess } from "../../store/actions/mail.actions";
import { Conversation } from "../models/conversation.model";
import { SetHasMoreAction, UpdateManyConversationsSuccess } from "../../store/actions";
import { SearchConvRequest } from "../models/search-conv-request.model";
import { SearchFolder } from "../../../shared/models/search-folder";
import { Contact } from "../models/contact";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import { Preference } from "src/app/preference/shared/models";
import { CommonUtils } from "src/app/common/utils/common-util";
import { AuthService } from "../../../common/providers/auth.service";
import { MailTag } from "../../models/mail-tag.model";
import { ElectronService } from "src/app/services/electron.service";
import { getMessageById, getConversationById } from "../../store/selectors";
import { SharedUtils } from "../../utils/shared.utils";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { FilesStorageService } from "src/app/services/files-storage.service";
import { ToastService } from "src/app/common/providers/toast.service";
import jstimezonedetect from "jstimezonedetect";
import { SearchResponse, SearchItem } from "src/app/mail/shared/models/search-item";
import { ContactInfo } from "src/app/mail/shared/models/contact-info.model";
import { getAllUserContacts } from "src/app/reducers";
import { TranslateService } from "@ngx-translate/core";
import { VNCContact } from "src/app/shared/models/contact.model";
import { DatabaseService } from "src/app/services/db/database.service";
import { PreferenceService } from "src/app/preference/shared/services/preference.service";
import { FormatFileSizePipe } from "src/app/shared/pipes/format-file-size.pipe";

export interface ExtraFilters {
  mailFlags?: string; // unread, flagged, has_attachment, sent_by_me, replied, forwarded, draft, deleted, notification_sent
  sizeMoreThan?: number;
  sizeLessThan?: number;
}
@Injectable()
export class MailService {

  public allFolders: any = [];
  public attachmentInfo: any;
  mailFolders: MailFolder[] = [];
  flatFolders = {};
  mailShareZids = [];
  flatArrayFolders = [];
  isCordovaOrElectron = environment.isCordova || environment.isElectron;
  scheduledNotification: any = [];
  sharedIds: any = [];
  shareFoldersQuery: string = "";
  userContacts: any;
  contacts = {};
  convReadPostQueue$ = new Subject<any>();
  currentTagName = new BehaviorSubject<string>("");
  isAppOnline: boolean;
  openedDetail: boolean;
  tempAllContacts$ =  new BehaviorSubject<any>(null);
  constructor(
    private prefrencesService: PreferenceService,
    private formatFileSizePipe: FormatFileSizePipe,
    private http: HttpClient,
    private configService: ConfigService,
    private snackBar: MatSnackBar,
    public appStore: Store<MailRootState>,
    private domSanitizer: DomSanitizer,
    private mailBroadcaster: MailBroadcaster,
    private filesStorageService: FilesStorageService,
    private toastService: ToastService,
    private electronService: ElectronService,
    private translate: TranslateService,
    private db: DatabaseService,
    private authService: AuthService) {
      this.appStore.select(getAllUserContacts).subscribe( res => {
        this.userContacts = res.filter(v => v && v.email);
        this.userContacts.forEach(c => {
          if (!this.contacts[c.email.toLowerCase()]) {
            this.contacts[c.email.toLowerCase()] = c;
          }
        });
      });
      this.convReadPostQueue$.pipe(bufferTime(1000), distinctUntilChanged()).subscribe(v => {
        if ((v.length > 0) && (!!v[0])) {
          console.log("querypostread fired: ", v, new Date());
          this.processReadConvPostRequests(v);
        }
      });
    }

  getPreferences(): Observable<Preference[]> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/getPrefs", {},
      { headers: CommonUtils.getZimbraHeader() }).pipe(take(1),
      tap(res => {
        this.configService.updateLanguage(res["_attrs"].zimbraPrefLocale);
        if (this.electronService.isElectron) {
          this.electronService.setToStorage(MailConstants.MAIL_LANGUAGE, res["_attrs"].zimbraPrefLocale);
        } else {
          localStorage.setItem(MailConstants.MAIL_LANGUAGE, res["_attrs"].zimbraPrefLocale);
        }
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][getPreferences] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][getPreferences] took ${performance} s for API call`, performance);
        }
      }),
      map((res: any) => {
        const preferences: Preference[] = [];
        for (const key in res._attrs) {
          if (res._attrs.hasOwnProperty(key)) {
            const preference: Preference = {
              key: key ,
              value: res._attrs[key]
            };
            preferences.push(preference);
          }
        }
        return preferences;
      }));
  }

  showOfflineMessage() {
    if (!navigator.onLine) {
      this.translate.get("NO_INTERNET_CONNECTION")
      .pipe(take(1))
      .subscribe(text => {
        this.openSnackBar(text);
      });
    }
    return !navigator.onLine;
  }

  setShareFoldersQuery(): void {
    let query = "";
    const ids: string [] = [];
    this.flatArrayFolders.map( f => {
      if (f.perm) {
          if (f.id.indexOf(":") !== -1 ) {
            ids.push("inid:" + "\"" + f.id + "\"");
          } else {
            ids.push("inid:" + f.id);
          }
      }
    });
    ids.push("is:local");
    query = ids.join(",").replace(/,/g, " OR ");
    this.shareFoldersQuery = query;
  }

  getMessageFolder(folder): Observable<any> {
    console.log("[MailService][getMessageFolder]", folder);

    const response = new Subject<any>();
    const body = {
      folder: folder
    };
    this.http
      .post(this.configService.API_URL + "/api/getMessageFolder", body, { headers: this.getZimbraHeader() })
      .pipe(take(1),
        map(res => {
          console.log(`[MailService][getMessageFolder] res`, res);
          response.next(res);
        }),
        catchError(this.authService.handleErrorObservable.bind(this)
      )).subscribe();
      return response.asObservable().pipe(take(1));
  }

  getMailFolders(body: any, isSingleNode): Observable<MailFolder[]> {
    console.log("[MailService][getMailFolders]", body);

    const t1 = new Date().getTime();
    const response = new Subject<MailFolder[]>();
    const params = JSON.stringify(body);
    this.http
      .post(this.configService.API_URL + "/api/getFolderList", body, { headers: this.getZimbraHeader() })
      .pipe(
        tap(res => {
          const t2 = new Date().getTime();
          const performance = (t2 - t1) / 1000;
          console.log(`[MailService][getFolderList] took ${performance} s for API call`);
          if (!!this.configService.get("logSentryPerf")) {
            CommonUtils.sentryLog(`[API][getFolderList] - params=${params} took ${performance} s`, performance);
          }
        }),
        take(1),
        map(res => {
          console.log(`[MailService][getFolderList] res`, res);
          const folders = SharedUtils.parseFolders(res, isSingleNode);
          const t2 = new Date().getTime();
          const performance = (t2 - t1) / 1000;
          console.log(`[MailService][getFolderList] folders`, folders);
          response.next(folders);
        }),
        catchError(this.authService.handleErrorObservable.bind(this)
      )).subscribe();
      return response.asObservable().pipe(take(1));
  }

  getSearchFolders(body: any, isSingleNode): Observable<SearchFolder[]> {
    console.log("[MailService][getSearchFolders]", body);

    const t1 = new Date().getTime();
    const response = new Subject<SearchFolder[]>();
    const params = JSON.stringify(body);
    this.http
      .post(this.configService.API_URL + "/api/getFolderList", body, { headers: this.getZimbraHeader() })
      .pipe(
        tap(res => {
          const t2 = new Date().getTime();
          const performance = (t2 - t1) / 1000;
          console.log(`[MailService][getSearchFolders] took ${performance} s for API call`);
          if (!!this.configService.get("logSentryPerf")) {
            CommonUtils.sentryLog(`[API][getSearchFolders] - params=${params} took ${performance} s`, performance);
          }
        }),
        take(1),
        map(res => {
          const searches = SharedUtils.parseSearchFolders(res, isSingleNode);
          const t2 = new Date().getTime();
          const performance = (t2 - t1) / 1000;
          console.log(`[MailService][getSearchFolders] res`, searches);
          response.next(searches);
        }),
        catchError(this.authService.handleErrorObservable.bind(this))
      ).subscribe();
      return response.asObservable().pipe(take(1));
  }

  markConvMsgAsReadFromNotification(isMessage: boolean, id: string): void {
    if (isMessage) {
      this.messageAction({ id: id, op: "read" }).subscribe(res => {
        console.log("mark message as read from notification");
        this.appStore.select(state => getMessageById(state, id)).pipe(take(1)).subscribe(msg => {
          if (!!msg) {
            if (msg.f) {
              msg.f = msg.f.replace("u", "");
            }
            this.appStore.dispatch(new UpdateManyMessagesSuccess([msg]));
          }
        });
      });
    } else {
      this.conversationAction({ id: id, op: "read" }).subscribe(res => {
        console.log("mark conversation as read from notification");
        this.appStore.select(state => getConversationById(state, id)).pipe(take(1)).subscribe(conv => {
          if (!!conv) {
            conv.u = 0;
            if (conv.f) {
              conv.f = conv.f.replace("u", "");
            }
            this.appStore.dispatch(new UpdateManyConversationsSuccess([conv]));
          }
        });
      });
    }
  }

  refreshMailFolders(body: any): Observable<any> {
    console.log("[getMailFolders]", body);
    return this.http
      .post(this.configService.API_URL + "/api/getFolderList", body, { headers: this.getZimbraHeader() })
      .pipe(
        map(res => {
          return res;
        }),
        catchError(this.authService.handleErrorObservable.bind(this)
)      );
  }

  getContactFolders(body: any): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(body);
    return this.http
      .post(this.configService.API_URL + "/api/getFolderList", body, { headers: this.getZimbraHeader() })
      .pipe(
        tap(res => {
          const t2 = new Date().getTime();
          const performance = (t2 - t1) / 1000;
          console.log(`[API][getContactFolders] took ${performance} s for API call`);
          if (!!this.configService.get("logSentryPerf")) {
            CommonUtils.sentryLog(`[API][getContactFolders] took ${performance} s - ${params}`, performance);
          }
        }),
        map(res => {
          return res;
        }),
        catchError(this.authService.handleErrorObservable.bind(this)
)      );
  }

  private getZimbraHeader(): HttpHeaders {
    const headers: HttpHeaders = new HttpHeaders();
    if (this.isCordovaOrElectron) {
      return headers.set("Authorization", this.getToken()).set("Content-Type", "application/json").set("Accept", "application/json");
    }
    return headers.set("Content-Type", "application/json").set("Accept", "application/json");
  }

  getZimbraHeadersForSerialization(): any {
    if (this.isCordovaOrElectron) {
      return {"Authorization": this.getToken(), "Content-Type": "application/json", "Accept": "application/json"};
    }
    return {"Content-Type": "application/json", "Accept": "application/json"};
  }

  private getToken() {
    const token = environment.isElectron
      ? this.electronService.getFromStorage(MailConstants.TOKEN)
      : localStorage.getItem(MailConstants.TOKEN);

    return token;
  }

  private getAuthorizationToken(): HttpHeaders {
    const headers: HttpHeaders = new HttpHeaders();
    if (this.isCordovaOrElectron) {
      return headers.set("Authorization", this.getToken());
    }
    return headers;
  }

  getAutoCompleteList(inputValue: any): Observable<any> {
    let body;
    if (typeof inputValue === "string") {
      body = { name: inputValue };
    } else if (typeof inputValue === "object") {
      body = inputValue;
    } else {
      throw Error("[MailService][getAutoCompleteList] wrong argument type");
    }

    return this.http.post(
      this.configService.API_URL + "/api/autocomplete",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getAutoCompleteGalList(inputValue: string): Observable<any> {
    const body = { name: inputValue, type: "resource" };
    return this.http.post(
      this.configService.API_URL + "/api/autocompleteGalRequest",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getApiUrl(): string {
    return this.configService.API_URL;
  }

  zimbraPrefSpellIgnoreWord(word: string): Observable<any> {
    const body = {
      "ModifyPrefsRequest":
      {
        "@": {
          "xmlns": "urn:zimbraAccount",
          "requestId": "0"
        },
        "pref": {
          "@": {
            "name": "+zimbraPrefSpellIgnoreWord"
        },
        "#": word
        }
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  saveMailAsDraft(saveSendMessage: SaveSendMessage): Observable<any> {
    console.log(`[MailService][saveMailAsDraft]`);

    const t1 = new Date().getTime();
    const params = JSON.stringify(saveSendMessage);
    return this.http.post(
      this.configService.API_URL + "/api/saveDraft",
      saveSendMessage,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][saveDraft] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][saveDraft] took ${performance} s - ${params}`, performance);
        }
      }),
      catchError(error => {
        if (error && error?.includes("exceeded allowed size")) {
          const limitSize = this.prefrencesService.uploadAttachmentSizeLimit;
          if (limitSize !== -1) {
            this.translate.get(MailConstants.ATTACHMENT_EXCEED_LIMIT_MSG).subscribe(text => {
              this.toastService.showPlainMessage(text + " " + this.formatFileSizePipe?.transform(limitSize, false), 4000);
            });
          }
        } else {
          return this.authService.handleErrorObservable(error);
        }
        return error;
      })
    );
  }

  sendEmail(saveSendMessage: SaveSendMessage): Observable<any> {
    console.log("[mail-service] sendEmail ", saveSendMessage);
    const t1 = new Date().getTime();
    const params = JSON.stringify(saveSendMessage);
    return this.http.post(
      this.configService.API_URL + "/api/sendEmail",
      saveSendMessage,
      {
        headers: this.getZimbraHeader()
      }).pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][sendEmail] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][sendEmail] took ${performance} s - ${params}`, performance);
        }
      }),
      map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  uploadAttachment(file: any): Observable<any> {
    const formData = new FormData();
    formData.append("file", file);
    const t1 = new Date().getTime();
    return this.http.post(
      this.configService.API_URL + "/api/upload",
      formData,
      {
        headers: this.getZimbraHeader().delete("Content-Type").delete("Accept"),
        responseType: "text"
      }).pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][uploadAttachment] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][uploadAttachment] took ${performance} s for API call`, performance);
        }
      }));
  }

  uploadAvatar(files): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/upload_avatar", files,
      {
        headers: this.getAuthorizationToken(),
        responseType: "text"
      }).pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][uploadAvatar] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][uploadAvatar] took ${performance} s for API call`, performance);
        }
      }));
  }

  removeAvatar(): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/remove_avatar", {"delete": "true"},
      {
        headers: this.getAuthorizationToken(),
        responseType: "text"
      }).pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][removeAvatar] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][removeAvatar] took ${performance} s for API call`, performance);
        }
      }));
  }

  createFolder(body): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(body);
    return this.http.post(this.configService.API_URL + "/api/createFolder", body, { headers: this.getZimbraHeader() }).
      pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][createFolder] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][searchGal] took ${performance} s - ${params}`, performance);
        }
      }),
      map((res: any) => {
        if (!!res && res.folder && res.folder[0]) {
          return res.folder[0];
        }
        return null;
      }), catchError(this.authService.handleErrorObservable.bind(this)));
  }


  backgroundSearchRequest(body: any): Observable<any> {
    const result = new Subject<any>();
    try {

      console.log("backgroundSearchRequest advanced-http request body: ", body);

//      cordova.plugin.http.removeCookies(this.configService.API_URL + "/api/searchRequest", () => {
        cordova.plugin.http.post(this.configService.API_URL + "/api/searchRequest", body, { Authorization: this.getToken() }, function(response) {
          // cordova.plugin.http.clearCookies();
          console.log("backgroundSearchRequest advanced-http-post response: ", response, response.status);
          if (response && !!response.data) {
            let data = {};
            try {
              data = JSON.parse(response.data);
            } catch (e) {
              console.error("backroundRequest JSON parse error: ", e, response);
            }
            console.log("backgroundSearchRequest advanced-http result: ", data);
            result.next(data);
          } else {
            result.next({});
          }

        }, function(response) {
          console.log("backgroundSearchRequest advanced-http response: ", response, response.status);
          console.error("background advanced-http error: ", response.error);
          result.error(response.error);
        });
//      });
    } catch (error) {
      result.error(error);
    }


    return result.asObservable().pipe(take(1));
  }


  backgroundSearchRequest1(body: any): Observable<any> {
    const result = new Subject<any>();
    try {
      let options = {
        method: "post",
        data: body,
        responseType: "json",
        headers: {
          "Authorization": this.getToken(),
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      };
      console.log("backgroundSearchRequest advanced-http request body: ", body);
//      cordova.plugin.http.clearCookies();
      cordova.plugin.http.sendRequest(this.configService.API_URL + "/api/searchRequest", options, function (response) {
        // cordova.plugin.http.clearCookies();
        console.log("backgroundSearchRequest advanced-http response: ", response, response.status);
        if (response && !!response.data) {
          console.log("backgroundSearchRequest advanced-http result: ", response.data);
          result.next(response.data);
        } else {
          result.next({});
        }

      }, function (response) {
        console.log("backgroundSearchRequest advanced-http response: ", response, response.status);
        console.error("background advanced-http error: ", response.error);
        result.error(response.error);
      });
    } catch (error) {
      result.error(error);
    }


    return result.asObservable().pipe(take(1));
  }

  searchRequest(body: SearchRequest): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/searchRequest", body, { headers: this.getZimbraHeader() }).pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][searchRequest] took ${performance} s for API call`, body, res);
      if (res.session) {
        this.appStore.dispatch(new SetSession(res.session));
      }
      if (!!this.configService.get("logSentryPerf")) {
        CommonUtils.sentryLog(`[API][searchRequest] took ${performance} s - ${JSON.stringify(body)}`, performance);
      }
    }));
  }

  getConversationsList(query: SearchRequest): Observable<Conversation[]> {
    console.log("[MailService][getConversationsList]", query);

    const t1 = new Date().getTime();
    this.appStore.dispatch(new IsLoadingAction());

    if (window.appInBackground) {
      return this.backgroundSearchRequest(query).pipe(map(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][getEmailList] took ${performance} s for API call and mapping`);
        let conversations: Conversation[] = [];
        this.appStore.dispatch(new SetHasMoreAction(res.more === true));
        if (res.c) {
          res.c = isArray(res.c) ? res.c : [res.c];
          conversations = res.c.map(c => {
            try {
              const conv = MailUtils.mapConversation(c, query);
              return conv;
            } catch (ex) {
              console.error("[MailService][getConversationsList] mapConversation ex", ex);
            }
            return null;
          });
        }
        return conversations.filter(v => !!v);
      }));


    } else {

      return this.searchRequest(query).pipe(map(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][getEmailList] took ${performance} s for API call and mapping`);
        let conversations: Conversation[] = [];
        this.appStore.dispatch(new SetHasMoreAction(res.more === true));
        if (res.c) {
          res.c = isArray(res.c) ? res.c : [res.c];
          conversations = res.c.map(c => {
            try {
              const conv = MailUtils.mapConversation(c, query);
              return conv;
            } catch (ex) {
              console.error("[MailService][getConversationsList] mapConversation ex", ex);
            }
            return null;
          });
        }
        return conversations.filter(v => !!v);
      }));
    }
  }

  getMessagesList(query: SearchRequest): Observable<Message[]> {
    console.log("[MailService][getMessagesList] query", query);

    this.appStore.dispatch(new IsLoadingAction());
    if (window.appInBackground) {
      return this.backgroundSearchRequest(query).pipe(map(res => {
        let messages: Message[] = [];
        this.appStore.dispatch(new SetHasMoreAction(res.more === true));
        if (res.m) {
          const _messages = isArray(res.m) ? res.m : [res.m];
          messages = _messages.map(m => {
            try {
              const msg = MailUtils.mapEmailMessage(m, query.originalQuery);
              return msg;
            } catch (ex) {
              console.log("[MailService][getMessagesList] mapEmailMessage ex", ex);
            }
            return null;
          });
        }
        return messages.filter(v => !!v);
      }));


    } else {

      return this.searchRequest(query).pipe(map(res => {
        let messages: Message[] = [];
        this.appStore.dispatch(new SetHasMoreAction(res.more === true));
        if (res.m) {
          const _messages = isArray(res.m) ? res.m : [res.m];
          messages = _messages.map(m => {
            try {
              const msg = MailUtils.mapEmailMessage(m, query.originalQuery);
              return msg;
            } catch (ex) {
              console.log("[MailService][getMessagesList] mapEmailMessage ex", ex);
            }
            return null;
          });
        }
        return messages.filter(v => !!v);
      }));
    }
  }

  getContactAvtar(contacts: Contact[]) {
    const requests: Observable<any>[] = [];
    for (let i = 0; i < contacts.length; i++) {
      requests.push(this.http.get(contacts[i].avatarUrl, {
        responseType: "blob"
      }).pipe(catchError(this.authService.handleErrorObservable.bind(this)
)      ));
    }
    forkJoin(requests).subscribe(results => {
      for (let j = 0; j < results.length; j++) {
        const reader = new FileReader();
        reader.onloadend = (e) => {
          const data: any = reader.result;
          contacts[j].blobImage = this.domSanitizer.bypassSecurityTrustUrl(data);
        };
        reader.readAsDataURL(results[j]);
      }
      this.appStore.dispatch(new SaveContactProfiles(contacts));
    });
  }

  conversationAction(body): Observable<any> {
    console.log(`[MailService][conversationAction]`, body.op, body);

    const t1 = new Date().getTime();
    const params = JSON.stringify(body);
    return this.http.post(this.configService.API_URL + "/api/convAction", body, { headers: this.getZimbraHeader() }).
      pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][convAction] took ${performance} s for API call`, body);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][convAction] took ${performance} s - ${params}`, performance);
        }
      }),
      catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getAllContacts(): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/getContacts", {}, { headers: this.getZimbraHeader() }).
      pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][getContacts] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][getContacts] took ${performance} s for API call`, performance);
        }
      }),
      catchError(this.authService.handleErrorObservable.bind(this)));
  }

  messageAction(body): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/msgAction", body, { headers: this.getZimbraHeader() }).
      pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][msgAction] took ${performance} s for API call`, body);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][searchGal] took ${performance} s for API call`, performance);
        }
        setTimeout(() => {
          console.log("[mailService] CALL_NO_OP_REQUEST 1");
          this.mailBroadcaster.broadcast(MailConstants.CALL_NO_OP_REQUEST);
        }, 2000);
      }),
      catchError(this.authService.handleErrorObservable.bind(this)));
  }

  folderAction(body): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(body);
    return this.http.post(this.configService.API_URL + "/api/folderAction", body, { headers: this.getZimbraHeader() }).
      pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][folderAction] took ${performance} s for API call`, body);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][folderAction] took ${performance} s - ${params}`, performance);
        }
        setTimeout(() => {
          console.log("[mailService] CALL_NO_OP_REQUEST 2");
          this.mailBroadcaster.broadcast(MailConstants.CALL_NO_OP_REQUEST);
        }, 2000);
      }),
      catchError(this.authService.handleErrorObservable.bind(this)));
  }

  searchConversations(body: SearchConvRequest): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(body);
    return this.http.post(this.configService.API_URL + "/api/searchConvRequest", body, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][searchConvRequest] took ${performance} s for API call`, body);
      if (!!this.configService.get("logSentryPerf")) {
        CommonUtils.sentryLog(`[API][searchConvRequest] took ${performance} s - ${params}`, performance);
      }
    }));
  }

  getMsgRequest(id: string): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/getMsgRequest", { id }, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][mail.service][getMsgRequest] took ${performance} s for API call`, id);
      if (!!this.configService.get("logSentryPerf")) {
        CommonUtils.sentryLog(`[API][getMsgRequest] took ${performance} s - ID=${id}`, performance);
      }
    }));
  }

  getMsgAttachmentDetailRequest(body: any): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(body);
    return this.http.post(this.configService.API_URL + "/api/getMsgRequest", body, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][getMsgRequest] took ${performance} s for API call`, body);
      if (!!this.configService.get("logSentryPerf")) {
        CommonUtils.sentryLog(`[API][getMsgRequest] took ${performance} s - ${params}`, performance);
      }
    }));
  }

  backgroundRetrieveMessagesById(id: string, currentQuery?: string): Observable<Message[]> {
    console.log("[mail.service][backgroundRetrieveMessagesById] getMsgRequest: ", id);
    const result = new Subject<any>();
    try {
      let options = {
        method: "post",
        data: { id: id },
        responseType: "json",
        headers: {
          "Authorization": this.getToken(),
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      };
      console.log("backgroundRetrieveMessagesById advanced-http request id: ", id);
//      cordova.plugin.http.clearCookies();
      cordova.plugin.http.sendRequest(this.configService.API_URL + "/api/getMsgRequest", options, function (response) {
        // cordova.plugin.http.clearCookies();
        console.log("backgroundRetrieveMessagesById advanced-http response: ", response, response.status);
        if (response && !!response.data && !!response.data.m) {
          let message: Message[] = [];
          message = response.data.m.map(m => {
            return MailUtils.mapEmailMessage(m, currentQuery);
          });
          result.next(message);
        } else {
          result.next({});
        }

      }, function (response) {
        console.log("backgroundRetrieveMessagesById advanced-http response: ", response, response.status);
        console.error("background advanced-http error: ", response.error);
        result.error(response.error);
      });
    } catch (error) {
      result.error(error);
    }


    return result.asObservable().pipe(take(1));
  }


  retrieveMessagesById(id: string, currentQuery?: string): Observable<Message[]> {
    console.log("[mail.service][retireveMessagesById] getMsgRequest: ", id);
    return this.getMsgRequest(id).pipe(map(res => {
      if (res.m) {
        let message: Message[] = [];
        res.m = isArray(res.m) ? res.m : [res.m];
        message = res.m.map(m => {
          return MailUtils.mapEmailMessage(m, currentQuery);
        });
        return message;
      }
      return [];
    }), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getConversationMessages(query: SearchConvRequest, currentQuery?: string): Observable<Message[]> {
    return this.searchConversations(query).pipe(map(res => {
      if (res.m) {
        let message: Message[] = [];
        res.m = isArray(res.m) ? res.m : [res.m];
        message = res.m.map(m => {
          return MailUtils.mapEmailMessage(m, currentQuery);
        });
        return message;
      }
      return [];
    }), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  removeAttachment(body: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/removeAttachments", body, { headers: this.getZimbraHeader() });
  }

  removeMailAttachment(messageId: string, attachmentPart: string): Observable<Message> {
    const body = {
      id: messageId,
      part: attachmentPart
    };
    return this.removeAttachment(body).pipe(map(res => {
      if (res.m) {
        let message: Message;
        message = MailUtils.mapEmailMessage(res.m);
        return message;
      }
      return null;
    }), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  shareFolder(options, users): Observable<any> {
    const folderActionRequest = [];
    let i = 0;
    if (options.gt === "pub") {
      folderActionRequest.push({
        "@": {
          "xmlns": "urn:zimbraMail",
          "requestId": i
        },
        "action": {
          "@": {
            "op": options.op,
            "id": options.id,
            "zid": options.zid
          },
          "grant": {
            "@": {
              "gt": options.gt,
              "inh": options.inh,
              "perm": options.perm || "",
              "pw": options.pw || ""
            }
          }
        }
      });
    } else {
      for (const user of users) {
        folderActionRequest.push({
          "@": {
            "xmlns": "urn:zimbraMail",
            "requestId": i
          },
          "action": {
            "@": {
              "op": options.op,
              "id": options.id,
              "zid": options.zid
            },
            "grant": {
              "@": {
                "gt": options.gt,
                "inh": options.inh,
                "d": user,
                "perm": options.perm || "",
                "pw": options.pw || ""
              }
            }
          }
        });
        i++;
      }
    }
    const request = {
      FolderActionRequest: folderActionRequest
    };
    const params = JSON.stringify(request);
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/batchRequest", request, { headers: this.getZimbraHeader() }).
      pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][shareFolder] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][shareFolder] took ${performance} s - ${params}`, performance);
        }
      }),
      catchError(this.authService.handleErrorObservable.bind(this)));
  }

  redirectMail(body: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/bounceMsg", body, { headers: this.getZimbraHeader() });
  }

  sendShareFolderRequest(body): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(body);
    return this.http.post(this.configService.API_URL + "/api/sendShareNotification", body, { headers: this.getZimbraHeader() }).
      pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][sendShareNotification] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][sendShareNotification] took ${performance} s - ${params}`, performance);
        }
      }),
      catchError(this.authService.handleErrorObservable.bind(this)));
  }

  deleteMessage(body: any): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(body);
    return this.http.post(this.configService.API_URL + "/api/messageAction", body, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][deleteMessage] took ${performance} s for API call`);
      if (!!this.configService.get("logSentryPerf")) {
        CommonUtils.sentryLog(`[API][deleteMessage] took ${performance} s - params`, performance);
      }
      setTimeout(() => {
        console.log("[mailService] CALL_NO_OP_REQUEST 3");
        this.mailBroadcaster.broadcast(MailConstants.CALL_NO_OP_REQUEST);
      }, 2000);
    }),
    catchError(this.authService.handleErrorObservable.bind(this)));
  }

  createMountPoint(body: any): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/createMountpoint", body, { headers: this.getZimbraHeader() }).
      pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][createMountPoint] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][searchGal] took ${performance} s for API call`, performance);
        }
      }),
      catchError(this.authService.handleErrorObservable.bind(this)));
  }


  getPrintConversationData(convIds: any): Observable<any> {
    const t1 = new Date().getTime();
    let url = this.configService.API_URL + "/api/printMessage?id=" + convIds;
    if (this.isCordovaOrElectron) {
      const token = localStorage.getItem("token");
      url = url + "&token=" + token;
    }
    let timeZone = jstimezonedetect.determine().name();
    timeZone = timeZone.replace("Asia/Calcutta", "Asia/Kolkata");
    url = url + "&tz=" + timeZone;
    return this.http.get(url, { headers: this.getZimbraHeader(), responseType: "text" }
    ).pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][printMessage] took ${performance} s for API call`);
      if (!!this.configService.get("logSentryPerf")) {
        CommonUtils.sentryLog(`[API][printMessage] took ${performance} s - ${convIds}`, performance);
      }
    }),
    catchError(this.authService.handleErrorObservable.bind(this)));
  }

  printConversation(convIds) {
    if (typeof cordova !== "undefined") {
      this.getPrintConversationData(convIds).subscribe(res => {
        cordova.plugins.printer.print(res, { duplex: "long" }, function (response) {
        });
      }, catchError(this.authService.handleErrorObservable.bind(this)));
    } else {
      let url = this.configService.API_URL + "/api/printMessage?id=" + convIds;
      if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        url = url + "&token=" + token;
      }
      let timeZone = jstimezonedetect.determine().name();
      timeZone = timeZone.replace("Asia/Calcutta", "Asia/Kolkata");
      url = url + "&tz=" + timeZone;
      window.open(url);
    }
  }

  printMessage(msgIds) {
    if (typeof cordova !== "undefined") {
      this.getPrintMessageData(msgIds).subscribe(res => {
        cordova.plugins.printer.print(res, { duplex: "long" }, function (response) {
        });
      }, catchError(this.authService.handleErrorObservable.bind(this)));
    } else {
      let url = this.configService.API_URL + "/api/printMessage?id=" + msgIds + "&type=message";
      if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        url = url + "&token=" + token;
      }
      let timeZone = jstimezonedetect.determine().name();
      timeZone = timeZone.replace("Asia/Calcutta", "Asia/Kolkata");
      url = url + "&tz=" + timeZone;
      window.open(url);
    }
  }

  downloadEmail(msgIds, msgType) {
    let timeZone = jstimezonedetect.determine().name();
    timeZone = timeZone.replace("Asia/Calcutta", "Asia/Kolkata");
    if (msgType === "message") {
      return this.http.get(this.configService.API_URL + "/api/downloadMessage?id=" + msgIds + "&type=message" + "&tz=" + timeZone,
        { headers: this.getZimbraHeader(), responseType: "blob" }
      ).pipe(catchError(this.authService.handleErrorObservable.bind(this)));
    } else {
      return this.http.get(this.configService.API_URL + "/api/downloadMessage?id=" + msgIds + "&tz=" + timeZone,
        { headers: this.getZimbraHeader(), responseType: "blob" }
      ).pipe(catchError(this.authService.handleErrorObservable.bind(this)));
    }
  }

downloadRawEmail(msgIds, msgType) {
  if (msgType === "message") {
    return this.http.get(this.configService.API_URL + "/api/downloadRawMessage?id=" + msgIds,
      { headers: this.getZimbraHeader(), responseType: "blob" }
    ).pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  } else {
    return this.http.get(this.configService.API_URL + "/api/downloadRawMessage?id=" + msgIds,
      { headers: this.getZimbraHeader(), responseType: "blob" }
    ).pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }
}

  getPrintMessageData(msgIds: any): Observable<any> {
    let url = this.configService.API_URL + "/api/printMessage?id=" + msgIds + "&type=message";
    if (this.isCordovaOrElectron) {
      const token = localStorage.getItem("token");
      url = url + "&token=" + token;
    }
    let timeZone = jstimezonedetect.determine().name();
    timeZone = timeZone.replace("Asia/Calcutta", "Asia/Kolkata");
    url = url + "&tz=" + timeZone;
    return this.http.get(url, { headers: this.getZimbraHeader(), responseType: "text" }
    ).pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getConfiguration(): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders();
    headers.set("Content-Type", "application/json");
    const api = this.configService.API_URL || "";
    return this.http.get(api + "/api/config", { headers: headers });
  }

  /**
   * Get All Folder
   *
   */
  getAllFolders(): Observable<any> {
    const request = {
      GetFolderRequest: {
        "@": {
          xmlns: "urn:zimbraMail",
          requestId: 0
        }
      }
    };
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/batchRequest2", request, { headers: this.getZimbraHeader() }).
      pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][getAllFolders] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][getAllFolders] took ${performance} s for API call`, performance);
        }
      }),
      catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getSavedSearchFolder(): Observable<SearchFolder[]> {
    const request = {
      GetSearchFolderRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        }
      }
    };
    const t1 = new Date().getTime();
    return this.http
      .post(this.configService.API_URL + "/api/batchRequest", request, { headers: this.getZimbraHeader() })
      .pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][GetSearchFolderRequest] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][GetSearchFolderRequest] took ${performance} s for API call`, performance);
        }
      }),
        map(res => {
          const mailFolderResponse = MailUtils.parseSearchFoders(res);
          return mailFolderResponse;
        }),
        catchError(this.authService.handleErrorObservable.bind(this)
)      );
  }

  saveSearchFolder(options): Observable<SearchFolder> {
    const request = {
      CreateSearchFolderRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        },
        search: {
          "@": {
            name: options.name,
            query: options.query,
            l: options.folderId || 1,
            types: options.types || "conversation"
          }
        }
      }
    };
    const t1 = new Date().getTime();
    const params = JSON.stringify(request);
    return this.http
      .post(this.configService.API_URL + "/api/batchRequest", request, { headers: this.getZimbraHeader() })
      .pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][CreateSearchFolderRequest] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][CreateSearchFolderRequest] took ${performance} s - ${params}`, performance);
        }
      }),
        map(res => {
          return MailUtils.parseCreateSearchResponse(res);
        })
      );
  }

  private returnResponse(res: Response): Response {
    return res;
  }

  openAttachment(id: string, part: string, attachment?: any): void {
    if (attachment && attachment.ct === "message/rfc822") {
      console.log("[MailService] openAttachment attachment: 001", );
      let queryParams = "id=" + id + "&part=" + part + "&disp=a";
      this.getAttachmentBlob(queryParams).pipe(take(1)).subscribe( res => {
        const fileName = attachment.filename;
        this.saveData(res.body, fileName);
      });
    } else {
      console.log("[MailService] openAttachment attachment: 002", );

      let queryParams = "id=" + id + "&part=" + part + "&disp=a";
      let url = CommonUtils.addTokenToRequest(this.configService.API_URL + "/api/getAttachment/?" + queryParams);
      console.log("[MailService] openAttachment attachment: 002 ::", url, part, attachment);
      if (environment.isElectron) {
        ElectronService.downloadFile(url, part);
      } else {
        if (environment.isCordova) {
          cordova.InAppBrowser.open(url, "_system");
        } else {
          console.log("[MailService] openAttachment attachment: 002 ::", url, part, attachment);
          window.open(url, "_system");
        }
      }
    }
  }

  saveData(data, fileName) {
    if (environment.isCordova) {
      this.downloadFileToDownloadsOrGallery(data, fileName);
    } else {
      const url = window.URL.createObjectURL(data);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    }
  }

  downloadFileToDownloadsOrGallery(fileBlob: any, fileName: string): Observable<any> {
    console.log("[MailService] downloadFileToDownloadsOrGallery fileName: ", fileName);
    let fName = fileName.replace(/:/g, "-");
    const response = new Subject();
    if (CommonUtils.isOnIOS()) {
      // save in hidden cache
      this.filesStorageService.saveBlobToDisc(fileBlob, fName).subscribe((localFileUrl) => {
        // for iOS we need to additionaly save to Camera Roll
        this.toastService.show("DOWNLOAD_SUCCESS");
        cordova.plugins.imagesaver.saveImageToGallery(localFileUrl, () => {
          console.log("[MailService] downloadFileToDownloadsOrGallery success, localFileUrl: ", localFileUrl);
          response.next(localFileUrl);
        }, (err) => {
          response.error(err);
        });
      }, err => {
        response.error(err);
      });

      // Android
    } else {
      this.filesStorageService.saveBlobToAndroidDownloadFolder(fileBlob, fName).subscribe((localFileUrl) => {
        this.toastService.show("DOWNLOAD_SUCCESS");
        console.log("[MailService] downloadFileToDownloadsOrGallery success, localFileUrl: ", localFileUrl);
        response.next(localFileUrl);
      }, err => {
        response.error(err);
      });
    }
    return response.asObservable().pipe(take(1));
  }

  getAttachmentBlob(body: any): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.get(CommonUtils.addTokenToRequest(this.configService.API_URL + "/api/getAttachment?" + body), {
      headers: this.getZimbraHeader().delete("Content-Type").delete("Accept"),
      responseType: "blob",
      observe: "response"
    });
  }

  previewAttachment(id: string, part: string): void {
    const url = this.getPreviewURL(id, part);
    if (environment.isElectron) {
      ElectronService.downloadFile(url, part);
    } else {
      const isOnMobile = CommonUtils.isOnMobileDevice();
      if (isOnMobile) {
        window.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }
    }
  }

  getPreviewURL(id: string, part: string): string {
    let authorization = null;
    let queryParams = "id=" + id + "&part=" + part;
    const url = CommonUtils.addTokenToRequest(this.configService.API_URL + "/api/getAttachment/?" + queryParams);
    return url;
  }

  downloadAllAttachment(ids: string, mailId: string, fileName: string): void {
    let authorization = null;
    let queryParams = "id=" + mailId + "&part=" + ids + "&filename=" + fileName;
    const url = CommonUtils.addTokenToRequest(this.configService.API_URL + "/api/downloadAttachments/?" + queryParams);
    if (environment.isElectron) {
      ElectronService.downloadFile(url, fileName);
    } else if (environment.isCordova) {
      if (device.platform === "iOS") {
        window.open(url, "_system");
      } else if (device.platform === "Android") {
        console.log("appswitcher open url: ", url);
        navigator.app.loadUrl(url, {
          openExternal: true
        });
      }
    } else {
      window.open(url, "_system");
    }

  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, "", {
      duration: 2000,
      panelClass: "mobile_snackbar"
    });
  }

  setAttachmentDocument(info: any) {
    this.attachmentInfo = info;
  }

  getAttachmentDocument() {
    return this.attachmentInfo;
  }

  noOpRequest(body: any): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/noOp", body, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][noOp] took ${performance} s for API call`);
    }));
  }

  getConvRequest(query: any): Observable<Message[]> {
    return this.getConversations(query).pipe(map(res => {
      if (res.c.m) {
        let message: Message[] = [];
        res.c.m = isArray(res.c.m) ? res.c.m : [res.c.m];
        message = res.c.m.map(m => {
          return MailUtils.mapEmailMessage(m);
        });
        return message;
      }
      return [];
    }), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getConversations(body: any): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/getConvRequest", body, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][getConvRequest] took ${performance} s for API call`);
      if (!!this.configService.get("logSentryPerf")) {
        CommonUtils.sentryLog(`[API][getConvRequest] took ${performance} s for API call`, performance);
      }
    }));
  }

  tagAction(body: any): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/tagAction", body, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][tagAction] took ${performance} s for API call`);
      if (!!this.configService.get("logSentryPerf")) {
        CommonUtils.sentryLog(`[API][tagAction] took ${performance} s for API call`, performance);
      }
    }));
  }

  createTag(body: any): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/createTag", body, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][createTag] took ${performance} s for API call`);
      if (!!this.configService.get("logSentryPerf")) {
        CommonUtils.sentryLog(`[API][createTag] took ${performance} s for API call`, performance);
      }
    }));
  }

  getTags(): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.get(this.configService.API_URL + "/api/getTagList", { headers: this.getZimbraHeader() }).pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][getTagList] took ${performance} s for API call`);
      if (!!this.configService.get("logSentryPerf")) {
        CommonUtils.sentryLog(`[API][getTagList] took ${performance} s for API call`, performance);
      }
    }),
    map((res: any) => {
      if (res.GetTagResponse && res.GetTagResponse.tag) {
        const tags = Array.isArray(res.GetTagResponse.tag) ? res.GetTagResponse.tag : [res.GetTagResponse.tag];
        return tags.map(t => {
          return t as MailTag;
        });
      }
      return [];
    }));
  }

  sendInviteReply (body: any): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(body);
    return this.http.post(this.configService.API_URL + "/api/sendInviteReply", body, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][sendInviteReply] took ${performance} s for API call`);
      if (!!this.configService.get("logSentryPerf")) {
        CommonUtils.sentryLog(`[API][sendInviteReply] took ${performance} s - ${params}`, performance);
      }
    }),
    catchError(this.authService.handleErrorObservable.bind(this)));
  }

  itemAction (body: any): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(body);
    return this.http.post(this.configService.API_URL + "/api/itemAction", body, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][itemAction] took ${performance} s for API call`);
      if (!!this.configService.get("logSentryPerf")) {
        CommonUtils.sentryLog(`[API][itemAction] took ${performance} s - ${params}`, performance);
      }
    }),
    catchError(this.authService.handleErrorObservable.bind(this)));
  }

  printMessageAndConv(convId, msgId): void {
    console.log("[Print Conv And Msg ] : ", convId, msgId);
    if (typeof cordova !== "undefined") {
      this.printMessageAndConvData(convId, msgId).subscribe(res => {
        cordova.plugins.printer.print(res, { duplex: "long" }, function (response) {
        });
      }, catchError(this.authService.handleErrorObservable.bind(this)));
    } else {
      window.open(this.getPrintUrl(convId, msgId));
    }
  }

  private getPrintUrl(convId, msgId): string {
    let url = this.configService.API_URL + "/api/printMessage?";
      const params = [];
      if (convId.length > 0) {
        params.push(`id=${convId.toString()}`);
      }
      if (msgId.length > 0) {
        params.push(`msgId=${msgId.toString()}`);
      }
      let timeZone = jstimezonedetect.determine().name();
      timeZone = timeZone.replace("Asia/Calcutta", "Asia/Kolkata");
      params.push(`tz=${timeZone}`);
      url = url + params.join("&");
      if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        url = url + "&token=" + token;
      }
      return url;
  }

  printMessageAndConvData(convId, msgId): Observable<any> {
    const url = this.getPrintUrl(convId, msgId);
    return this.http.get(url,
      { headers: this.getZimbraHeader(), responseType: "text" }
    ).pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getAttributes (section: string): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.get(this.configService.API_URL + "/api/getInfo?sections=" + section , { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][getInfo] took ${performance} s for API call`, section);
      if (!!this.configService.get("logSentryPerf")) {
        CommonUtils.sentryLog(`[API][getInfo]sections=${section} took ${performance} s for API call`, performance);
      }
    }),
    catchError(this.authService.handleErrorObservable.bind(this)));
  }

  createBatchRequest (request: any ): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(request);
    return this.http
      .post(this.configService.API_URL + "/api/batchRequest", request, { headers: this.getZimbraHeader() })
      .pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][createBatchRequest] took ${performance} s for API call`, request);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][createBatchRequest] took ${performance} s - ${params}`, performance);
        }
      }),
      catchError(this.authService.handleErrorObservable.bind(this)));
  }

  checkSpelling (request: any ): Observable<any> {
    const t1 = new Date().getTime();
    return this.http
      .post(this.configService.API_URL + "/api/checkSpelling", request, { headers: this.getZimbraHeader() })
      .pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][checkSpelling] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][checkSpelling] took ${performance} s for API call`, performance);
        }
      }),
      catchError(this.authService.handleErrorObservable.bind(this)));
  }

  sendDeliveryReport (body: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/sendDeliveryReport", body, { headers: this.getZimbraHeader() })
    .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  searchGalRequest (body: any ): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(body);
    return this.http
      .post(this.configService.API_URL + "/api/searchGal", body, { headers: this.getZimbraHeader() })
      .pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][searchGal] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][searchGal] took ${performance} s - ${params}`, performance);
        }
      }),
      catchError(this.authService.handleErrorObservable.bind(this)));
  }

  searchZGalRequest(body: any): Observable<any> {
    const t1 = new Date().getTime();
    const params = JSON.stringify(body);
    return this.http
      .post(this.configService.API_URL + "/api/searchZGal", body, { headers: this.getZimbraHeader() })
      .pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][searchGal] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][searchGal] took ${performance} s - ${params}`, performance);
        }
      }),
        catchError(this.authService.handleErrorObservable.bind(this)));
  }


  getMailBoxMetaData(): Observable<any> {
    const request = {
      GetMailboxMetadataRequest: {
        "@": {
          "xmlns": "urn:zimbraMail",
          "requestId": "0"
        },
        "meta": {
          "@": {
            "section": "zwc:implicit"
          }
        }
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getAppoinementById(id: any): Observable<any> {
    const request = {
      GetAppointmentRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        },
        includeContent: 1,
        id: id
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  cancelAppointment(ms: any, id: any, name: any): Observable<any> {
    let cancelText = "Cancelled";
    this.translate.get("CANCELED_LBL").pipe(take(1)).subscribe(v => cancelText = v);
    const request = {
      CancelAppointmentRequest: {
        "@": {
          xmlns: "urn:zimbraMail"
        },
        "ms": ms,
        "rev": ms,
        "id": id,
        "comp": "0",
        "m": {
          "e": [],
          "su": `${cancelText}: ` + name,
          "mp": {
            "mp": [{
              "ct": "text/plain",
              "content": ""
            }],
            "ct": "multipart/alternative"
          }
        }
      }
    };

    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  createAppointment(email: any, startDate: any, endDate: any, name: any, timeZone: string, allDay?: boolean): Observable<any> {
    const request = {
      "CreateAppointmentRequest": {
        "@": {
          "xmlns": "urn:zimbraMail"
        },
        "m": {
          "l": 10,
          "inv": {
            "comp": [{
              "at": [],
              "status": "CONF",
              "fb": "O",
              "class": "PUB",
              "transp": "O",
              "draft": 0,
              "allDay": allDay ? "1" : "0",
              "s": {
                "d": startDate,
                "tz": timeZone
              },
              "e": {
                "d": endDate,
                "tz": timeZone
              },
              "name": name || "Out of Office",
              "loc": "",
              "or": {
                "a": email
              }
            }]
          },
          "e": [],
          "su": name || "Out of Office",
          "mp": {
            "mp": [{
              "ct": "text/plain",
              "content": ""
            }],
            "ct": "multipart/alternative"
          }
        }
      }
    };

    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  setMailBoxMetaData(metaData: any): Observable<any> {
    const a = [];
    for (const key of Object.keys(metaData)) {
      if (key === "zimbraPrefFoldersExpanded" && metaData[key] === "") {
        a.push({
          "@": {
            n: key
          }
        });
      } else {
        a.push({
          "@": {
            n: key
          },
          "#": metaData[key]
        });
      }
    }

    const request = {
      SetMailboxMetadataRequest: {
        "@": {
          "xmlns": "urn:zimbraMail",
          "requestId": "0"
        },
        "meta": {
          "@": {
            "section": "zwc:implicit"
          },
          a
        }
      }
    };

    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getZimbraVersion(): Observable<any> {
    const request = {
      GetVersionInfoRequest: {
        "@": {
          xmlns: "urn:zimbraAccount"
        }
      }
    };
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      request,
      {
        headers: this.getZimbraHeader()
      }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getAccountInformation (body: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/getAcountInformation", body, { headers: this.getZimbraHeader() })
    .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  changePassword(body: any): Observable<any> {
    return this.http.post(this.configService.API_URL + "/api/changePassword", body, { headers: this.getZimbraHeader() })
    .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getChangePasswordData(): Promise<any> {
    return new Promise(resolve => {
      const changePasswordType = localStorage.getItem("changePasswordType") === null ? "zimbra" :
      localStorage.getItem("changePasswordType");
      const sectionAttributes = localStorage.getItem("sectionAttributes");
      resolve({ changePasswordType: changePasswordType, sectionAttributes: sectionAttributes });
    });
  }

  getMailHistory(msgId: string): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/getMailHistory?id=" + msgId, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][getMailHistory] took ${performance} s for API call`);
      if (!!this.configService.get("logSentryPerf")) {
        CommonUtils.sentryLog(`[API][getMailHistory] ${msgId} took ${performance} s for API call`, performance);
      }
    }),
    catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getOriginalConversationText(id: string): void {
    const url = this.configService.API_URL + "/api/showOriginalConversation?id=" + id;
    if (this.isCordovaOrElectron) {
      const token = localStorage.getItem("token");
      window.open(url + "&token=" + token, "_blank");
    } else {
      window.open(
        url,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yeswidth=500,height=500"
      );
    }
  }

  getOriginalMessage(id: string): Observable<any> {
    const url = this.configService.API_URL + "/api/showOriginalConversation?id=" + id;
    let headers = new HttpHeaders({ "Content-Type": "application/html"  });
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        headers = new HttpHeaders({ "Content-Type": "application/html", "Authorization": token });
    }
    console.log("[getOriginalMessage]", id);
    return this.http.get(url, { headers: headers, responseType: "text" });
  }

  getShareInfoRequestByEmail(owner: string): Observable<any> {
    const body = {
      "GetShareInfoRequest": {
        "@": {
          "xmlns": "urn:zimbraAccount",
          "requestId": "0"
        },
        owner: {
          "@": {
            "by": "name"
          },
          "#": owner
        }
      }
    };
    const t1 = new Date().getTime();
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][GetShareInfoRequest] took ${performance} s for API call`);
      }),
      map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getShareInfoRequest(): Observable<any> {
    const body = {
      "GetShareInfoRequest": {
        "@": {
          "xmlns": "urn:zimbraAccount",
          "includeSelf": "0"
        }
      }
    };
    const t1 = new Date().getTime();
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][GetShareInfoRequest] took ${performance} s for API call`);
      }),
      map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getShareInfoRequestByGrantee(type: string): Observable<any> {
    const body = {
      "GetShareInfoRequest": {
        "@": {
          "xmlns": "urn:zimbraAccount",
          "requestId": "0"
        },
        grantee: {
          "@": {
            "type": type
          }
        }
      }
    };
    const t1 = new Date().getTime();
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][GetShareInfoRequest] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][GetShareInfoRequest] took ${performance} s for API call`, performance);
        }
      }),
      map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getShareInfoRequestByMountPoint(owner: string[]): Observable<any> {
    const request: any [] = [];
    owner.forEach( email => {
      request.push(
        {
          "@": {
            "xmlns": "urn:zimbraAccount",
            "requestId": "0"
          },
          owner: {
            "@": {
              "by": "name"
            },
            "#": email
          }
        }
      );
    });
    const body = {
      "GetShareInfoRequest": request
    };
    const t1 = new Date().getTime();
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][GetShareInfoRequest] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][GetShareInfoRequest] took ${performance} s for API call`, performance);
        }
      }),
      map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getShareFolderByMe(folderIds: string[]): Observable<any> {
    const request: any [] = [];
    folderIds.forEach( id => {
      request.push(
        {
          "@": {
            "xmlns": "urn:zimbraMail",
            "requestId": "0"
          },
          folder: {
            "@": {
              "l": id
            }
          }
        }
      );
    });
    const body = {
      "GetFolderRequest": request
    };
    const params = JSON.stringify(body);
    const t1 = new Date().getTime();
    return this.http.post(
      this.configService.API_URL + "/api/batchRequest",
      body,
      {
        headers: this.getZimbraHeader()
      }).pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][GetFolderRequest] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][GetFolderRequest] took ${performance} s - ${params}`, performance);
        }
      }),
      map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
     }

  updateRetensionPolilcy(body: any): Observable<any> {
    const t1 = new Date().getTime();
     return this.http.post(this.configService.API_URL + "/api/folderActionRetention", body, { headers: this.getZimbraHeader() }).
       pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][folderActionRetention] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][folderActionRetention] took ${performance} s for API call`, performance);
        }
      }),
      catchError(this.authService.handleErrorObservable.bind(this)));
  }

  sendInviteReplyAction (body: any): Observable<any> {
    const t1 = new Date().getTime();
    let plainText = "";
    let translationKey = "";
    if (body.verb) {
      if (body.verb === "ACCEPT") {
        translationKey = "I_WILL_ATTEND";
      } else if (body.verb === "TENTATIVE") {
        translationKey = "I_MIGHT_ATTEND";
      } else if (body.verb === "DECLINE") {
        translationKey = "I_WONT_ATTEND";
      }
      this.translate.get(translationKey).pipe(take(1)).subscribe(text => plainText = text);
      body.htmlText = `<p>${plainText}</p>`;
      body.plainText = plainText;
    }

    const params = JSON.stringify(body);
    return this.http.post(this.configService.API_URL + "/api/sendInviteReplyAction", body, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][sendInviteReplyAction] took ${performance} s for API call`);
      if (!!this.configService.get("logSentryPerf")) {
        CommonUtils.sentryLog(`[API][sendInviteReplyAction] took ${performance} s - ${params}`, performance);
      }
    }),
    catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getAllUserContacts(): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/getAllUserContacts", {}, { headers: this.getZimbraHeader() }).
      pipe(tap(res => {
        const t2 = new Date().getTime();
        const performance = (t2 - t1) / 1000;
        console.log(`[API][getAllUserContacts] took ${performance} s for API call`);
        if (!!this.configService.get("logSentryPerf")) {
          CommonUtils.sentryLog(`[API][getAllUserContacts] took ${performance} s for API call`, performance);
        }
      }),
      catchError(this.authService.handleErrorObservable.bind(this)));
  }

  ///

  processReadConvPostRequests(requests: any) {
    console.log("processReadPostRequests: ", requests);
    const convIds = requests.map(r => r.id).join(",");
    console.log("processReadPostRequests: joinedIds", convIds);
    const body = {id: convIds, op: "read"};
    this.conversationAction(body).subscribe(res => {
      console.log("processReadPostRequests res: ", res);
    });
  }

  queryPost(url, body): Observable<any> {
    if (!!body.op && (body.op === "read") && (url === "/api/convAction")) {

      console.log("[mailService] querypostread ", url, body);
      this.convReadPostQueue$.next(body);
      const response = new Subject<any>();
      const res = {
        action: body,
        _jsns: "urn:zimbraMail"
      };
      setTimeout(() => {
        response.next(res);
      }, 10);
      return response.asObservable().pipe(take(1));

    } if (((url === "/api/saveDraft") || (url === "/api/sendEmail")) && !!body.attach.fid && body.attach.fid.length) {
      const response = new Subject<any>();
      const requests: Observable<any>[] = [];

      body.attach.fid.forEach(id => {
        requests.push(this.db.fetchAttachmentById(id));
      });

      forkJoin(requests).subscribe(results => {
        const request2: Observable<any>[] = [];
        let allAttachmentUploadsWorking = true;
        for (let j = 0; j < results.length; j++) {
          request2.push(this.uploadAttachment(results[j].file));
          if (!results[j]) {
            allAttachmentUploadsWorking = false;
          }
        }
        if (!allAttachmentUploadsWorking) {
          const draftUrl = "/api/saveDraft";
          const withoutAttachmentBody = { ...body };
          delete body.attach;
          return this.http.post(
            this.configService.API_URL + draftUrl,
            withoutAttachmentBody,
            {
              headers: this.getZimbraHeader()
            }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));

        } else {
          forkJoin(request2).subscribe(results2 => {
            let attachmentIds = [];
            for (let k = 0; k < results2.length; k++) {
              const json = JSON.parse(results2[k].toString().replace("200,\'null\',", ""));
              attachmentIds.push(json[0].aid);
            }
            body.attach.aid = attachmentIds.toString();
            this.http.post(
              this.configService.API_URL + url,
              body,
              {
                headers: this.getZimbraHeader()
              }).pipe(take(1)).subscribe(res => response.next(res));
          });
        }
      });
      return response.asObservable().pipe(take(1));
    } else {
      return this.http.post(
        this.configService.API_URL + url,
        body,
        {
          headers: this.getZimbraHeader()
        }).pipe(map(this.returnResponse), catchError(this.authService.handleErrorObservable.bind(this)));
    }
  }

  syncRequest(body: any): Observable<any> {
    const t1 = new Date().getTime();
    return this.http.post(this.configService.API_URL + "/api/syncRequest", body, { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
      const performance = (t2 - t1) / 1000;
      console.log(`[API][noOp] took ${performance} s for API call`);
    }));
  }

  backgroundSyncRequest(body: any): Observable<any> {
    const result = new Subject<any>();
    try {
      let options = {
        method: "post",
        data: body,
        responseType: "json",
        headers: {
          "Authorization": this.getToken(),
          "Cache-Control": "no-cache",
          "Content-Type": "application/json",
          "Accept": "application/json"
        }
      };
      console.log("backgroundSyncRequest advanced-http request body: ", body);
      cordova.plugin.http.sendRequest(this.configService.API_URL + "/api/syncRequest", options, function(response) {

        console.log("backgroundSyncRequest advanced-http response: ", response, response.status);
        if (response && !!response.data) {
          result.next(response.data);
        } else {
          result.next({});
        }

      }, function(response) {
        console.log("backgroundSyncRequest advanced-http response: ", response, response.status);
        console.error("backgroundSyncRequest advanced-http error: ", response.error);
        if (response.error === "auth credentials have expired") {
          setTimeout(() => {
            this.backgroundSyncRequest(body).subscribe(r => {
              result.next(r);
            }, err => {
              result.error(err);
            });
          }, 1000);
        } else {
          result.error(response.error);
        }
      });
    } catch (error) {
      result.error(error);
    }


    return result.asObservable().pipe(take(1));
  }

  searchMailFromSolr(searchText: string, searchType: string, offset: number, limit: number, sort: string, attachment: string,
    to_ss: string, from_s: string, folder_id: string, tag: string, beforeDate: string, afterDate: string, exacDate: string, showMails: string): Observable<any> {
    const isSearchInTrash = this.configService.prefs.zimbraPrefIncludeTrashInSearch;
    const isSearchInSpam = this.configService.prefs.zimbraPrefIncludeSpamInSearch;
    const query: string[] = [];
    query.push("type=" + searchType);
    query.push("searchText=" + searchText);
    query.push("start=" + offset);
    query.push("limit=" + limit);
    query.push("sort=" + sort);
    query.push("showMails=" + showMails);
    let isShowTrash = "false";
    let isShowSpam = "false";
    if (!!isSearchInTrash && isSearchInTrash === "TRUE") {
      isShowTrash = "true";
    }
    if (!!isSearchInSpam && isSearchInSpam === "TRUE") {
      isShowSpam = "true";
    }
    query.push("isShowFromTrash=" + isShowTrash);
    query.push("isShowFromSpam=" + isShowSpam);
    if (attachment !== "") {
        query.push("attachment=" + attachment);
    }
    if (to_ss !== "") {
        query.push("to_ss=" + to_ss);
    }
    if (from_s !== "") {
        query.push("from_s=" + from_s);
    }
    if (folder_id !== "") {
        query.push("folder_id=" + folder_id);
    }
    if (tag !== "") {
        query.push("tag=" + tag);
    }
    if (beforeDate !== "") {
      query.push("beforeDate=" + beforeDate);
    }
    if (afterDate !== "") {
      query.push("afterDate=" + afterDate);
    }
    if (exacDate !== "") {
      query.push("exacDate=" + exacDate);
    }
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
    }
    return this.http.get(this.configService.API_URL + "/api/searchFromSolr?" + query.join("&").toString() , { headers: this.getZimbraHeader() })
    .pipe(tap(res => {
      const t2 = new Date().getTime();
    }),
    catchError(this.authService.handleErrorObservable.bind(this)));
  }

  mailSearch(searchText: string, searchType: string, offset: number, limit: number, sorts: any, attachment: string,
    to_ss: string, from_s: string, folder_id: string, tag: string, beforeDate: string, afterDate: string, exacDate: string, showMails: string, filters?: any, extraFilters?: any): Observable<any> {
    const isSearchInTrash = this.configService.prefs.zimbraPrefIncludeTrashInSearch;
    const isSearchInSpam = this.configService.prefs.zimbraPrefIncludeSpamInSearch;
    const query: string[] = [];
    let body: any = {};
    body.type = searchType;
    body.searchText = searchText || "";
    body.start = offset;
    body.limit = limit;
    body.sorts = sorts;
    body.showMails = showMails;
    if (filters === "read") {
      body.isUnread = false;
    } else if (filters === "unread") {
      body.isUnread = true;
    }
    let isShowTrash = "false";
    let isShowSpam = "false";
    if (!!isSearchInTrash && isSearchInTrash === "TRUE") {
      isShowTrash = "true";
    }
    if (extraFilters) {
      body = {...extraFilters, ...extraFilters};
    }
    if (!!isSearchInSpam && isSearchInSpam === "TRUE") {
      isShowSpam = "true";
    }
    body.isShowFromTrash = isShowTrash;
    body.isShowFromSpam = isShowSpam;
    if (attachment !== "") {
      body.attachment = attachment;
    }
    if (to_ss !== "") {
      body.to_ss = to_ss;
    }
    if (from_s !== "") {
      body.from_s = from_s;
    }
    if (folder_id !== "") {
      body.folder_id = folder_id;
    }
    if (tag !== "") {
      body.tag = tag;
    }
    if (beforeDate !== "") {
      body.beforeDate = beforeDate;
    }
    if (afterDate !== "") {
      body.afterDate = afterDate;
    }
    if (exacDate !== "") {
      body.exacDate = exacDate;
    }
    if (extraFilters) {
      body = {...body, ...extraFilters};
    }
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
    }
    return this.http.post(this.configService.API_URL + "/api/mailSearch", body, { headers: this.getZimbraHeader() })
    .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }

  getmailSearchAnother() {
    let body: any = {};
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
    }
    return this.http.post(this.configService.API_URL + "/api/mailSearch", body, { headers: this.getZimbraHeader() })
    .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }
  public getContactInfo(email: string): Observable<any> {
    const query: string[] = [];
    query.push("email=" + email);
    let headers = new HttpHeaders();
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        headers = new HttpHeaders({ "Authorization": token });
    }
    return this.http.get(this.configService.API_URL + "/api/getContactInfo?" + query.join("&").toString(), { headers: headers }).pipe(map((res: any) => {
      if (!!res && res !== null && res.contacts && res.contacts.length > 0) {
        return this.getContacts(res.contacts[0]);
      } else {
        const contact = new ContactInfo();
        contact.jid = email;
        contact.fullName = email.substring(0, email.indexOf("@"));
        contact.phones = [];
        contact.emails = [];
        contact.urls = [];
        return contact;
      }
    }));
  }

  public checkTicketAccess(email: string): Observable<any> {
    const query: string[] = [];
    query.push("email=" + email);
    let headers = new HttpHeaders();
    if (this.isCordovaOrElectron) {
      const token = localStorage.getItem("token");
      headers = new HttpHeaders({ "Authorization": token });
    }
    return this.http.get(this.configService.API_URL + "/api/checkTicketAccess?" + query.join("&").toString(), { headers: headers })
    .pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }


  public getLoggedInUserContactInfo(email: string): Observable<any> {
    let headers = new HttpHeaders();
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        headers = new HttpHeaders({ "Authorization": token });
    }
    return this.http.get(this.configService.API_URL + "/api/getLoggedInUserInfo", { headers: headers }).pipe(map((res: any) => {
      if (!!res && res !== null && res.contact) {
        return this.getContacts(res.contact);
      } else {
        const contact = new ContactInfo();
        contact.jid = email;
        contact.fullName = email.substring(0, email.indexOf("@"));
        contact.phones = [];
        contact.emails = [];
        contact.urls = [];
        return contact;
      }
    }));
  }

  public searchDocs(params: any): Observable<SearchResponse> {
    let headers = new HttpHeaders({ "Content-Type": "application/json" });
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        headers = new HttpHeaders({ "Content-Type": "application/json", "Authorization": token });
    }
    return this.http.get(this.configService.API_URL + "/api/search", { headers: headers, params: params }).pipe(map((res: any) => {
        let docs = [];
        let numFound = 0;
        let start = 0;
        let groups = {};
        if (res.response) {
            docs = res.response.docs.map(val => {
                return this.mapSearchItem(val);
            });
            numFound = res.response.numFound;
            start = res.response.start;
        } else if (res.grouped && res.grouped.type_s && res.grouped.type_s.groups) {
            numFound = res.grouped.type_s.matches;
            res.grouped.type_s.groups.forEach(group => {
                if (group.doclist.start) {
                    start = group.doclist.start;
                }
                const _docs = group.doclist.docs.map(val => {
                    return this.mapSearchItem(val);
                });
                groups[group.groupValue] = _docs;
                docs = [...docs, ..._docs];
            });
        } else if (res.grouped && res.grouped.from_s && res.grouped.from_s.groups) {
            numFound = res.grouped.from_s.matches;
            res.grouped.from_s.groups.forEach(group => {
                if (group.doclist.start) {
                    start = group.doclist.start;
                }
                const _docs = group.doclist.docs.map(val => {
                    return this.mapSearchItem(val);
                });
                groups[group.groupValue] = _docs;
                docs = [...docs, ..._docs];
            });
        }
        if (res.facet_counts && res.facet_counts.facet_fields && res.facet_counts.facet_fields.type_s) {
            const apps = {};
            const type = res.facet_counts.facet_fields.type_s;
            for (let i = 0; i <= type.length; i++) {
                if (typeof type[i] === "string" && i < type.length - 1) {
                    apps[type[i]] = type[i + 1];
                }
            }
            console.log("[searchDocs]", apps);
        } else {
        }
        return { docs: docs, numFound: numFound, start: start, groups: groups } as SearchResponse;
    }));
  }

  private mapSearchItem(doc) {
    const content = doc.content_txt ? doc.content_txt[0] : "";
    const raw = doc.raw_txt ? doc.raw_txt[0] : "";
    let parsedContent = content;
    const rawTxt = raw.replace(/\\"/ig, "\"").replace(/\\'/ig, "\'");
    if (rawTxt !== "") {
        parsedContent = rawTxt;
    }
    const shortContent = parsedContent;
    let id = doc.id;
    let objectId = doc.id;
    if (doc.type_s === "task") {
        id = doc.task_id_i;
    } else if (doc.type_s === "mail") {
        id = doc.mail_id_i;
        objectId = objectId.replace(`.${doc.mail_id_i}`, "");
    } else if (doc.type_s === "talk") {
        objectId = objectId.replace(`.${doc.owner_s}`, "").replace(`talk.${doc.talk_id_s}.`, "");
    }
    return {
        id: id,
        objectId: objectId,
        talkId: doc.talk_id_s,
        owner: doc.owner_s,
        title: doc.title_s ? doc.title_s.replace(/\\"/ig, "\"").replace(/\\'/ig, "\'") : "",
        contentTxt: content.replace(/\\n/g, "<br />"),
        parsedContent: parsedContent.replace(/\\n/g, "<br />"),
        shortContent: shortContent.replace(/\\n/g, "<br />"),
        chatType: doc.talk_type_s,
        rawTxt: raw,
        createdDt: doc.created_dt,
        modifiedDt: doc.modified_dt,
        from: doc.from_s,
        to: doc.to_ss,
        type: doc.type_s,
        version: doc._version_,
        mailFolderID: doc.mail_folder_id_i,
        unread: doc.mail_unread_b,
        flags: doc.mail_flags_s
    } as SearchItem;
  }

  private getContacts(contactItem: any): VNCContact {
    const contact = contactItem as VNCContact;
    contact.id = contactItem.id;
    if (contactItem.addresses && contactItem.addresses !== null && contactItem.addresses.length > 0) {
        contact.address = contactItem.addresses;
    }
    if (contactItem.avatar) {
        contact.avatar = contactItem.avatar;
    }
    if (contactItem.company) {
        contact.company = contactItem.company;
    }
    if (contactItem.created_at && contactItem.created_at !== null) {
        contact.created_at = new Date(contactItem.created_at);
    }
    if (contactItem.deleted_at && contactItem.deleted_at !== null) {
        contact.deleted_at = new Date(contactItem.deleted_at);
    }
    if (contactItem.emails) {
        contact.emails = contactItem.emails;
    }
    if (contactItem.first_name) {
        contact.firstName = contactItem.first_name;
    }
    if (contactItem.groups) {
        contact.groups = contactItem.groups;
    }
    if (contactItem.is_company) {
        contact.is_company = contactItem.is_company === "true" ? true : false;
    }
    if (contactItem.is_global) {
        contact.is_global = contactItem.is_global === "true" ? true : false;
    }
    if (contactItem.jid) {
        contact.jid = contactItem.jid;
    }
    if (contactItem.job_title) {
        contact.jobTitle = contactItem.job_title;
    }
    if (contactItem.last_name) {
        contact.lastName = contactItem.last_name;
    }
    if (contactItem.middle_name) {
        contact.middleName = contactItem.middle_name;
    }
    if (contactItem.phones) {
        contact.phones = contactItem.phones;
    }
    if (contactItem.updated_at && contactItem.updated_at !== null) {
        contact.updated_at = new Date(contactItem.updated_at);
    }
    if (contactItem.is_global) {
        contact.is_global = contactItem.is_global === "true" ? true : false;
    }
    if (contactItem.notes) {
        contact.notes = contactItem.notes;
    }
    if (contactItem.im_accounts && contactItem.im_accounts !== null && contactItem.im_accounts.length > 0) {
        contact.im_accounts = contactItem.im_accounts;
    }
    if (contactItem.urls) {
        contact.urls = contactItem.urls;
    }
    if (contactItem.custom_fields) {
        contact.custom_fields = contactItem.custom_fields;
    }
    if (contactItem.events && contactItem.events !== null && contactItem.events.length > 0) {
        contact.events = contactItem.events;
    }
    if (contactItem.contact_lists) {
        contact.contact_list = contactItem.contact_lists;
    }
    if (contactItem.tags) {
        contact.tags = contactItem.tags;
    }
    if (contactItem.time_zone && contactItem.time_zone !== null) {
        contact.timezone = contactItem.time_zone;
    }
    if (contactItem.language && contactItem.language !== null) {
        contact.language = contactItem.language;
    }
    if (contactItem.skills && contactItem.skills !== null && contactItem.skills.length > 0) {
        contact.skills = contactItem.skills;
    }
    if (contactItem.interests && contactItem.interests !== null && contactItem.interests.length > 0) {
        contact.interests = contactItem.interests;
    }
    if (contactItem.birthday && contactItem.birthday !== null) {
        contact.birthday = new Date(contactItem.birthday);
    }
    if (contactItem.gender && contactItem.gender !== null) {
        contact.gender = contactItem.gender;
    }
    if (contactItem.marital_status && contactItem.marital_status !== null) {
        contact.marital_status = contactItem.marital_status;
    }
    if (contactItem.private_email && contactItem.private_email !== null) {
        contact.private_email = contactItem.private_email;
    }
    if (contactItem.start_date && contactItem.start_date !== null) {
        contact.start_date = new Date(contactItem.start_date);
    }
    if (contactItem.end_date && contactItem.end_date !== null) {
        contact.end_date = new Date(contactItem.end_date);
    }
    if (contactItem.per_week_availability && contactItem.per_week_availability !== null) {
        contact.per_week_availability = contactItem.per_week_availability;
    }
    if (contactItem.hourly_rate && contactItem.hourly_rate !== null) {
        contact.hourly_rate = contactItem.hourly_rate;
    }
    if (!!contactItem.vnc_employee && contactItem.vnc_employee !== null) {
        contact.vnc_employee = contactItem.vnc_employee;
    }
    if (contactItem.payment_mode && contactItem.payment_mode !== null) {
        contact.payment_mode = contactItem.payment_mode;
    }
    if (contactItem.passport_expiry && contactItem.passport_expiry !== null) {
        contact.passport_expiry = new Date(contactItem.passport_expiry);
    }
    if (contactItem.rfc_limit && contactItem.rfc_limit !== null) {
        contact.rfc_limit = contactItem.rfc_limit;
    }
    if (contactItem.username && contactItem.username !== null) {
        contact.username = contactItem.username;
    }
    if (contactItem.admin && contactItem.admin !== null) {
        contact.admin = contactItem.admin;
    }
    if (contactItem.agb_accepted && contactItem.agb_accepted !== null) {
        contact.agb_accepted = contactItem.agb_accepted;
    }
    if (contactItem.video_bridge && contactItem.video_bridge !== null) {
        contact.video_bridge = contactItem.video_bridge;
    }
    if (contactItem.omemo && contactItem.omemo !== null) {
        contact.omemo = contactItem.omemo;
    }
    if (contactItem.id_number && contactItem.id_number !== null) {
        contact.national_id_number = contactItem.id_number;
    }
    if (contactItem.id_expiry && contactItem.id_expiry !== null) {
        contact.national_id_expiry = new Date(contactItem.id_expiry);
    }
    if (contactItem.products && contactItem.products !== null && contactItem.products.length > 0) {
        contact.products = contactItem.products;
    }
    if (contactItem.favorite && contactItem !== null) {
        contact.favorite = contactItem.favorite === "true" ? true : false;
    }
    contact.fullName = this.getFullName(contact.firstName, contact.lastName);
    contact.bgAvatarColor = CommonUtils.getRandomAvatarColor();
    return contact;
  }

  private getFullName(firstName: string, lastName: string): string {
    if (firstName && lastName) {
        return firstName + " " + lastName;
    } else {
        if (firstName && firstName !== undefined) {
            return firstName;
        } else if (lastName && lastName !== undefined) {
            return lastName;
        }
    }
  }
  solrautoSuggest(query: string): Observable<any> {
    return this.http.get(this.configService.API_URL + "/api/autosuggest?searchText=" + query,
      { headers: this.getZimbraHeader()}
    ).pipe(catchError(this.authService.handleErrorObservable.bind(this)));
  }


  getNameByEmail(emailName): Promise<string> {
    return new Promise((resolve, reject) => {
      this.db.searchContacts(emailName).subscribe(data => {
        if (data && data.length) {
          if (data[0] && data[0].fileAsStr) {
            resolve(data[0].fileAsStr);
          } else {
            resolve(emailName);
          }
        } else {
          resolve(emailName);
        }
      }, error => {
        resolve("");
      });
    });
  }

  getNameByFullEmail(email): Promise<string> {
    return new Promise((resolve, reject) => {
      this.db.searchContactsByMail(email).subscribe(contacts => {
        // console.log("getNameByFullEmail email, contacts: ", email, contacts);
        if (!!contacts && (contacts.length > 0) && !!contacts[0].fileAsStr) {
          resolve(contacts[0].fileAsStr);
        } else {
          resolve(email);
        }
      }, error => {
        resolve("");
      });
    });
  }


  getFolderNameKey(name, constant: any, cal?: boolean): string {

    if (!!name) {
      if (name !== "" && name.charAt(0) === "/") {
        name = name.substr(1);
      }
      const key = name.toUpperCase() + "_FOLDER";
      let folderName = name;
      if (constant.indexOf(key) !== -1) {
          this.translate.get(cal ? "CALENDARS." + key : "" + key).pipe(take(1)).subscribe(text => {
              folderName = text;
          });
      }
      return folderName;
    }
    return "";
 }
}
