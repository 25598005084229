
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Observable, Subject, pipe, BehaviorSubject } from "rxjs";
import { SetFirebaseToken, SetProps, SetIncludeShared, SetLoadInsecureContent, SetRightSidebarStatus, SetSavedRecentSearch, SetShowGlobalTags } from "../actions/app";
import { CommonUtils } from "../common/utils/common-util";
import { RootState, getProps, getIsDeviceReady } from "../reducers";
import { Store } from "@ngrx/store";
import { ConfigService } from "../config.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { take, filter, tap } from "rxjs/operators";
import { MailConstants } from "../common/utils/mail-constants";
import jwt_decode from "jwt-decode";

@Injectable()
export class AppService {
    private isCordovaOrElectron = environment.isCordova || environment.isElectron;
    loadedFirstTime: boolean;

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
        private store: Store<RootState>
    ) {
        this.pullFirebaseTokenFromStorage();

        if (!!localStorage.getItem("userProps")) {
          this.store.dispatch(new SetProps(JSON.parse(localStorage.getItem("userProps"))));
        }
    }

    setFirebaseToken(token?: string) {
        let data = [];
        let fcm = {};
        this.store.select(getProps).pipe(take(1)).subscribe(props => {
            if (!!props) {
                data = props.filter(p => p.zimlet === "vncmail" && p.name === "fcm");
                if (data.length > 0) {
                    try {
                        fcm = JSON.parse(data[0]["_content"]);
                    } catch (ex) {
                        console.log("parse fcm error", ex);
                    }
                }
            }
        });
        if (!!token) {
            fcm[device.uuid] = token;
        } else {
            delete fcm[device.uuid];
        }
        const subject = new Subject<any>();
        const request = {
            "ModifyPropertiesRequest": {
                "@": { "xmlns": "urn:zimbraAccount" },
                "prop": [{
                    "@": { "zimlet": "vncmail", "name": "fcm" },
                    "#": JSON.stringify(fcm)
                }]
            }
        };
        this.http.post(this.configService.API_URL + "/api/batchRequest", request,
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1)).subscribe((res: any) => {
                if (res.ModifyPropertiesResponse) {
                    subject.next(token);
                    this.pushFirebaseTokenToStore(token);
                } else if (res.Fault && res.Fault[0]) {
                    subject.error(res.Fault[0].Reason.Text);
                } else {
                    subject.error(null);
                }
            }, () => {
                subject.error(null);
            });
        return subject.asObservable();
    }

    setIncludeSharedItems(val: boolean) {
        const subject = new Subject<any>();
        const request = {
            "ModifyPropertiesRequest": {
                "@": { "xmlns": "urn:zimbraAccount" },
                "prop": [{
                    "@": { "zimlet": "vncmail", "name": MailConstants.INCLUDE_SHARED_ITEM },
                    "#": val.toString()
                }]
            }
        };
        this.http.post(this.configService.API_URL + "/api/batchRequest", request,
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1)).subscribe((res: any) => {
                if (res.ModifyPropertiesResponse) {
                this.store.dispatch(new SetIncludeShared(val));
                localStorage.setItem(MailConstants.INCLUDE_SHARED_ITEM, val.toString());
                    subject.next(val);
                } else if (res.Fault && res.Fault[0]) {
                    subject.error(res.Fault[0].Reason.Text);
                } else {
                    subject.error(null);
                }
            }, () => {
                subject.error(null);
            });
        return subject.asObservable();
    }

    setSortContactsOptions(val: string) {
        const subject = new Subject<any>();
        const request = {
            "ModifyPropertiesRequest": {
                "@": { "xmlns": "urn:zimbraAccount" },
                "prop": [{
                    "@": { "zimlet": "vncmail", "name": MailConstants.SORT_CONTACTS_OPTION },
                    "#": val
                }]
            }
        };
        this.http.post(this.configService.API_URL + "/api/batchRequest", request,
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1)).subscribe((res: any) => {
                if (res.ModifyPropertiesResponse) {
                    localStorage.setItem(MailConstants.SORT_CONTACTS_OPTION, val.toString());
                    subject.next(val);
                } else if (res.Fault && res.Fault[0]) {
                    subject.error(res.Fault[0].Reason.Text);
                } else {
                    subject.error(null);
                }
            }, () => {
                subject.error(null);
            });
        return subject.asObservable();
    }

    setPaneSashValue(key: string, val: string) {
        const subject = new Subject<any>();
        if (!navigator.onLine) {
          subject.next(null);
          return subject.asObservable();
        }
        const request = {
            "ModifyPropertiesRequest": {
                "@": { "xmlns": "urn:zimbraAccount" },
                "prop": [{
                    "@": { "zimlet": "vncmail", "name": key },
                    "#": val
                }]
            }
        };
        this.http.post(this.configService.API_URL + "/api/batchRequest", request,
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1)).subscribe((res: any) => {
                if (res.ModifyPropertiesResponse) {
                    localStorage.setItem(key, val.toString());
                    subject.next(val);
                } else if (res.Fault && res.Fault[0]) {
                    subject.error(res.Fault[0].Reason.Text);
                } else {
                    subject.error(null);
                }
            }, () => {
                subject.error(null);
            });
        return subject.asObservable();
    }

    private pullFirebaseTokenFromStorage() {
        const token = localStorage.getItem("KEY_FIREBASE_TOKEN");

        if (token) {
            this.store.dispatch(new SetFirebaseToken(token));
        }
    }

    private pushFirebaseTokenToStore(token: string) {
        this.store.dispatch(new SetFirebaseToken(token));
        localStorage.setItem("KEY_FIREBASE_TOKEN", token);
    }

    private setAppBadgeCounter(counter) {
        console.log("[RootComponent][setAppBadgeCounter]", counter);
        if (CommonUtils.isOnIOS()) {
            // for iOS we get a badge counter from push notification when in background,
            // so from the app we update iy only when on foreground
            if (!window.appInBackground) {
                window.FirebasePlugin.setBadgeNumber(counter);
            }
        } else {
            window.FirebasePlugin.setBadgeNumber(counter);
        }
    }

    getProperties(): Observable<any> {
        const subject = new Subject<any>();
        const request = {
            GetInfoRequest: {
                "@": {
                    "xmlns": "urn:zimbraAccount",
                    "sections": "props"
                }
            }
        };
        this.http.post(this.configService.API_URL + "/api/batchRequest", request,
            { headers: CommonUtils.getZimbraHeader() }).pipe(take(1)).subscribe((res: any) => {
                if (res.GetInfoResponse && res.GetInfoResponse[0] && res.GetInfoResponse[0].props && res.GetInfoResponse[0].props.prop) {
                    subject.next(res.GetInfoResponse[0].props.prop);
                    this.store.dispatch(new SetProps(res.GetInfoResponse[0].props.prop));
                } else if (res.Fault && res.Fault[0]) {
                    subject.error(res.Fault[0].Reason.Text);
                } else {
                    subject.error(null);
                }
            }, () => {
                subject.error(null);
            });
        return subject.asObservable();
    }

    saveAuthToken(token) {
      console.log("[AppService][saveAuthToken]", environment.isCordova);

      if (environment.isCordova && token) {
        this.store.select(getIsDeviceReady).pipe(filter(v => !!v), take(1)).subscribe(isReady => {
          console.log("[AppService][saveAuthToken] getIsDeviceReady", isReady);
          if (!!plugins && plugins.appPreferences) {
            plugins.appPreferences.store(() => {
              console.log("[AppService][saveAuthToken] success");
            }, () => {
              console.error("[AppService][saveAuthToken] failure");
            }, "auth-token", token);
          }
        });
      }
    }

    removeAuthToken() {
      if (environment.isCordova && !!plugins && plugins.appPreferences) {
        plugins.appPreferences.remove(() => {
          console.log("[AppService][removeAuthToken] success");
        }, () => {
          console.log("[AppService][removeAuthToken] failure");
        }, "auth-token");
      }
    }

    saveCurrentUserAddressAndDisplayName(email: string, displayName: string) {
      console.log("[AppService][saveCurrentUserAddressAndDisplayName]", email, displayName);

      if (environment.isCordova) {
        this.store.select(getIsDeviceReady).pipe(filter(v => !!v), take(1)).subscribe(isReady => {
          if (!!plugins && plugins.appPreferences) {

            plugins.appPreferences.store(() => {
              console.log("[AppService][saveCurrentUserAddressAndDisplayName] address success");
            }, () => {
              console.error("[AppService][saveCurrentUserAddressAndDisplayName] address failure");
            }, "mailCurrentUserAddress", email);

            plugins.appPreferences.store(() => {
              console.log("[AppService][saveCurrentUserAddressAndDisplayName] displayName success");
            }, () => {
              console.error("[AppService][saveCurrentUserAddressAndDisplayName] displayName failure");
            }, "mailCurrentUserDisplayName", displayName);
          }
        });
      } else {
        localStorage.setItem("currentUserEmail", email);
        localStorage.setItem("currentUserDisplayName", displayName);
      }
    }

    getCurrentUserAddressAndDisplayName(): Observable<any> {
      console.log("[AppService][getCurrentUserAddressAndDisplayName]");

      const response = new BehaviorSubject<any>("null");

      const res: any = {};

      if (environment.isCordova) {
        this.store.select(getIsDeviceReady).pipe(filter(v => !!v), take(1)).subscribe(isReady => {

          if (!!plugins && plugins.appPreferences) {
            Promise.all([
              plugins.appPreferences.fetch("mailCurrentUserAddress"),
              plugins.appPreferences.fetch("mailCurrentUserDisplayName")
            ]).then(result => {
              console.info("[AppService][getCurrentUserAddressAndDisplayName] result = ", result);

              res.currentUserEmail = result[0];
              res.currentUserDisplayName = result[1];

              response.next(res);
            }).catch(error => {
              response.error(error);
            });
          } else {
            response.error("No appPreferences plugin ready");
          }
        });
      } else {
        res.currentUserEmail = localStorage.getItem("currentUserEmail");
        res.currentUserDisplayName = localStorage.getItem("currentUserDisplayName");

        response.next(res);
      }

      return response.asObservable().pipe(filter(res => res !== "null"), take(1));
    }

    saveApiUrl() {
      console.log("[AppService][saveApiUrl]", this.configService.API_URL);

      if (environment.isCordova && !!plugins && plugins.appPreferences) {
        plugins.appPreferences.store(() => {
          console.log("[AppService][saveApiUrl] success");
        }, () => {
          console.log("[AppService][saveApiUrl] failure");
        }, "apiUrl", this.configService.API_URL + "/api");
      }
    }

    public getMyProducts(): Observable<any> {
      let headers = new HttpHeaders();
      if (this.isCordovaOrElectron) {
          const token = localStorage.getItem("token");
          headers = new HttpHeaders({ "Authorization": token });
      }
      return this.http.get(this.configService.API_URL + "/api/my-products", {
          headers: headers
      });
    }

    public getContactGroupById(id): Observable<any> {
      let headers = new HttpHeaders();
      if (this.isCordovaOrElectron) {
          const token = localStorage.getItem("token");
          headers = new HttpHeaders({ "Authorization": token });
      }
      const body = {
        id: id
      };
      return this.http.post(this.configService.API_URL + "/api/getContactGroupById", body , {
          headers: headers
      });

    }

    removeApiUrl() {
      if (environment.isCordova && !!plugins && plugins.appPreferences) {
        plugins.appPreferences.remove(() => {
          console.log("[AppService][removeApiUrl] success");
        }, () => {
          console.log("[AppService][removeApiUrl] failure");
        }, "apiUrl");
      }
    }

    removeReplyMessages() {
      if (environment.isCordova && !!plugins && plugins.appPreferences) {
        plugins.appPreferences.remove(() => {
          console.log("[AppService][removeReplyMessages] success");
        }, () => {
          console.log("[AppService][removeReplyMessages] failure");
        }, "replyMessages"); // this constant comes from 'cordova-plugin-firebase'
      }
    }

    fetchReplyMessages(): Observable<any[]> {
      const response = new Subject<any[]>();
      if (environment.isCordova && !!plugins && plugins.appPreference) {
        plugins.appPreferences.fetch((data) => {
          console.log("[AppService][fetchReplyMessages] success", data);
          if (data && CommonUtils.isOnAndroid()) {
            data = JSON.parse(data);
          }
          response.next(data || []);
        }, () => {
          console.log("[AppService][fetchReplyMessages] failure");
          response.next([]);
        }, "replyMessages"); // this constant comes from 'cordova-plugin-firebase'
      }
      return response.asObservable().pipe(take(1));
    }

    removeMarkAsReadFailedMessage() {
      if (environment.isCordova && !!plugins && plugins.appPreferences) {
        plugins.appPreferences.remove(() => {
          console.log("[AppService][removeMarkAsReadFailedMessage] success");
        }, () => {
          console.log("[AppService][removeMarkAsReadFailedMessage] failure");
        }, "markAsReadFailedRequests"); // this constant comes from 'cordova-plugin-firebase'
      }
    }

    fetchMarkAsReadFailedMessage(): Observable<any[]> {
      const response = new Subject<any[]>();
      if (environment.isCordova && !!plugins && plugins.appPreferences) {
        plugins.appPreferences.fetch((data) => {
          console.log("[AppService] fetchMarkAsReadFailedMessage success", data);
          if (data && CommonUtils.isOnAndroid()) {
            data = JSON.parse(data);
          }
          response.next(data || []);
        }, () => {
          console.log("[AppService] fetchMarkAsReadFailedMessage failure");
          response.next([]);
        }, "markAsReadFailedRequests"); // this constant comes from 'cordova-plugin-firebase'
      }
      return response.asObservable().pipe(take(1));
    }

    /* Mail`s Calendar firebase token store  */
    setFirebaseTokenForCalendar(token?: string) {
      let data = [];
      let fcm = {};
      this.store.select(getProps).pipe(take(1)).subscribe(props => {
          if (!!props) {
              data = props.filter(p => p.zimlet === "vncmail" && p.name === "mailcalfcm");
              if (data.length > 0) {
                  try {
                      fcm = JSON.parse(data[0]["_content"]);
                  } catch (ex) {
                      console.log("parse fcm error", ex);
                  }
              }
          }
      });
      if (!!token) {
          fcm[device.uuid] = token;
      } else {
          delete fcm[device.uuid];
      }
      const subject = new Subject<any>();
      const request = {
          "ModifyPropertiesRequest": {
              "@": { "xmlns": "urn:zimbraAccount" },
              "prop": [{
                  "@": { "zimlet": "vncmail", "name": "mailcalfcm" },
                  "#": JSON.stringify(fcm)
              }]
          }
      };
      this.http.post(this.configService.API_URL + "/api/batchRequest", request,
          { headers: CommonUtils.getZimbraHeader() }).pipe(take(1)).subscribe((res: any) => {
              if (res.ModifyPropertiesResponse) {
                  subject.next(token);
                  this.pushFirebaseTokenToStore(token);
              } else if (res.Fault && res.Fault[0]) {
                  subject.error(res.Fault[0].Reason.Text);
              } else {
                  subject.error(null);
              }
          }, () => {
              subject.error(null);
          });
      return subject.asObservable();
  }

  setLoadInsecureContent(val: string) {
    const subject = new Subject<any>();
    const request = {
        "ModifyPropertiesRequest": {
            "@": { "xmlns": "urn:zimbraAccount" },
            "prop": [{
                "@": { "zimlet": "vncmail", "name": MailConstants.NOT_LOAD_INSECURE_CONTENT },
                "#": val
            }]
        }
    };
    this.http.post(this.configService.API_URL + "/api/batchRequest", request,
        { headers: CommonUtils.getZimbraHeader() }).pipe(take(1)).subscribe((res: any) => {
            if (res.ModifyPropertiesResponse) {
                this.store.dispatch(new SetLoadInsecureContent(val));
                subject.next(val);
            } else if (res.Fault && res.Fault[0]) {
                subject.error(res.Fault[0].Reason.Text);
            } else {
                subject.error(null);
            }
        }, () => {
            subject.error(null);
        });
    return subject.asObservable();
  }

  setShowGlobalTags(val: string) {
    const subject = new Subject<any>();
    const request = {
      "ModifyPropertiesRequest": {
        "@": { "xmlns": "urn:zimbraAccount" },
        "prop": [{
          "@": { "zimlet": "vncmail", "name": MailConstants.SHOW_GLOBAL_TAGS },
          "#": val
        }]
      }
    };
    this.http.post(this.configService.API_URL + "/api/batchRequest", request,
      { headers: CommonUtils.getZimbraHeader() }).pipe(take(1)).subscribe((res: any) => {
        if (res.ModifyPropertiesResponse) {
          this.store.dispatch(new SetShowGlobalTags(val));
          subject.next(val);
        } else if (res.Fault && res.Fault[0]) {
          subject.error(res.Fault[0].Reason.Text);
        } else {
          subject.error(null);
        }
      }, () => {
        subject.error(null);
      });
    return subject.asObservable();
  }


  public getDirectoryTags(offset: number, limit: number): Observable<any> {
    let headers = new HttpHeaders();
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        headers = new HttpHeaders({ "Authorization": token });
    }
    return this.http.get(this.configService.API_URL + "/api/directory-tags?offset=" + offset + "&limit=" + limit, {
        headers: headers
    });
  }

  getOICAPIToken() {
    const apiToken = localStorage.getItem("api_token");
    if (!!apiToken) {
        try {
            const decoded: any = jwt_decode(apiToken);
            if (decoded && decoded.exp &&  decoded.exp * 1000 < new Date().getTime() + 15 * 60 * 1000) {
                this.getAndSetAPIToken();
            }
        } catch (error) {
            this.getAndSetAPIToken();
        }
    } else {
        this.getAndSetAPIToken();
    }
  }

  getAndSetAPIToken() {
    let headers = new HttpHeaders();
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        headers = new HttpHeaders({ "Authorization": token });
    }
    const body = {
      "token": localStorage.getItem("token")
    };
    this.http.post(this.configService.API_URL + "/api/getoidc", body , {
        headers: headers
    }).subscribe((res: any) => {
      console.log("[getAndSetAPIToken]", res);
      if (res && res.sealToken) {
          localStorage.setItem("api_token", res.sealToken);
      }
    });
  }

  createNewMeeting(payload) {
    let headers = new HttpHeaders();
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        headers = new HttpHeaders({ "Authorization": token });
    }
    return this.http.post(this.configService.API_URL + "/api/createnewmeeting", payload , {
        headers: headers
    });
  }

  updateScheduledMeeting(payload) {
    let headers = new HttpHeaders();
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        headers = new HttpHeaders({ "Authorization": token });
    }
    return this.http.post(this.configService.API_URL + "/api/updatescheduledmeeting", payload , {
        headers: headers
    });
  }

  createDirectoryTag(name: string, color: string): Observable<any> {
    let headers = new HttpHeaders();
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        headers = new HttpHeaders({ "Authorization": token });
    }
    const setColor = color === undefined || color === "" ? MailConstants.DEFAULT_COLOR : color;
    const body = {
      "tag": {
        "name": name,
        "configs" : {"color_hex" : setColor }
      }
    };
    return this.http.post(this.configService.API_URL + "/api/create-directory-tag", body , {
        headers: headers
    });
  }

  updateDirectoryUser(body: any): Observable<any> {
    let headers = new HttpHeaders();
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        headers = new HttpHeaders({ "Authorization": token });
    }
    return this.http.post(this.configService.API_URL + "/api/update-account", body , {
        headers: headers
    });
  }

  private getHeaders() {
    let headers = new HttpHeaders();
    if (this.isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        headers = new HttpHeaders({ "Authorization": token });
    }
    return headers;
  }

  public getDirectoryAccountInfo(): Observable<any> {
    return this.http.get(this.configService.API_URL + "/api/get-my-account", {
        headers: this.getHeaders()
    });
  }

  createSearchQuery(body: any) {
    return this.http.post(this.configService.API_URL + "/api/queries", body , {
      headers: this.getHeaders()
    });
  }

  deleteSearchQuery(id: any) {
    return this.http.delete(this.configService.API_URL + `/api/queries/${id}`, {
      headers: this.getHeaders()
    });
  }

  getSearchQueries(q: string = "", limit = 25) {
    return this.http.get(this.configService.API_URL + `/api/queries?q=${q}&limit=${limit}`, {
      headers: this.getHeaders()
    }).pipe(tap((res: any) => {
      if (res && res.queries) {
        this.store.dispatch(new SetSavedRecentSearch(res.queries));
      }
    }));
  }

  public setRightSidebarStatus(flag: boolean): void {
    this.store.dispatch(new SetRightSidebarStatus(flag));
  }
}
