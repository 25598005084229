
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { BreakpointObserver } from "@angular/cdk/layout";
import { Store } from "@ngrx/store";
import { take, filter } from "rxjs/operators";
import { CalendarView } from "../vp-calendar/common/calendar-common.module";
import {
  Appointment, AppointmentRequest, AppointmentInCompose
} from "../../common/models/appoinment.model";
import {
  CalendarFolder, CalendarComposeViewDefaultControl, KeyValue, SelectingCalendarData, FolderLink, CalendarAppointment
} from "../../common/models/calendar.model";
import jstimezonedetect from "jstimezonedetect";
import { isArray } from "util";
import { UserProfile } from "src/app/shared/models";
import * as moment from "moment";
import { app } from "electron";
import { ConfigService } from "src/app/config.service";
import { environment } from "src/environments/environment";
import { CommonService } from "src/app/services/ common.service.";
import { CalenderUtils } from "../utils/calender-utils";
import { ToastService } from "src/app/common/providers/toast.service";
import { RootState, getCalendarFolders, getCalendarAppointmentsByIds, getUserProfile, getOnlineStatus } from "src/app/reducers";
import { LoadCalendarAppointmentsSuccessAction, LoadMoreCalendarAppointmentsSuccessAction, CreateCalendarAppointmentSuccessAction,
  UpdateCalendarAppointmentsSuccessAction, UpdateCalendarAppointmentSuccessAction, DeleteCalendarAppointmentsSuccessAction,
  DeleteCalendarAppointmentSuccessAction, SetCalendarFolders, ResetCalendarAppointmentsAction, CreateCalendarFolderSuccess,
  UpdateCalendarFolderSuccess,
  LoadDeletedCalendarAppointmentsSuccessAction} from "src/app/actions/calendar.actions";
import { TranslateService } from "@ngx-translate/core";
import { MailConstants } from "src/app/common/utils/mail-constants";
import { ElectronService } from "src/app/services/electron.service";
import { Router } from "@angular/router";
import { MailBroadcaster } from "src/app/common/providers/mail-broadcaster.service";
import { MailUtils } from "src/app/mail/utils/mail-utils";
import { CommonUtils } from "src/app/common/utils/common-util";
import { Observable, Subject } from "rxjs";
import { MailTag } from "src/app/mail/models/mail-tag.model";
import { AppService } from "src/app/services/app.service";
import { DatabaseService } from "src/app/services/db/database.service";
import { MatDialog } from "@angular/material/dialog";
import { ShowOriginalDialogComponent } from "../show-original-dialog/show-original-dialog.component";
import { SelectionModel } from "@angular/cdk/collections";
import * as _ from "lodash";

const colors: any = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3"
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF"
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA"
  }
};
const CALENDARCONSTANT: any = {
  DEFAULT_FOLDER_COLOR: 1,
  COLOR_CODES: [
    null,
    "BLUE",
    "CYAN",
    "GREEN",
    "PURPLE",
    "RED",
    "YELLOW",
    "PINK",
    "GRAY",
    "ORANGE"
  ],
  RGB_CODES: [
    "#999999", // GRAY
    "#42a5f6", // BLUE
    "#00BCD4", // CYAN
    "#66bb6a", // GREEN
    "#5B69C3", // PURPLE
    "#F1524A", // RED
    "#ef6c00", // YELLOW
    "#E91E63", // PINK
    "#999999", // GRAY
    "#ffa500"  // ORANGE
  ]
};

@Injectable()
export class CalendarRepository {
  calendarComposeViewDefaultControl: CalendarComposeViewDefaultControl;
  appointmentInComposeModel: AppointmentInCompose;
  userProfile: UserProfile;

  totalCalendarAppointments: CalendarAppointment[] = [];
  selectingCalendar: SelectingCalendarData = <SelectingCalendarData>{};
  selectingCalendarView: CalendarView = CalendarView.Day;

  private calendarFolders: CalendarFolder[];
  private appointments: CalendarAppointment[];
  private appointment: Appointment;

  private currentAppointmentsFromDate: any = moment();
  private currentAppointmentsToDate: any = moment();

  private isTrashFolderSelected: boolean = false;
  private isInitializingData: boolean = false;

  private isGetFullMonth: boolean = false;
  private isMobileScreen: boolean = false;
  public miniCalSelectedDate: Date = new Date();
  selectedTag: MailTag;
  isOnline = false;
  checklistSelection = new SelectionModel<CalendarFolder>(true);
  folderSelectToggle = new Subject();
  isOfflineRefreshed = false;

  daysInWeek = 0;
  externalAccountForCalendar: any = [];
  refreshCalendarListView$: Subject<any> = new Subject();
  constructor(
    private breakpointObserver: BreakpointObserver,
    private service: CommonService,
    private appService: AppService,
    private store: Store<RootState>,
    private configService: ConfigService,
    private commonService: CommonService,
    private toastService: ToastService,
    private translate: TranslateService,
    private translateService: TranslateService,
    private electronService: ElectronService,
    private router: Router,
    private broadcaster: MailBroadcaster,
    private databaseService: DatabaseService,
    private matDialog: MatDialog,
    ) {
    this.isMobileScreen = this.breakpointObserver.isMatched("(max-width: 599px)");
    this.calendarFolders = [];
    this.calendarComposeViewDefaultControl = <CalendarComposeViewDefaultControl>{};
    this.appointmentInComposeModel = this.getEmptyAppointmentModel();
    this.store.select(getCalendarFolders).subscribe(folders => {
      this.initCalendarComposeViewForFolderOptions(folders);
      this.generateCalendarFolderColor(folders);
    });
    this.store.select(getUserProfile).pipe(filter(v => !!v), take(1)).subscribe((res: any ) => {
      let tempProfile: any = null;
      console.log("[getUserProfile] header", res);
      if (!!res) {
        tempProfile = res;
        tempProfile.email = this.getSingleEmail(res.email);
        this.userProfile = tempProfile;
      } else {
        const contactUser = this.electronService.isElectron
        ? this.electronService.getFromStorage("profileUser") : localStorage.getItem("profileUser");
        if (!!contactUser) {
          this.userProfile = MailUtils.parseUserProfile(contactUser);
        }
      }
    });
    this.store.select(getOnlineStatus).subscribe(res => {
      this.isOnline = res;
    });
  }

  initCalendarAppointment(currentDate?: Date): void {
    this.isGetFullMonth = true;
    // check if calendar folders is loaded
    if (this.calendarFolders.length === 0) {
      this.isInitializingData = true;
      this.getUserCalendarFolders(currentDate);
    } else {
      console.log("initCalendarAppointment-this.getCalendarAppointments");
      this.getCalendarAppointments(this.isGetFullMonth, currentDate);
    }
  }

  initCalendarComposeView(): void {
    this.calendarComposeViewDefaultControl.repeatOptions = this.repeatOptions();
    this.calendarComposeViewDefaultControl.alarmOptions = this.reminderOptions();
    this.calendarComposeViewDefaultControl.briefcaseDisplayOptions = this.displayOptions();
  }

  initCalendarComposeViewForTimePoint(date: Date) {
    this.calendarComposeViewDefaultControl.startTimePointOptions = this.generateTimePointOptions(date);
    this.calendarComposeViewDefaultControl.endTimePointOptions = this.generateTimePointOptions(date);
  }

  initCalendarComposeViewForFolderOptions(folders: CalendarFolder[]): void {
    const foldersWithoutTrash: CalendarFolder[] = [];

    folders.forEach((folder: CalendarFolder) => {
      if (folder.id !== "3" && folder.name !== "Trash" && !folder.url) {
        foldersWithoutTrash.push(folder);
        if (folder.perm && (folder.perm === "r" || folder.perm === "rp")) {
          foldersWithoutTrash.splice(foldersWithoutTrash.indexOf(folder), 1);
        }
        const childFolders = CalenderUtils.getChildFolders([folder]);
        if (childFolders.length > 0) {
          childFolders.map( f => {
            foldersWithoutTrash.push(f);
            if (folder.perm && (folder.perm === "r" || folder.perm === "rp")) {
              foldersWithoutTrash.splice(foldersWithoutTrash.indexOf(folder), 1);
            }
          });
        }
      }
    });

    this.calendarComposeViewDefaultControl.calendarFolderOptions = foldersWithoutTrash.filter(f => f.view === "appointment");
  }

  initDefaultComposeAppointmentData(requestAppointment: AppointmentInCompose): AppointmentInCompose {
    requestAppointment.subject = "";
    requestAppointment.location = "";

    // Set default calendar folder
    const calendarFolder = this.setDefaultComposeAppointment(this.calendarFolders);
    requestAppointment.calendarFolder = !!calendarFolder ? calendarFolder : this.calendarFolders[0];

    // Set default appointment display
    requestAppointment.calendarDisplay = this.calendarComposeViewDefaultControl.briefcaseDisplayOptions[2];

    // Set default appointment repeat
    requestAppointment.calendarRepeat = this.calendarComposeViewDefaultControl.repeatOptions[0];

    // Set default appointment alarm
    requestAppointment.calendarAlarm = this.calendarComposeViewDefaultControl.alarmOptions[2];

    requestAppointment.calendarTimeSpanDuration = 30;

    if (requestAppointment.isAllDay) {
      requestAppointment.endDate = requestAppointment.startDate;
    }

    return requestAppointment;
  }

  addAllCalendarAppointments(calendarAppointments: CalendarAppointment[]): void {
    this.store.dispatch(new LoadCalendarAppointmentsSuccessAction({ appointments: calendarAppointments }));
  }

  addCalendarAppointments(calendarAppointments: CalendarAppointment[]): void {
    this.store.dispatch(new LoadMoreCalendarAppointmentsSuccessAction({ appointments: calendarAppointments }));
  }

  addCalendarAppointment(calendarAppontment: CalendarAppointment): void {
    this.store.dispatch(new CreateCalendarAppointmentSuccessAction({ appointment: calendarAppontment }));
  }

  updateCalendarAppointments(calendarAppointments: CalendarAppointment[]): void {
    this.store.dispatch(new UpdateCalendarAppointmentsSuccessAction(calendarAppointments));
  }

  updateCalendarAppointment(calendarAppontment: CalendarAppointment): void {
    this.store.dispatch(new UpdateCalendarAppointmentSuccessAction({ id: calendarAppontment.eventId, changes: calendarAppontment }));
  }

  deleteCalendarAppointments(calendarAppontment: CalendarAppointment): void {
    let appointmentIds = [];
    appointmentIds = this.totalCalendarAppointments.filter((appointment: CalendarAppointment) => appointment.id === calendarAppontment.id).map(appointment => appointment.eventId);
    this.store.dispatch(new DeleteCalendarAppointmentsSuccessAction({ appointmentIds: appointmentIds }));
  }

  deleteCalendarAppointment(calendarAppontment: CalendarAppointment): void {
    this.store.dispatch(new DeleteCalendarAppointmentSuccessAction({ appointment: calendarAppontment }));
  }

  updateTimePointOptions(date: Date, isStartTime: boolean = true): void {
    if (isStartTime) {
      this.calendarComposeViewDefaultControl.startTimePointOptions = this.generateTimePointOptions(date);
    } else {
      this.calendarComposeViewDefaultControl.endTimePointOptions = this.generateTimePointOptions(date);
    }
  }

  addDeletedCalendarAppointments(deletedAppointments: CalendarAppointment[]): void {
    this.store.dispatch(new LoadDeletedCalendarAppointmentsSuccessAction(deletedAppointments ));
  }

  checkAndUpdateCalendarAppointments(calendarAppointments: CalendarAppointment[]): void {
    if (calendarAppointments.length > 0) {
      let existingCalendarAppointmentIds = [];
      let newCalendarAppointments: CalendarAppointment[];
      let existingCalendarAppointments: CalendarAppointment[];

      this.store.select(state => getCalendarAppointmentsByIds(state, calendarAppointments.map(c => c.eventId)))
        .pipe(take(1)).subscribe(appointments => {
          existingCalendarAppointmentIds = appointments.filter(c => !!c).map((c: any) => c.eventId);
        });

      newCalendarAppointments = calendarAppointments.filter(c => existingCalendarAppointmentIds.indexOf(c.eventId) === -1);
      existingCalendarAppointments = calendarAppointments.filter(c => existingCalendarAppointmentIds.indexOf(c.eventId) > -1);

      if (newCalendarAppointments.length > 0) {
        this.addCalendarAppointments(newCalendarAppointments);
      }

      if (existingCalendarAppointments.length > 0) {
        this.updateCalendarAppointments(existingCalendarAppointments);
      }
    }
  }

  getUserCalendarFolders(currentDate?: Date): void {
    this.service.getUserAllFoldersList().subscribe(res => {
      let externalAccount :any = [];
      externalAccount = res.folder[0].link;
      // console.log("calrndarrepo-getusercalendarfolders-getCalendarAppointments - external0: ", externalAccount);
      if (externalAccount && externalAccount.length && externalAccount.length > 0) {
        externalAccount.map((ext: any) => {
          ext.folder = MailUtils.getMailFoldersAppointment(ext.folder);
        });
        this.externalAccountForCalendar = externalAccount.filter(e => ((e.oname === "USER_ROOT" && e.folder.length > 0) || (!!e.view && e.view === "appointment")));
      }
      this.getUserCalendarFoldersMain(currentDate);
    }, err => {
      console.log("error in getting external account for calendar", err);
    });
  }

  getUserCalendarFoldersMain(currentDate?: Date): void {
    if (this.isOnline) {
      this.service.getUserCalendarFolders().subscribe(data => {
        let folders: CalendarFolder[] = [];
        folders = data.folder[0].folder;
        // console.log("calrndarrepo-getusercalendarfolders-getCalendarAppointments - external: ", this.externalAccountForCalendar);
        this.externalAccountForCalendar.map(ext => {
          folders.push(ext);
        })
        if (data.folder[0].link) {
         folders = folders.concat(data.folder[0].link);
        }

        console.log("calrndarrepo-getusercalendarfolders-getCalendarAppointments - folder: folders", folders);

        this.generateCalendarFolderColor(folders);
        this.store.dispatch(new SetCalendarFolders(this.calendarFolders));

        // store new data for calendar folders
        // localStorage.setItem("Calendar_Folders", JSON.stringify(data.folder[0].folder));
        if (this.isInitializingData) {
          console.log("calrndarrepo-getusercalendarfolders-getCalendarAppointments - folder: ", this.calendarFolders);
          this.databaseService.addCalendarFolders(this.calendarFolders);
          this.getCalendarAppointments(this.isGetFullMonth, currentDate);
        }
      });
    } else {
      this.databaseService.getCalendarFolders().subscribe(data => {
        this.calendarFolders = data;
        this.generateCalendarFolderColor(data);

        this.store.dispatch(new SetCalendarFolders(this.calendarFolders));

        // store new data for calendar folders
        // localStorage.setItem("Calendar_Folders", JSON.stringify(data.folder[0].folder));
        if (this.isInitializingData) {
          this.getCalendarAppointments(this.isGetFullMonth, currentDate);
        }
      });
    }
  }

  getCalendarAppointments(isGetFullMonth: boolean = false, date?: Date, addMorePreDate: number = 0, addMoreDate: number = 0): void {
    this.isGetFullMonth = isGetFullMonth;
    const currentDate = moment(date) || moment();

    if (isGetFullMonth) {
      // get full appointment for a month, with expand 12 days in pre/next month
      this.currentAppointmentsFromDate = currentDate.clone().startOf("month").subtract(6, "day");
      this.currentAppointmentsToDate = currentDate.clone().endOf("month").add(6, "day");
    } else {
      // get appointments for current Date from 00:00 to 23:59
      this.currentAppointmentsFromDate = currentDate.clone().subtract(addMorePreDate, "day").hours(0).minutes(0).seconds(0).millisecond(0);
      this.currentAppointmentsToDate = currentDate.clone().add(addMoreDate, "day").hours(23).minutes(59).seconds(59).millisecond(999);
    }
    console.log("calrndarrepo-getCalendarAppointments - start, isonline: ", this.isOnline);
    // prepare request for getting appointment
    const request: AppointmentRequest = this.generateAppointmentRequest(
      this.currentAppointmentsFromDate.format("x"), this.currentAppointmentsToDate.format("x"));

    // fetch all appointments if trash folder is selected!
    if (this.isTrashFolderSelected) {
      delete request.calExpandInstStart;
      delete request.calExpandInstEnd;
    }

    // Prepare query list for getting appointment list
    // Need to get appointment based on the selected folder from sidebar, but that feature is not implemented so just noted here.

    // if (request.query === "") {
    //   for (const folder of this.calendarFolders) {
    //     if (folder.id !== "3" || folder.name !== "Trash") {
    //       if (this.calendarFolders.indexOf(folder) > 0) {
    //         request.query += " OR ";
    //       }
    //       request.query += "inid:\"" + folder.id + "\"";
    //     }
    //   }
    // }

    // if (this.searchString !== null && angular.isDefined(this.searchString) && this.searchString !== '') {
    //   request.query = this.searchString + ' (' + request.query + ')';
    // }
    const query = this.getShareFolderQuery(this.calendarFolders);
    const isTrashChecked = query.includes("inid:\"3\"");
    this.broadcaster.broadcast("TRASH_CHECKED", isTrashChecked);
    console.log("calrndarrepo-getCalendarAppointments - getShareFolderQuery: ", query);
      request.query = query;
      if (this.selectedTag && !!this.selectedTag.name && this.selectedTag.name.indexOf(`"`) === -1) {
        request.query = `tag:"${this.selectedTag.name}" (${query})`;
        if (this.isGetFullMonth) {
          this.addAllCalendarAppointments([]);
        } else {
          this.checkAndUpdateCalendarAppointments([]);
        }
      }
      if (request.query === "") {
        if (this.isGetFullMonth) {
          this.addAllCalendarAppointments([]);
        } else {
          this.checkAndUpdateCalendarAppointments([]);
        }
        if (this.isOnline) {
          console.log("calrndarrepo-getCalendarAppointments - empty query, bail out");
          return;
        }
      }
      console.log("calrndarrepo-getCalendarAppointments - request: ", request);
      if (this.isOnline) {
        console.log("calrndarrepo-getCalendarAppointments - online: ");
        this.getAppointmentListFromDB().subscribe(appointmentsDB => {
          // removed getting from DB, as DB data here is likely to be stale

          this.service.getAppointmentList(request).pipe(take(1)).subscribe((data: any) => {
            if (data.appt) {
              let deleteAppointments = data.appt.filter(app => app.l === "3");
              deleteAppointments = this.mappingDeletedAppointmentToEvents(deleteAppointments);
              let appointments = data.appt.filter(app => app.l !== "3");
              appointments = this.mappingAppointmentToEvents(appointments);
              console.log("calendarrepo-getappointmentslist getAppointmentList", appointments);
              // console.log("date & appts: ", { date: this.currentAppointmentsFromDate, appts: data.appt });
              // console.log("date & appointments: ", { date: this.currentAppointmentsFromDate, appointments: appointments });



              this.addAllCalendarAppointments(appointments);
              this.addDeletedCalendarAppointments(deleteAppointments);

              this.addAllAppointmentsToDB(appointments);
              if (this.isGetFullMonth) {
                this.addAllCalendarAppointments(appointments);
              } else {
                // this.checkAndUpdateCalendarAppointments(appointments);
                this.addAllCalendarAppointments(appointments);
              }
            } else {
              if (this.isGetFullMonth) {
                this.addAllCalendarAppointments([]);
              } else {
                this.checkAndUpdateCalendarAppointments([]);
              }
            }
          });
        });
      } else {
        console.log("calrndarrepo-getCalendarAppointments - offline ");
        this.getAppointmentListFromDB().subscribe(appos => {
          console.log(`calendarrepo-getappointmentslist getAppointmentList (DB) appos: `, appos);
          const appointments = this.mappingDBAppointmentToEvents(appos);
          this.addAllCalendarAppointments(appointments);

          console.log(`calendarrepo-getappointmentslist getAppointmentList (DB) appointments`, appointments);
          if (!this.isOfflineRefreshed) {
            this.isOfflineRefreshed = true;
            setTimeout(() => {
              this.broadcaster.broadcast("REFRESH_CALENDAR");
            }, 300);
          }
        });
        this.addDeletedCalendarAppointments([]);

      }
      this.broadcaster.broadcast("HIDE_SYNC_SPINNER");
  }

  getShareFolderQuery(folders: CalendarFolder[]): string {
    const ids: string [] = [];
    const allFolders = [...folders , ...CalenderUtils.getChildFolders(folders)];
    folders = allFolders;
    folders.filter(folder => folder.f && folder.f.indexOf("#") !== -1).map( f => {
      if (f.perm) {
        if (f.id.indexOf(":") !== -1 ) {
          ids.push("inid:" + "\"" + f.id + "\"");
        } else {
          ids.push("inid:" + f.id);
        }
        const children = CalenderUtils.getChildFolders([f]);
        if (children.length > 0) {
          children.map( c => {
            ids.push("inid:" + "\"" + c.id + "\"");
          });
        }
      } else {
        ids.push("inid:" + "\"" + f.id + "\"");
        const children = CalenderUtils.getChildFolders([f]);
        if (children.length > 0) {
          children.map( c => {
            if (c.f && c.f.indexOf("#") !== -1) {
              ids.push("inid:" + "\"" + c.id + "\"");
            }
          });
        }
      }
    });
    return ids.join(",").replace(/,/g, " OR ");
  }

  getCalendarTimelinePosition(haftHourRatio: number): object {
    const now = moment();
    return {
      position: ((now.hour() * 60) + now.minute()) * (haftHourRatio * 4) / 60,
      label: now.format("hh:mm a")
    };
  }

  genNewAppoinmententAndUpdateChangeInCalendar(
    requestAppointment: AppointmentInCompose,
    isAddingNew: boolean = true): void {
    const appointment = this.generateNewAppointment(requestAppointment);
    if (isAddingNew) {
      this.addCalendarAppointment(appointment);
    } else {
      this.deleteCalendarAppointment(appointment);
    }
  }

  createNewAppointment(requestAppointment: AppointmentInCompose): void {
    const options = this.prepareRequestForNewAppointment(requestAppointment);
    if (this.isOnline) {
      this.service.createAppointmentWithRequest(options).subscribe(
        (response) => {
          this.updateNewCalendarAppointment(requestAppointment, response, true);
        },
        (err) => {
        });
    } else {
      let requestBody: any = { ...options };
      if (this.commonService.isFakeId(requestBody.id)) {
        delete requestBody.id;
      }
      if (this.commonService.isFakeId(requestBody.did)) {
        delete requestBody.did;
      }
      const request = {
        "url": "/api/batchRequest",
        "method": "post",
        "body":  requestBody
      };

      // fake id
      if (!requestBody.id) {
        requestBody.id = this.commonService.createFakeId(requestBody?.CreateAppointmentRequest?.m?.mp?.mp[0].content);
      }

      this.databaseService.addPendingOperation(requestBody.id, "batchRequest", request).subscribe(res => {
        this.saveAppointmentRequestApplyToDB(requestBody).subscribe(() => {
        });
    });
    }
  }

  public saveAppointmentRequestApplyToDB(appointment): Observable<any> {
    const response = new Subject<any>();

    // appointment.l = "6"; // draft
    this.addAppointmentsToDB([appointment]).subscribe(() => {
      response.next(true);
    }, err => {
      // parallel message adding issue,
      // e.g. when it's added via save draft form component and via noop - at the same time
      // so we simply ignore it and return a success responce.
      // TODO: need to implement DB transactions;
      response.next(true);
    });

    return response.asObservable().pipe(take(1));
  }

  addAppointmentsToDB(appointments: any[], query?: any) {
    const response = new Subject<any>();
    // console.log("[conversationrepo addMessagesToDB ", appointments);
    this.databaseService.addAppointments(appointments, query).subscribe(() => {
      response.next(true);
    }, err => {
      response.error(err);
    });

    return response.asObservable().pipe(take(1));
  }

  getFreeBusy(params): Observable<any> {
    return this.service.getFreeBusy(params);
  }

  getWorkingHours(params): Observable<any> {
    return this.service.getWorkingHours(params);
  }

  deleteAppointment(appointment: CalendarAppointment, isInstanced: boolean = false): void {
    let option: any = {};
    let cancelText = "Cancelled";
    this.translate.get("CANCELED_LBL").pipe(take(1)).subscribe(v => cancelText = v);
    if (isInstanced) {
      option = {
        compNum: 0,
        id: appointment.invId,
        inst: {
          d: moment(appointment.inst[0].ridZ).format("YYYYMMDDTHHmmss"),
          tz: jstimezonedetect.determine().name()
        },
        emailInfo: {
          e: [
            {
              a: this.userProfile.email,
              t: "f"
            }
          ],
          mp: {
            "mp": [{
              "ct": "text/plain",
              "content": ""
            }],
            "ct": "multipart/alternative"
          },
          su: `${cancelText}: ` + appointment.title
        },
        s: appointment.s,
        ms: appointment.ms,
        rev: appointment.rev
      };

      this.service.deleteInstanceAppointment(option).subscribe(res => {
        this.deleteCalendarAppointment(appointment);
      }, err => {
        console.log("delete appointment err log: ", err);
      });
    } else {
      option = {
        op: "trash",
        id: appointment.apptId
      };

      this.service.deleteAppointment(option).subscribe(res => {
        this.deleteCalendarAppointments(appointment);
      }, err => {
        console.log("delete appointment err log: ", err);
      });
    }
  }

  deleteAppointmentFromTrash (appointment: CalendarAppointment) {
    let option = {
      op: "delete",
      id: appointment.apptId
    };
    this.service.deleteAppointment(option).subscribe(res => {
      this.getAllAppointments();
    }, err => {
      console.log("delete appointment err log: ", err);
    });
  }

  updateNewCalendarAppointment(requestAppointment: AppointmentInCompose, appointmentExtraOptions?: any, isAppointmentSubmitted: boolean = false): void {
    const existingNewAppointment = this.generateNewAppointment(requestAppointment);
    // const appointment: CalendarAppointment = this.generateNewAppointment(requestAppointment, appointmentExtraOptions);
    if (isAppointmentSubmitted) {
      const moreDate = this.getExtraDateBasedOnCalendarView();
      this.deleteCalendarAppointment(existingNewAppointment);
      // this.getCalendarAppointments(false, existingNewAppointment.start, moreDate.extraPre, moreDate.extraNext);
      this.getAllAppointments();
    } else {
      this.updateCalendarAppointment(existingNewAppointment);
    }
  }

  refreshCalendarAppointments(currentDate: Date) {
    // clear folder cache in local storage
    // localStorage.removeItem("Calendar_Folders");
    this.calendarFolders = [];

    this.store.dispatch(new ResetCalendarAppointmentsAction());
    this.initCalendarAppointment(currentDate);
  }

  private getExtraDateBasedOnCalendarView(): { extraPre: number, extraNext: number } {
    let addMoreDate = 0;
    let addMorePreDate = 0;
    switch (this.selectingCalendarView) {
      case CalendarView.Day: {
        addMoreDate = 0;
      } break;
      case CalendarView.DayThree: {
        addMoreDate = 2;
      } break;
      case CalendarView.Week: {
        addMoreDate = 6;
        addMorePreDate = 6;
      } break;
      case CalendarView.Workweek: {
        addMoreDate = 4;
        addMorePreDate = 4;
      } break;
      case CalendarView.Month: {
        addMoreDate = 37;
        addMorePreDate = 6;
      } break;
      default: addMoreDate = 0; break;
    }

    return { extraPre: addMorePreDate, extraNext: addMoreDate };
  }

  private prepareRequestForNewAppointment(requestAppointment: AppointmentInCompose): object {
    console.log("[UserProfile]: ", this.userProfile);
    const timezone = jstimezonedetect.determine().name();
    const startTimeStamp = moment(requestAppointment.startDate).format("YYYYMMDDTHHmmss");
    const endTimeStamp = moment(requestAppointment.endDate).format("YYYYMMDDTHHmmss");
    let calenderId = "";
    calenderId = requestAppointment.calendarFolder.id;
    const requestObject = {
      "CreateAppointmentRequest": {
        "@": {
          "xmlns": "urn:zimbraMail"
        },
        "m": {
          "l": calenderId,
          "inv": {
            "comp": [{
              "at": [],
              "status": "CONF",
              "fb": requestAppointment.calendarDisplay.value,
              "fba": requestAppointment.calendarDisplay.value,
              "class": requestAppointment.isPrivate ? "PRI" : "PUB",
              "transp": "T",
              "draft": 0,
              "allDay": requestAppointment.isAllDay ? "1" : "0",
              "s": {
                "d": startTimeStamp,
                "tz": timezone
              },
              "e": {
                "d": endTimeStamp,
                "tz": timezone
              },
              "name": requestAppointment.subject,
              "loc": requestAppointment.location,
              "or": { }
            }]
          },
          "e": [],
          "su": requestAppointment.subject,
          "mp": {
            "mp": [{
              "ct": "text/plain",
              "content": ""
            }],
            "ct": "multipart/alternative"
          }
        }
      }
    };

    requestObject.CreateAppointmentRequest.m.inv.comp[0].or = { "a": this.userProfile.email};
    if (requestAppointment.calendarFolder.perm) {
      const rootFolder = this.getRootFolder(requestAppointment.calendarFolder);
      requestObject.CreateAppointmentRequest.m.inv.comp[0].or = {
        a: rootFolder.owner,
        sentBy: this.userProfile.email,
      };
      if (requestAppointment.calendarFolder.owner) {
        requestObject.CreateAppointmentRequest.m.e = [{
          a: rootFolder.owner,
          t: "f"
        }, {
          a: this.userProfile.email,
          t: "s"
        }];
      } else {
        requestObject.CreateAppointmentRequest.m.e = [{
          a: this.userProfile.email,
          t: "f"
        }, {
          a: this.userProfile.email,
          t: "s"
        }];
      }
      requestObject.CreateAppointmentRequest.m.inv.comp[0].transp = "O";
    }

    // check appointment reminder
    if (requestAppointment.calendarAlarm.value !== "" && requestAppointment.calendarAlarm.value !== 0) {
      requestObject.CreateAppointmentRequest.m.inv.comp[0]["alarm"] = this.prepareAlarmInfo(requestAppointment);
      if (requestAppointment.isPrefCalendarReminderEmail === true) {
        requestObject.CreateAppointmentRequest.m.inv.comp[0]["alarm"].push(this.prepareEmailAlarmInfo(requestAppointment)[0]);
      }
    } else if (requestAppointment.calendarAlarm.value === 0) {
      requestObject.CreateAppointmentRequest.m.inv.comp[0]["alarm"] = [{
        "action": "DISPLAY",
        "trigger": {
          "rel": {
            "m": 0,
            "related": "START",
            "neg": "1"
          }
        }
      }];
      if (requestAppointment.isPrefCalendarReminderEmail === true) {
        requestObject.CreateAppointmentRequest.m.inv.comp[0]["alarm"].push({
          "action": "EMAIL",
          "trigger": {
            "rel": {
              "m": 0,
              "related": "START",
              "neg": "1"
            }
          },
          "at": {
            "a": this.configService.prefs.zimbraPrefCalendarReminderEmail
          }
        });
      }
    } else if (requestAppointment["alarm"] !== undefined && requestAppointment["alarm"]["trigger"]) {
      // requestObject.CreateAppointmentRequest.m.inv.comp[0]["alarm"] = this.prepareAlarmInfoFromAlarmResponse(requestAppointment);
    }

    // check appointment repeating
    // Kevin: adding condition to prevent recur added while appointment.repeat.value === 'F' / text === 'FREE' will occur error.
    if (requestAppointment.calendarRepeat.value !== "NON"
      && requestAppointment.calendarRepeat.value !== "F") {
      requestObject.CreateAppointmentRequest.m.inv.comp[0]["recur"] = this.prepareRecurInfo(requestAppointment);
    }

    return requestObject;
  }

  private prepareAlarmInfo(requestAppointment: AppointmentInCompose): object {
    const alarmInfo = [{
      "action": "DISPLAY",
      "trigger": {
        "rel": {
          "related": "START",
          "neg": "1"
        }
      }
    }];

    switch (requestAppointment.calendarAlarm.group) {
      case "WEEKS_BEFORE":
      case "WEEK_BEFORE":
        alarmInfo[0].trigger.rel["w"] = requestAppointment.calendarAlarm.value;
        break;
      case "DAYS_BEFORE":
      case "DAY_BEFORE":
        alarmInfo[0].trigger.rel["d"] = requestAppointment.calendarAlarm.value;
        break;
      case "HOURS_BEFORE":
        alarmInfo[0].trigger.rel["h"] = requestAppointment.calendarAlarm.value;
        break;
      case "MINUTES_BEFORE":
      case "MINUTE_BEFORE":
        alarmInfo[0].trigger.rel["m"] = requestAppointment.calendarAlarm.value;
        break;
      case "SECONDS_BEFORE":
      case "SECOND_BEFORE":
        alarmInfo[0].trigger.rel["s"] = requestAppointment.calendarAlarm.value;
        break;
    }

    return alarmInfo;
  }

  private prepareEmailAlarmInfo(requestAppointment: AppointmentInCompose): object {
    const alarmInfo = [{
      "action": "EMAIL",
      "trigger": {
        "rel": {
          "related": "START",
          "neg": "1"
        }
      },
      "at": {
        "a": this.configService.prefs.zimbraPrefCalendarReminderEmail
      }
    }];

    switch (requestAppointment.calendarAlarm.group) {
      case "WEEKS_BEFORE":
      case "WEEK_BEFORE":
        alarmInfo[0].trigger.rel["w"] = requestAppointment.calendarAlarm.value;
        break;
      case "DAYS_BEFORE":
      case "DAY_BEFORE":
        alarmInfo[0].trigger.rel["d"] = requestAppointment.calendarAlarm.value;
        break;
      case "HOURS_BEFORE":
        alarmInfo[0].trigger.rel["h"] = requestAppointment.calendarAlarm.value;
        break;
      case "MINUTES_BEFORE":
      case "MINUTE_BEFORE":
        alarmInfo[0].trigger.rel["m"] = requestAppointment.calendarAlarm.value;
        break;
      case "SECONDS_BEFORE":
      case "SECOND_BEFORE":
        alarmInfo[0].trigger.rel["s"] = requestAppointment.calendarAlarm.value;
        break;
    }

    return alarmInfo;
  }

  private prepareAlarmInfoFromAlarmResponse(requestAppointment: AppointmentInCompose) {
    const alarmInfo = [{
      "action": "DISPLAY",
      "trigger": {
        "rel": {
          "related": "START",
          "neg": "1"
        }
      }
    }];

    // if (requestAppointment.alarm.trigger.rel && requestAppointment.alarm.trigger.rel.$) {
    //   let rel = appointment.alarm.trigger.rel.$;
    //   let relTimeKeys = ["w", "d", "h", "m", "s"];

    //   for (let key in rel) {
    //     if (rel.hasOwnProperty(key) && relTimeKeys.indexOf(key) > -1) {
    //       rel[key] = Number(rel[key]);
    //     }
    //   }

    //   alarmInfo[0].trigger.rel = rel;
    // } else {
    //   alarmInfo = undefined;
    // }

    return alarmInfo;
  }

  private prepareRecurInfo(requestAppointment: AppointmentInCompose): object {
    const recurInfo = {
      "add": {
        "rule": {
          "interval": {
            "ival": 1
          },
          "freq": requestAppointment.calendarRepeat.value
        }
      }
    };

    if (requestAppointment["recurUntil"] !== undefined) {
      recurInfo.add.rule["until"] = {
        d: requestAppointment["recurUntil"]
      };
    }

    return recurInfo;
  }

  // #region prepare data for calendar controls
  private generateTimePointOptions(date?: Date): Date[] {
    date = date ? new Date(date) : new Date();
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    const timeList: Date[] = [];

    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        const newDate = new Date(date);
        newDate.setHours(i);
        newDate.setMinutes(j * 15);
        timeList.push(newDate);
      }
    }

    return timeList;
  }

  displayOptions(): KeyValue[] {
    return [
      { value: "F", text: "FREE" },
      { value: "T", text: "TENTATIVE" },
      { value: "B", text: "BUSY" },
      { value: "O", text: "OUT_OF_OFFICE" }
    ];
  }

  repeatOptions(): KeyValue[] {
    return [
      { value: "NON", text: "DOES_NOT_REPEAT" },
      { value: "DAI", text: "EVERY_DAY" },
      { value: "WEE", text: "EVERY_WEEK" },
      { value: "MON", text: "EVERY_MONTH" },
      { value: "YEA", text: "EVERY_YEAR" }
    ];
  }

  reminderOptions(): KeyValue[] {
    // Populate data for reminder options (alarm)
    let reminderOptions: KeyValue[] = [];
    const reminderOptionTexts = {
      minutes: [1, 5, 10, 15, 30, 45, 60],
      hours: [2, 3, 4, 5, 18],
      days: [1, 2, 3, 4],
      weeks: [1, 2]
    };

    reminderOptions.push({ value: "", text: "", group: "NEVER" });
    reminderOptions.push({ value: 0, text: "", group: "AT_TIME_OF_EVENT" });

    let optionValue = 1;

    for (let i = 0; i < reminderOptionTexts.minutes.length; i++) {
      reminderOptions.push({
        group: reminderOptionTexts.minutes[i] === 1 ? "MINUTE_BEFORE" : "MINUTES_BEFORE",
        value: reminderOptionTexts.minutes[i],
        text: reminderOptionTexts.minutes[i].toString()
      });

      optionValue++;
    }

    for (let i = 0; i < reminderOptionTexts.hours.length; i++) {
      reminderOptions.push({
        group: "HOURS_BEFORE",
        value: reminderOptionTexts.hours[i],
        text: reminderOptionTexts.hours[i].toString()
      });

      optionValue++;
    }

    for (let i = 0; i < reminderOptionTexts.days.length; i++) {
      reminderOptions.push({
        group: reminderOptionTexts.days[i] === 1 ? "DAY_BEFORE" : "DAYS_BEFORE",
        value: reminderOptionTexts.days[i],
        text: reminderOptionTexts.days[i].toString()
      });

      optionValue++;
    }

    for (let i = 0; i < reminderOptionTexts.weeks.length; i++) {
      reminderOptions.push({
        group: reminderOptionTexts.weeks[i] === 1 ? "WEEK_BEFORE" : "WEEKS_BEFORE",
        value: reminderOptionTexts.weeks[i],
        text: reminderOptionTexts.weeks[i].toString()
      });

      optionValue++;
    }

    return reminderOptions;
  }

  // #endregion

  mappingAppointmentToEvents(apptRaw: Appointment[]): CalendarAppointment[] {
    const appointments: CalendarAppointment[] = [];
    let clonnedAppt = null;

    apptRaw.forEach((appt: Appointment) => {
      // If there are more than one instance of the current appt
      if (appt.inst && appt.inst.length > 0) {
        // Loop through the instances
        appt.inst.forEach((instance) => {
          clonnedAppt = { ...appt };
          // Check if the start and end date time is between the request start and end date time
          const instanceStartTime = Number(instance.s);
          const instanceEndTime = instanceStartTime + Number(appt.dur);

          if (appt.allDay) {
            // if (this.currentAppointmentsFromDate.valueOf() <= instanceStartTime && instanceStartTime <= this.currentAppointmentsToDate.valueOf()) {
              // Clone the current appt with start and end date time of current instance
              clonnedAppt.inst = [instance];
              // Push it into appointmentList
              appointments.push(this.processAppointmentData(clonnedAppt));
            // }
          } else {
              clonnedAppt.inst = [instance];
              // Push it into appointmentList
              appointments.push(this.processAppointmentData(clonnedAppt));
          }
        });
      }
    });

    return appointments;
  }

  mappingDBAppointmentToEvents(apptRaw: Appointment[]): CalendarAppointment[] {
    const appointments: CalendarAppointment[] = [];
    let clonnedAppt = null;

    apptRaw.forEach((appt: Appointment) => {
      // If there are more than one instance of the current appt
      if (!!appt) {


          clonnedAppt = { ...appt };
          // console.log("mappingDBAppointmentToEvents - appt: ", clonnedAppt);
          // Check if the start and end date time is between the request start and end date time
          const instanceStartTime = Number(appt.s);
          const instanceEndTime = instanceStartTime + Number(appt.dur);

          if (appt.allDay) {
            // if (this.currentAppointmentsFromDate.valueOf() <= instanceStartTime && instanceStartTime <= this.currentAppointmentsToDate.valueOf()) {
            // Clone the current appt with start and end date time of current instance
            clonnedAppt.inst = [appt];
            // Push it into appointmentList
            appointments.push(this.processDBAppointmentData(clonnedAppt));
            // }
          } else {
            clonnedAppt.inst = [appt];
            // Push it into appointmentList
            appointments.push(this.processDBAppointmentData(clonnedAppt));
          }

      }
    });

    return appointments;
  }


  private processAppointmentData(appt: Appointment): CalendarAppointment {
    const eventId = appt.id + "_" + appt.inst[0].ridZ;

    let startDate = parseInt(appt.inst[0].s, 10);
    let endDate = startDate + appt.dur;
    if (appt.inst[0].dur) {
      endDate = startDate + appt.inst[0].dur;
    }
    if (appt.allDay) {
      if (appt.inst && appt.inst[0].ex) {
        if (appt.inst && appt.inst[0].dur) {
          startDate = moment(startDate).startOf("day").valueOf();
          endDate = moment(startDate).add(this.getDaysFromMillis(appt.inst[0].dur) - 1, "days").endOf("day").valueOf();
        } else {
          startDate = moment(startDate).startOf("day").valueOf();
          endDate = moment(startDate).startOf("day").valueOf();
        }
      } else {
        startDate = moment(appt.inst[0].ridZ).startOf("day").valueOf();
        endDate = moment(appt.inst[0].ridZ).add(this.getDaysFromMillis(appt.dur) - 1, "days").endOf("day").valueOf();
      }
    }
    return <CalendarAppointment>{
      eventId: eventId,
      id: appt.inst[0].recur !== undefined || appt.recur ? eventId : appt.id,
      invId: appt.inst[0].invId ? appt.inst[0].invId : appt.invId,
      apptId: appt.id,
      isRepeatAppt: appt.inst[0].recur !== undefined ? appt.inst[0].recur : appt.recur,
      inst: appt.inst,
      allDay: appt.allDay,
      fb: appt.fb,
      fba: appt.fba,
      alarm: appt.alarm,
      duration: appt.dur ? appt.dur : 0,
      s: appt.s,
      ms: appt.ms,
      rev: appt.rev,
      isOrganizer: appt.isOrg,
      title: appt.name || (appt as any).title,
      location: appt.loc,
      folderId: appt.l || appt.folderId,
      start: new Date(startDate),
      end: new Date(endDate),
      bgcolor: this.getFolderColorById(appt.l),
      alarmData: appt.alarmData ? appt.alarmData : undefined,
      draggable: this.isMobileScreen ? false : this.isReadOnlyFolder(appt.l) ? false : appt.isOrg ? true : false,
      tn: appt.tn ? appt.tn : "",
      f: appt.f ? appt.f : "",
      neverSent: appt.neverSent,
      otherAtt: appt.otherAtt ? appt.otherAtt : false,
      hasEx: !!appt.hasEx ? appt.hasEx : false,
      t: appt.t ? appt.t : "",
      class: appt.class ? appt.class : "",
      ex: appt.inst && appt.inst[0].ex ? true : false,
      ptst: appt.ptst ? appt.ptst : undefined,
      description: appt.fr,
      recur: appt.recur,

    };
  }

  private processDBAppointmentData(appt: Appointment): CalendarAppointment {
    console.log("processDBAppointmentData appt: ", JSON.stringify(appt));
    const instEventId = !!appt.inst ? appt.inst[0].eventId : null;
    const eventId = !!appt.startDateData ? appt.id + "_" + appt.startDateData[0].d : instEventId;

    let startDate = (!!appt.startDateData && !!appt.startDateData[0]) ? parseInt(appt.startDateData[0].u, 10) : appt.inst[0].start;
    let endDate = (!!appt.endDateData && !!appt.endDateData[0]) ?  parseInt(appt.endDateData[0].u, 10) : appt.inst[0].end;

    if (appt.inst[0].dur) {
      endDate = startDate + appt.inst[0].dur;
    }
    if (appt.allDay) {
      if (appt.inst && appt.inst[0].ex) {
        if (appt.inst && appt.inst[0].dur) {
          startDate = moment(startDate).startOf("day").valueOf();
          endDate = moment(startDate).add(this.getDaysFromMillis(appt.inst[0].dur) - 1, "days").endOf("day").valueOf();
        } else {
          startDate = moment(startDate).startOf("day").valueOf();
          endDate = moment(startDate).startOf("day").valueOf();
        }
      } else {
        if (!!appt && !!appt.inst && !!appt.inst[0] && !!appt.inst[0].ridZ) {
          startDate = moment(appt.inst[0].ridZ).startOf("day").valueOf();
          endDate = moment(appt.inst[0].ridZ).add(this.getDaysFromMillis(appt.dur) - 1, "days").endOf("day").valueOf();
        }
        if (!!appt.startDateData && !!appt.startDateData[0] && appt.startDateData[0].d && (appt.startDateData[0].d.length === 8)) {
          startDate = moment(appt.startDateData[0].d).startOf("day").valueOf();
          endDate = moment(appt.endDateData[0].d).endOf("day").valueOf();
        }
      }
    }
    console.log("processDBAppointmentData appt post: ", startDate, endDate);
    return <CalendarAppointment>{
      eventId: eventId,
      id: appt.inst[0].recur !== undefined || appt.recur ? eventId : appt.id,
      invId: appt.inst[0].invId ? appt.inst[0].invId : appt.invId,
      apptId: appt.id,
      isRepeatAppt: appt.inst[0].recur !== undefined ? appt.inst[0].recur : appt.recur,
      inst: appt.inst,
      allDay: appt.allDay,
      fb: appt.fb,
      fba: appt.fba,
      alarm: appt.alarm,
      duration: appt.dur ? appt.dur : 0,
      s: appt.s,
      ms: appt.ms,
      rev: appt.rev,
      isOrganizer: appt.isOrg,
      title: appt.name || (appt as any).title,
      location: appt.loc,
      folderId: appt.l || appt.folderId,
      start: new Date(startDate),
      end: new Date(endDate),
      bgcolor: this.getFolderColorById(appt.l),
      alarmData: appt.alarmData ? appt.alarmData : undefined,
      draggable: this.isMobileScreen ? false : this.isReadOnlyFolder(appt.l) ? false : appt.isOrg ? true : false,
      tn: appt.tn ? appt.tn : "",
      f: appt.f ? appt.f : "",
      neverSent: appt.neverSent,
      otherAtt: appt.otherAtt ? appt.otherAtt : false,
      hasEx: !!appt.hasEx ? appt.hasEx : false,
      t: appt.t ? appt.t : "",
      class: appt.class ? appt.class : "",
      ex: appt.inst && appt.inst[0].ex ? true : false,
      ptst: appt.ptst ? appt.ptst : undefined
    };
  }


  private generateAppointmentRequest(calExpandInstStart: number, calExpandInstEnd: number): AppointmentRequest {
    return <AppointmentRequest>{
      calExpandInstStart: calExpandInstStart,
      calExpandInstEnd: calExpandInstEnd,
      limit: 500,
      offset: 0,
      fetch: "all",
      sortBy: "none",
      types: "appointment",
      query: this.isTrashFolderSelected ? "inid:'3'" : ""
    };
  }

  getFolderColorById(folderId: string): string {
    const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
    let folderColor: string = "#000099";
    if (!!folderId && !!fld) {
        fld.map( f => {
            if (folderId.toString().indexOf(":") !== -1) {
                const zid = folderId.split(":")[0];
                const rid = folderId.split(":")[1];
                if (!!f.rid && f.rid) {
                    if (f.zid === zid && f.rid.toString() === rid) {
                        folderColor = f.folderColor;
                    }
                } else {
                    if (f.id === folderId) {
                        folderColor = f.folderColor;
                    }
                }
            } else {
                if (f.id === folderId) {
                    folderColor = f.folderColor;
                }
            }
        });
    }
    return folderColor;
  }

  generateCalendarFolderColor(folders: CalendarFolder[]): void {
    folders.forEach((folder: any) => {
      if (folder.id !== "3" && folder.name !== "Trash") {
        folder.folderColor = this.getColor(folder);
      }
      const childFolder = CalenderUtils.getChildFolders([folder]);
      childFolder.map(f => {
        if (f.id !== "3") {
          f.folderColor = this.getColor(f);
        }
      });
    });

    this.calendarFolders = folders;
  }

  private generateNewAppointment(requestAppointment: AppointmentInCompose, appointmentExtraOptions?: any): CalendarAppointment {
    appointmentExtraOptions = appointmentExtraOptions || {};

    const newAppointment: CalendarAppointment = <CalendarAppointment>{
      eventId: "newId",
      // id: requestAppointment.id,
      // apptId: requestAppointment.id,
      // inviteId: requestAppointment.invid,
      // isRepeatAppt: requestAppointment.recur,
      allDay: requestAppointment.isAllDay,
      fb: requestAppointment.calendarDisplay.value,
      fba: requestAppointment.calendarDisplay.value,
      // alarm: requestAppointment.alarm,
      // duration: requestAppointment.dur ? requestAppointment.dur : 0,
      // isOrganizer: requestAppointment.isOrg,
      title: requestAppointment.subject,
      location: requestAppointment.location,
      folderId: requestAppointment.calendarFolder.id,
      start: requestAppointment.startDate,
      end: requestAppointment.endDate,
      bgcolor: this.getFolderColorById(requestAppointment.calendarFolder.id),
      isNewInMobile: this.isMobileScreen,
      resizable: {
        beforeStart: true,
        afterEnd: true
      }
    };

    if (appointmentExtraOptions["CreateAppointmentResponse"] !== undefined) {
      newAppointment.isAdded = true;
      newAppointment.eventId = appointmentExtraOptions["CreateAppointmentResponse"][0]["apptId"]
        + "_" + moment(newAppointment.start).format("YYYYMMDDTHHmmss") + "Z";
      newAppointment.apptId = appointmentExtraOptions["CreateAppointmentResponse"][0]["apptId"];
      newAppointment.calItemId = appointmentExtraOptions["CreateAppointmentResponse"][0]["calItemId"];
      newAppointment.invId = appointmentExtraOptions["CreateAppointmentResponse"][0]["calItemId"];
      newAppointment.rev = appointmentExtraOptions["CreateAppointmentResponse"][0]["rev"];
      newAppointment.isNewInMobile = false;
      delete newAppointment.resizable;
    }

    return newAppointment;
  }



  createLocalAppointment (appointment: any, isNewAppt: boolean = true) {
    console.log("calendarRepo createLocalAppointment: ", JSON.stringify(appointment), appointment, this.userProfile);
    const newAppointment: CalendarAppointment = <CalendarAppointment>{
      id: appointment.id,
      eventId: !appointment.eventId ? appointment.id : appointment.eventId,
      // id: requestAppointment.id,
      // apptId: requestAppointment.id,
      // inviteId: requestAppointment.invid,
      // isRepeatAppt: requestAppointment.recur,
      allDay: appointment.inviteInfo[0].allDay === "1",
      fb: appointment.inviteInfo[0].fb,
      fba: appointment.inviteInfo[0].fb,
      // alarm: requestAppointment.alarm,
      // duration: requestAppointment.dur ? requestAppointment.dur : 0,
      // isOrganizer: requestAppointment.isOrg,
      title: appointment.subject,
      location: appointment.inviteInfo[0].loc,
      folderId: appointment.folderId,
      ciFolder: appointment.folderId,
      start: (appointment.inviteInfo[0].allDay === "1") ? moment(appointment.inviteInfo[0].s.d).startOf("day").toDate()  : moment(appointment.inviteInfo[0].s.d).toDate(),
      end: (appointment.inviteInfo[0].allDay === "1") ? moment(appointment.inviteInfo[0].s.d).endOf("day").toDate() : moment(appointment.inviteInfo[0].e.d).toDate(),
      bgcolor: this.getFolderColorById(appointment.folderId),
      isNewInMobile: this.isMobileScreen,
      resizable: {
        beforeStart: true,
        afterEnd: true
      },
      class: appointment?.inviteInfo && appointment?.inviteInfo[0] ? appointment?.inviteInfo[0].class : "PUB",
      draggable: this.isMobileScreen ? false : this.isReadOnlyFolder(appointment?.folderId) ? false :  true,
      ex: appointment.inst && appointment.inst[0].ex ? true : false,
      hasEx: !!appointment.hasEx ? appointment.hasEx : false,
      isOrganizer: true,
      isOrg: true,
      ms: appointment.ms,
      ptst: "NE",
      rev: appointment.rev,
      isRepeatAppt: appointment?.inviteInfo[0].recur && appointment?.inviteInfo[0].recur.length ? true : false,
      // msg_request: {
      //   id: appointment.id,
      //   eventId: appointment.id,
      //   allDay: appointment.inviteInfo[0].allDay === "1",
      //   at: appointment?.inviteInfo && appointment?.ippointment?.inviteInfo && appointment?.inviteInfnviteInfo[0] ? appointment?.inviteInfo[0].at : [],
      //   recur: ao[0] ? appointment?.inviteInfo[0].recur : [],
      //   ciFolder: appointment?.folderId,
      //   class: appointment?.inviteInfo && appointment?.inviteInfo[0] ? appointment?.inviteInfo[0].class : "PUB",
      //   comp: appointment?.comp,
      //   desc: "",
      //   descHTML: appointment?.mp && appointment?.mp[0] ? appointment?.mp[0].content : "",
      //   endDateData: [appointment?.inviteInfo && appointment?.inviteInfo[0] ? appointment?.inviteInfo[0].e : null],
      //   startDateData: [appointment?.inviteInfo && appointment?.inviteInfo[0] ? appointment?.inviteInfo[0].s : null],
      //   fb: appointment.inviteInfo[0].fb,
      //   fba: appointment.inviteInfo[0].fb,
      //   isOrg: true,
      //   l: appointment?.folderId,
      //   loc: appointment.inviteInfo[0].loc,
      //   ms: appointment.ms,
      //   name: appointment.subject,
      //   or: appointment?.inviteInfo[0].or,
      //   rev: appointment.rev,
      //   status: "CONF",
      //   transp: appointment.transp,
      // }
    };
    if (!!appointment.inviteInfo) {
      console.log("calendarRepo createLocalAppointment inviteInfo: ", JSON.stringify(appointment.inviteInfo));
      const inv = [];
      const comp = appointment.inviteInfo;
      console.log("calendarRepo createLocalAppointment inviteInfo comp: ", JSON.stringify(comp));
      inv.push({ comp: comp });
      newAppointment.inv = inv;
      console.log("calendarRepo createLocalAppointment newAppointment: ", newAppointment);
    }

   return newAppointment;
  }

  private getEmptyAppointmentModel(): AppointmentInCompose {
    return <AppointmentInCompose>{
      subject: "",
      location: "",
      calendarFolder: <CalendarFolder>{
        "folderColor": ""
      },
      calendarDisplay: <KeyValue>{ value: "", text: "" },
      calendarRepeat: <KeyValue>{ value: "", text: "" },
      calendarAlarm: <KeyValue>{ value: 0, text: "" },
      calendarTimeSpanDuration: 30,
      isPrivate: false
    };
  }

  private getColor(folder: CalendarFolder): string {
    if (folder["rgb"]) {
      return folder["rgb"];
    } else if (folder["color"]) {
      const colorIndex = CALENDARCONSTANT.COLOR_CODES.indexOf(folder["color"]);
      if (colorIndex >= 0) {
        return CALENDARCONSTANT.RGB_CODES[colorIndex];
      } else {
        return CALENDARCONSTANT.COLOR_CODES[folder["color"]].toLowerCase();
      }
    } else if (folder["owner"]) {
      return CALENDARCONSTANT.RGB_CODES[CALENDARCONSTANT.DEFAULT_FOLDER_COLOR];
    } else {
      return CALENDARCONSTANT.RGB_CODES[1];
    }
  }

  private getDaysFromMillis(milliseconds: number): number {
    return ((milliseconds / 3600) / 1000) / 24;
  }

  mapAppointmentFromMsg(appointmentResponse: any): Appointment {
    return CalenderUtils.mapAppointmentFromMsg(appointmentResponse);
  }

  mapAppointmentFromAppo(appointmentResponse: any): Appointment {
    return CalenderUtils.mapAppointmentFromAppo(appointmentResponse);
  }


  printAppointment(id: string): void {
    let timeZone = jstimezonedetect.determine().name();
    timeZone = timeZone.replace("Asia/Calcutta", "Asia/Kolkata");
    if (environment.isCordova) {
      this.commonService.getMobileAppointmentPrintData(id, timeZone).pipe(take(1)).subscribe( res => {
        cordova.plugins.printer.print(res, { duplex: "long" }, function (response) {
        });
      });
    } else {
      let url = this.configService.API_URL + "/api/printAppointment?id=" + id + "&tz=" + timeZone;
      const isCordovaOrElectron = environment.isCordova || environment.isElectron;
      if (isCordovaOrElectron) {
        const token = localStorage.getItem("token");
        url = url + "&token=" + token;
      }
      window.open(url);
    }
  }

  openAttachment(id: string, part: string): void {
    const queryParams = "id=" + id + "&part=" + part + "&disp=a";
    const url = CommonUtils.addTokenToRequest(this.configService.API_URL + "/api/getAttachment/?" + queryParams);
    if (environment.isElectron) {
      ElectronService.downloadFile(url, part);
    } else {
      if (environment.isCordova) {
        cordova.InAppBrowser.open(url, "_system");
      } else {
        window.open(url, "_system");
      }
    }
  }

  newAppointmentFullComposeMapping(appointmentInCompose: AppointmentInCompose): Appointment {
    const appointMent: any = {};
    appointMent.l = appointmentInCompose.calendarFolder.id;
    appointMent.alarmData = appointmentInCompose.calendarAlarm;
    appointMent.ciFolder = appointmentInCompose.calendarFolder.id;
    appointMent.desc = "";
    appointMent.descHTML = "";
    appointMent.fb = appointmentInCompose.calendarDisplay.value;
    appointMent.fba = appointmentInCompose.calendarDisplay.value;
    appointMent.name = appointmentInCompose.subject;
    appointMent.startDateData = [{
      d: new Date(appointmentInCompose.startDate)
    }];
    appointMent.endDateData = [{
      d: new Date(appointmentInCompose.endDate)
    }];
    appointMent.allDay = appointmentInCompose.isAllDay;
    appointMent.loc = appointmentInCompose.location;
    appointMent.recur = appointmentInCompose.calendarRepeat.value.toString();
    appointMent.class = appointmentInCompose.isPrivate ? "PRI" : "PUB";
    appointMent.transp = "O";
    return appointMent;
  }

  getAppointmentData(appt: any): CalendarAppointment {
    const inv = appt.inv[0].comp[0];
    const startDate = moment(inv.s[0].d).toDate();
    const endDate = moment(inv.e[0].d).toDate();
    return <CalendarAppointment>{
      start: startDate,
      end: endDate,
      title: inv.name,
      folderId: inv.ciFolder
    };
  }

  createCalendarFolder(targetFolder: CalendarFolder, title: string, freeBusy: boolean, color?: string, url?: string) {
    let targetFolderId = null;
    if (targetFolder) {
      targetFolderId = targetFolder.id;
    } else {
      targetFolderId = "1";
    }
    let body = null;
    if (color) {
      body = { folderId: targetFolderId, name: title, view: "appointment", rgb: color, f: "#"};
    } else {
      body = { folderId: targetFolderId, name: title, view: "appointment", color: 1, f: "#"};
    }
    if (freeBusy === true) {
      body.f = body.f + "b";
    }
    if (url) {
      body.url = url;
    }
    this.commonService.createFolder(body).subscribe(res => {
      if (targetFolder) {
        if (!targetFolder.folder) {
          targetFolder.folder = [];
        }
        if (!targetFolder.folder.find(f => f.id === (res as CalendarFolder).id)) {
          targetFolder.folder.push(res as CalendarFolder);
        }
        const folder: CalendarFolder = this.getRootFolder(targetFolder);
        if (folder.perm) {
          this.toastService.show("CALENDARS.CALENDAR_CREATE_MSG");
          this.getUserCalendarFolders();
        } else {
          this.getCalendarUpdatedRootFolder(folder, "CALENDARS.CALENDAR_CREATE_MSG");
        }
      } else {
        this.store.dispatch(new CreateCalendarFolderSuccess({ folder: res as CalendarFolder }));
        this.toastService.show("CALENDARS.CALENDAR_CREATE_MSG");
        this.broadcaster.broadcast("EXTERNAL_CALENDAR_FOLDER_FOLDER", { folder: res});
      }
    },
    err => {
      if (err.indexOf("invalid name") !== -1) {
        const error = err.split("invalid name:");
        this.translateService.get(MailConstants.INVALID_NAME_ERROR_MSG, { characters: error[1]}).pipe(take(1)).subscribe((text: string) => {
          this.toastService.showPlainMessage(text);
        });
      } else if (err.indexOf("object with that name already exists") !== -1) {
        this.toastService.show("DUPLICATE_CALENDER_FOLDER_MSG");
      } else {
        this.toastService.showPlainMessage(err);
      }
    });
  }

  getRootFolder(targetFolder: CalendarFolder): CalendarFolder {
    if (!targetFolder) {
      return null;
    }
    if (targetFolder.id && targetFolder.id.includes(":")) {
      const folderNames: any[] = targetFolder.absFolderPath.split("/");
      const regShareFolderIdExp = /[^:\\]+/;
      const folderZid = targetFolder.id.match(regShareFolderIdExp)[0];

      const rootFolder = this.calendarFolders.find(folder => (folder.zid && folder.zid === folderZid &&
        folderNames.indexOf(folder.oname) > -1));
      return rootFolder;
    } else if (targetFolder.absFolderPath) {
      const reg = /[^/\\]+/;
      const rootFolderName = targetFolder.absFolderPath.match(reg)[0];
      return this.calendarFolders.find(folder => folder.name.toLowerCase() === rootFolderName.toLowerCase());
    }
  }

  getCalendarUpdatedRootFolder(folder: CalendarFolder, keyMessage?) {
    if (!folder) {
      return;
    }
    const body = {
      view: "appointment",
      folder: {
        uuid: folder.uuid
      }
    };
    this.commonService.getCalendarFolders(body).subscribe(res => {
      if (!!res && res) {
        let fld;
        if (res.folder) {
          fld = res.folder[0];
        } else if (res.link) {
          fld = res.link[0];
        }
        this.store.dispatch(new UpdateCalendarFolderSuccess({ id: fld.id, changes: fld }));
        if (keyMessage) {
          this.toastService.show(keyMessage);
        }
      }
    });
  }

  mappingAppointmentToSearchEvents(apptRaw: Appointment[]): CalendarAppointment[] {
    const appointments: CalendarAppointment[] = [];
    let clonnedAppt = null;

    apptRaw.forEach((appt: Appointment) => {
      clonnedAppt = { ...appt };
      // If there are more than one instance of the current appt
      if (appt.inst && appt.inst.length > 0) {
        // Loop through the instances
        appt.inst.forEach((instance) => {
          // Check if the start and end date time is between the request start and end date time
          const instanceStartTime = Number(instance.s);
          const instanceEndTime = instanceStartTime + Number(appt.dur);

          if (appt.allDay) {
            if (this.currentAppointmentsFromDate.valueOf() <= instanceStartTime && instanceStartTime <= this.currentAppointmentsToDate.valueOf()) {
              // Clone the current appt with start and end date time of current instance
              clonnedAppt.inst = [instance];
              // Push it into appointmentList
              appointments.push(this.processAppointmentData(clonnedAppt));
            }
          } else {
              clonnedAppt.inst = [instance];
              appointments.push(this.processAppointmentData(clonnedAppt));
          }
        });
      }
    });

    return appointments;
  }

  calendarSearch(body): Observable<any> {
    return this.commonService.calendarSearch(body);
  }

  changeFolderColor(targetFolder: CalendarFolder, folderColor) {
    this.commonService.folderAction({ id: targetFolder.id, op: "color", rgb: folderColor }).pipe(take(1)).subscribe(res => {
    const folder: CalendarFolder = this.getRootFolder(targetFolder);
    if (folder.perm) {
      this.getUserCalendarFolders();
    } else {
      this.getCalendarUpdatedRootFolder(folder);
    }
    },
    err => {
      this.toastService.showPlainMessage(err);
    });
  }

  updateCalendarFolder(targetFolder: CalendarFolder, title, folderColor?: string) {
    const oldtitle: string = targetFolder.name;
    this.commonService.folderAction({ id: targetFolder.id, name: title, op: "rename"}).pipe(take(1)).subscribe(res => {
      if (folderColor) {
        this.changeFolderColor(targetFolder, folderColor);
      }
      targetFolder.name = title;
      let folder: CalendarFolder;
      const updatedAbsPath = targetFolder.absFolderPath.replace(oldtitle, title);
       targetFolder.absFolderPath = updatedAbsPath;
      if (targetFolder.l !==  MailConstants.ROOT_MAIL_FOLDER_ID) {
        folder = this.getRootFolder(targetFolder);
      } else {
        folder = targetFolder;
      }
       this.store.dispatch(new UpdateCalendarFolderSuccess({ id: folder.id, changes: folder }));
       if (folder.perm) {
        this.getUserCalendarFolders();
       } else {
        this.getCalendarUpdatedRootFolder(folder);
       }
    },
    err => {
      this.toastService.showPlainMessage(err);
    });
  }

  getSelectedSubFolder(folderId: string, rootFolder: CalendarFolder): CalendarFolder {
    if (rootFolder === null || rootFolder === undefined) {
      return null;
    }
    if (rootFolder.id === folderId) {
      return rootFolder;
    }
    if (rootFolder.folder) {
      for (let i = 0; i < rootFolder.folder.length; i++) {
        if (folderId === rootFolder.folder[i].id) {
          return rootFolder.folder[i];
        } else if (rootFolder.folder[i].folder) {
          const f =  this.getSelectedSubFolder(folderId, rootFolder.folder[i]);
          if (f) {
            return f;
          }
        }
      }
    } else {
      return null;
    }
  }

  deleteCalendarFolder(targetFolder: CalendarFolder): void {
    let trashLbl: string = "";
    this.translate.get("CALENDARS.TRASH_FOLDER").pipe(take(1)).subscribe(res => {
      trashLbl = res;
    });
    this.commonService.folderAction({ id: targetFolder.id, op: "trash" }).subscribe(res => {
      let transatedText: string = "";
      this.translate.get("CALENDARS.CALENDAR_MOVE_TO_MSG",
      { sourceFolder: targetFolder.name,
          destinationFolder: trashLbl
      }).pipe(take(1)).subscribe((text: string) => {
          transatedText = text;
      });
      this.toastService.showPlainMessage(transatedText);
    });
  }

  permenentDeleteCalendarFolder(targetFolder: CalendarFolder): void {
    this.commonService.folderAction({ id: targetFolder.id, op: "delete" }).subscribe(res => {
      const rootFolder: CalendarFolder = this.getRootFolder(targetFolder);
      if (targetFolder.l !== MailConstants.TRASH_MAIL_FOLDER_ID) {
        const parentFolder: CalendarFolder = this.getSelectedSubFolder(targetFolder.l, rootFolder);
        parentFolder.folder.splice(parentFolder.folder.indexOf(targetFolder), 1);
        if (parentFolder.folder.length === 0) {
           parentFolder.folder = null;
        }
      } else {
        if (rootFolder.folder) {
          rootFolder.folder.splice(rootFolder.folder.indexOf(targetFolder), 1);
        } else if (rootFolder.link) {
          rootFolder.link.splice(rootFolder.link.indexOf(<FolderLink>targetFolder), 1);
        }
        if (rootFolder.folder && rootFolder.folder.length === 0) {
          rootFolder.folder = null;
        }
      }
      this.getCalendarUpdatedRootFolder(rootFolder, "FOLDER_DELETED_MSG");
    },
    err => {
      this.toastService.showPlainMessage(err);
    });
  }

  emptyCalendarFolder(targetFolder: CalendarFolder): void {
    let folderId = "";
    if ( targetFolder.owner && targetFolder.perm ) {
      folderId = targetFolder.zid + ":" + targetFolder.rid;
    } else {
      folderId = targetFolder.id;
    }
    this.commonService.folderAction({ id: folderId, op: "empty" }).subscribe(res => {
      if (targetFolder.folder) {
        targetFolder.folder.splice(targetFolder.folder.indexOf(targetFolder), 1);
      } else if (targetFolder.link) {
        targetFolder.link.splice(targetFolder.link.indexOf(<FolderLink>targetFolder), 1);
      }
      if (targetFolder.folder && targetFolder.folder.length === 0) {
        targetFolder.folder = null;
      }
      this.getCalendarUpdatedRootFolder(targetFolder, "EMPTY_FODLER_SUCCESS_MSG");
    });
  }

  showOriginalAppointment(id: string | number): void {
    const isCordovaOrElectron = environment.isCordova || environment.isElectron;
    const url = this.configService.API_URL + "/api/showOriginalAppointment?id=" + id;
    if (isCordovaOrElectron) {
      const authorization = this.electronService.isElectron
      ? this.electronService.getFromStorage("token") : localStorage.getItem("token");
      fetch(url + "&token=" + authorization)
        .then(response => {
          response.text().then(res => {
            console.log("Appointment Data", res);
            const dialog =  this.matDialog.open(ShowOriginalDialogComponent, {
              maxWidth: "100%",
              height: "100vh",
              minWidth: "100vw",
              minHeight: "100vh",
              autoFocus: false,
              panelClass: "appointment-preview-dialog",
              data: res
            });

          });
        })
        .catch(err => console.error("An error occurred:", err));

      // window.open(url + "&token=" + authorization, "_blank");
    } else {
      window.open(
        url,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yeswidth=500,height=500"
      );
    }
  }

  isReadOnlyFolder(folderId: string): boolean {
    const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
    let readOnly: boolean = false;
    if (!!folderId && !!fld) {
        const folder = fld.filter(f => !!f).filter(f => f.id === folderId)[0];
        fld.map( f => {
            if (folderId.toString().indexOf(":") !== -1) {
                const zid = folderId.split(":")[0];
                const rid = folderId.split(":")[1];
                if (!!f.rid && f.rid) {
                    if (f.zid === zid && f.rid.toString() === rid) {
                      if (f.perm === "r" || f.perm === "rp") {
                        readOnly = true;
                      }
                    }
                } else if (f.id === folderId) {
                  if (f.perm === "r" || f.perm === "rp") {
                    readOnly = true;
                  }
                }
            }
            if (f.id === folderId && f.url) {
              readOnly = true;
            }
        });
        return readOnly;
    }
  }

  reminderOptionsWithoutBefore(): KeyValue[] {

    // review

    let reminderOptions: KeyValue[] = [];
    const reminderOptionTexts = {
      minutes: [1, 5, 10, 15, 30, 45, 60],
      hours: [2, 3, 4, 5, 18],
      days: [1, 2, 3, 4],
      weeks: [1, 2]
    };

    reminderOptions.push({ value: 0, text: "", group: "NEVER" });

    let optionValue = 1;

    for (let i = 0; i < reminderOptionTexts.minutes.length; i++) {
      reminderOptions.push({
        group: reminderOptionTexts.minutes[i] === 1 ? "MINUTE" : "MINUTES",
        value: reminderOptionTexts.minutes[i],
        text: reminderOptionTexts.minutes[i].toString()
      });

      optionValue++;
    }

    for (let i = 0; i < reminderOptionTexts.hours.length; i++) {
      reminderOptions.push({
        group: "HOURS",
        value: reminderOptionTexts.hours[i],
        text: reminderOptionTexts.hours[i].toString()
      });

      optionValue++;
    }

    for (let i = 0; i < reminderOptionTexts.days.length; i++) {
      reminderOptions.push({
        group: reminderOptionTexts.days[i] === 1 ? "DAY" : "DAYS",
        value: reminderOptionTexts.days[i],
        text: reminderOptionTexts.days[i].toString()
      });

      optionValue++;
    }

    for (let i = 0; i < reminderOptionTexts.weeks.length; i++) {
      reminderOptions.push({
        group: reminderOptionTexts.weeks[i] === 1 ? "WEEK" : "WEEKS",
        value: reminderOptionTexts.weeks[i],
        text: reminderOptionTexts.weeks[i].toString()
      });

      optionValue++;
    }
    return reminderOptions;
   }

  setDefaultComposeAppointment(calendarFolders: CalendarFolder[]): CalendarFolder {
    let calendarFolder: CalendarFolder;
    const allFolders = this.calendarComposeViewDefaultControl.calendarFolderOptions;
    const checkedFolders = allFolders.filter(folder => folder.f && folder.f.indexOf("#") !== -1);
    if (checkedFolders && checkedFolders.length === 1) {
      calendarFolder = checkedFolders[0];
    } else {
      calendarFolder = allFolders.filter( folder => folder.id === "10")[0];
    }
    return calendarFolder;
  }

  brokenLinkDeleteCalendarFolder(targetFolder: CalendarFolder): void {
    this.commonService.folderAction({ id: targetFolder.id, op: "delete" }).subscribe(res => {
      this.getUserCalendarFolders();
    },
    err => {
      this.toastService.showPlainMessage(err);
    });
  }

  isCalendarCheck(folderId: string): boolean {
    let check: boolean = false;
    const allFolders = [...this.calendarFolders, ...CalenderUtils.getChildFolders(this.calendarFolders)];
    allFolders.map(f => {
      if (folderId.toString().indexOf(":") !== -1) {
        const zid = folderId.split(":")[0];
        const rid = folderId.split(":")[1];
        if (!!f.rid && f.rid) {
            if (f.zid === zid && f.rid.toString() === rid && f.f && f.f.indexOf("#") !== -1) {
              check = true;
            }
        } else {
          if (f.id === folderId && f.f && f.f.indexOf("#") !== -1) {
            check = true;
          }
        }
      } else {
        if (f.id === folderId && f.f && f.f.indexOf("#") !== -1) {
          check = true;
        }
      }
    });
    return check;
  }

  reloadCalendarFolder(folder: CalendarFolder): void {
    const body = {
      id: folder.id,
      op: "sync"
    };
    this.commonService.folderAction(body).pipe(take(1)).subscribe(res => {
      this.getAllAppointments();
    });
  }

  getAllAppointments(): void {
    let currentDate = this.miniCalSelectedDate;
    if (currentDate === undefined || currentDate === null) {
      currentDate = new Date();
    }
    let addMoreDate = 0;
    let addMorePreDate = 0;
    switch (this.selectingCalendarView) {
      case CalendarView.Day: {
        addMoreDate = 0;
      } break;
      case CalendarView.DayThree: {
        addMoreDate = 2;
      } break;
      case CalendarView.Week: {
        addMoreDate = 6;
        addMorePreDate = 6;
      } break;
      case CalendarView.Workweek: {
        addMoreDate = 4;
        addMorePreDate = 4;
      } break;
      case CalendarView.Month: {
        if (currentDate) {
          currentDate.setDate(1);
        }
        addMorePreDate = 6;
        addMoreDate = 37;
      } break;
      default: addMoreDate = 0; break;
    }
    CalenderUtils.calendarTimeLineScroll();
    if (this.selectingCalendarView !== CalendarView.List) {
      this.miniCalSelectedDate = currentDate;
      console.log("caledarrepo-getallappointments-getCalendarAppointments");
      this.getCalendarAppointments(true, currentDate, addMorePreDate, addMoreDate);
    }
  }

  openComposeForHTMLFreeBusy(value: string): void {
    let paramter: string = "";
    if (value === "html") {
      paramter = "fmt=freebusy";
    } else if (value === "ics") {
      paramter = "fmt=ifb";
    } else if (value === "ics-event") {
      paramter = "fmt=ifb&fbfmt=event";
    }
    const serverURL = !!this.configService.zimbraURL ? this.configService.zimbraURL : this.getServerURL();
    const linkItem = serverURL + "/home/" + this.userProfile.email + "?" + paramter;
    this.router.navigate(["/mail/compose"]);
    this.broadcaster.broadcast(MailConstants.BROADCAST_MAIL_SELECTED_TAB);
    setTimeout(() => {
      this.broadcaster.broadcast("SEND_LINK_FROM_CALENDAR", {
        url: linkItem.replace(/ /g, "%20")
      });
    }, 200);
  }

  getServerURL(): string {
    const isCordovaOrElectron = environment.isCordova || environment.isElectron;
    let serverURL = location.origin;
    if (isCordovaOrElectron) {
      serverURL = localStorage.getItem(MailConstants.SERVER_URL).trim();
    }
     return serverURL;
   }

  getSingleEmail(emails) {
    if (isArray(emails)) {
      return emails[0];
    } else if (emails) {
      return emails;
    }
    return null;
  }

  mappingListAppointmentToEvents(apptRaw: Appointment[]): CalendarAppointment[] {
    const appointments: CalendarAppointment[] = [];
    let clonnedAppt = null;

    apptRaw.forEach((appt: Appointment) => {
      clonnedAppt = { ...appt };
      // If there are more than one instance of the current appt
      if (appt.inst && appt.inst.length > 0) {
        // Loop through the instances
        appt.inst.forEach((instance) => {
          // Check if the start and end date time is between the request start and end date time
          const instanceStartTime = Number(instance.s);
          const instanceEndTime = instanceStartTime + Number(appt.dur);

          if (appt.allDay) {
            if (this.currentAppointmentsFromDate.valueOf() <= instanceStartTime && instanceStartTime <= this.currentAppointmentsToDate.valueOf()) {
              // Clone the current appt with start and end date time of current instance
              clonnedAppt.inst = [instance];
              // Push it into appointmentList
              appointments.push(this.processListAppointmentData(clonnedAppt));
            }
          } else {
              clonnedAppt.inst = [instance];
              // Push it into appointmentList
              appointments.push(this.processListAppointmentData(clonnedAppt));
          }
        });
      }
    });

    return appointments;
  }

  mappingDeletedAppointmentToEvents(apptRaw: Appointment[]): CalendarAppointment[] {
    const appointments: CalendarAppointment[] = [];
    let clonnedAppt = null;

    apptRaw.forEach((appt: Appointment) => {
      clonnedAppt = { ...appt };
      // If there are more than one instance of the current appt
      if (appt.inst && appt.inst.length > 0) {
          // Check if the start and end date time is between the request start and end date time
          const instanceStartTime = Number(appt.inst[0].s);
          const instanceEndTime = instanceStartTime + Number(appt.dur);

          if (appt.allDay) {
            if (this.currentAppointmentsFromDate.valueOf() <= instanceStartTime && instanceStartTime <= this.currentAppointmentsToDate.valueOf()) {
              // Clone the current appt with start and end date time of current instance
              clonnedAppt.inst = [appt.inst[0]];
              // Push it into appointmentList
              appointments.push(this.processListAppointmentData(clonnedAppt));
            }
          } else {
              clonnedAppt.inst = [appt.inst[0]];
              // Push it into appointmentList
              appointments.push(this.processListAppointmentData(clonnedAppt));
          }
      }
    });

    return appointments;
  }

  private processListAppointmentData(appt: Appointment): CalendarAppointment {
    const eventId = appt.id + "_" + appt.inst[0].ridZ;

    let startDate = parseInt(appt.inst[0].s, 10);
    let endDate = startDate + appt.dur;

    if (appt.allDay) {
      startDate = moment(new Date(startDate)).startOf("day").valueOf();
      endDate = moment(startDate).add(this.getDaysFromMillis(appt.dur) - 1, "days").endOf("day").valueOf();
    }

    return <CalendarAppointment>{
      eventId: eventId,
      id: appt.id,
      invId: appt.inst[0].invId ? appt.inst[0].invId : appt.invId,
      apptId: appt.id,
      isRepeatAppt: appt.inst[0].recur !== undefined ? appt.inst[0].recur : appt.recur,
      inst: appt.inst,
      allDay: appt.allDay,
      fb: appt.fb,
      fba: appt.fba,
      alarm: appt.alarm,
      duration: appt.dur ? appt.dur : 0,
      s: appt.s,
      ms: appt.ms,
      rev: appt.rev,
      isOrganizer: appt.isOrg,
      title: appt.name,
      location: appt.loc,
      folderId: appt.l || appt.l,
      start: new Date(startDate),
      end: new Date(endDate),
      bgcolor: this.getFolderColorById(appt.l),
      alarmData: appt.alarmData ? appt.alarmData : undefined,
      draggable: this.isMobileScreen ? false : this.isReadOnlyFolder(appt.l) ? false : appt.isOrg ? true : false,
      tn: appt.tn ? appt.tn : "",
      f: appt.f ? appt.f : "",
      neverSent: appt.neverSent,
      otherAtt: appt.otherAtt ? appt.otherAtt : false,
      hasEx: !!appt.hasEx ? appt.hasEx : false,
      t: appt.t ? appt.t : "",
      class: appt.class ? appt.class : "",
      or: appt.or,
      status: appt.status,
      created: new Date(appt.d),
      ptst: appt.ptst
    };
  }

  getAllFolderQuery(): string {
    return this.getShareFolderQuery(this.calendarFolders);
  }

  getCheckedCalendarFolder(folderId: string): string {
    let calFolderId: string = folderId;
    const allFolders = [...this.calendarFolders, ...CalenderUtils.getChildFolders(this.calendarFolders)];
    if (!!allFolders && allFolders.length > 0) {
      const folder = allFolders.filter(f => f.id === folderId)[0];
      if (!!folder) {
        if (folder.f && folder.f.indexOf("#") !== -1) {
          calFolderId = folderId;
        } else {
          const allCheckedFolder = allFolders.filter(f => f.f && f.f.indexOf("#") !== -1);
          if (!!allCheckedFolder && allCheckedFolder.length > 0) {
            const allowdFolder = allCheckedFolder.filter(f => !f.perm);
            if (!!allowdFolder && allowdFolder.length > 0) {
              calFolderId = allowdFolder[0].id;
            } else {
              calFolderId = "10";
            }
          } else {
            calFolderId = "10";
          }
        }
      }
    }
    return calFolderId;
  }

  getFolderById(folderId: string): CalendarFolder {
    const fld = [...this.calendarFolders , ...CalenderUtils.getChildFolders(this.calendarFolders)];
    let calFolder: CalendarFolder;
    if (!!folderId && !!fld) {
        fld.map( f => {
            if (folderId.toString().indexOf(":") !== -1) {
                const zid = folderId.split(":")[0];
                const rid = folderId.split(":")[1];
                if (!!f.rid && f.rid) {
                    if (f.zid === zid && f.rid.toString() === rid) {
                        calFolder = f;
                    }
                } else {
                    if (f.id === folderId) {
                      calFolder = f;
                    }
                }
            } else {
                if (f.id === folderId) {
                  calFolder = f;
                }
            }
        });
    }
    return calFolder;
 }

  showNoInternetToastIfRequired() {
    if (!navigator.onLine) {
      this.toastService.show("NO_INTERNET_CONNECTION");
      return true;
    }
    return false;
  }
  addAllAppointmentsToDB(appointments: any[], query?: any) {
    console.log("calendarRepo-addAllAppointmentsToDB: ", appointments);
    const response = new Subject<any>();

    const appIds = _.uniqBy(appointments.map(a => a.apptId));
    console.log("appointsBatchMap: ", appointments, appIds);
    this.service.getBatchAppointmentRequest(appIds).subscribe(res => {
      console.log("appointsBatchMapRes: ", res);
      if (!!res && !!res.appt && (res.appt.length > 0)) {
        let mappedAppointments = [];
        res.appt.forEach(element => {
          const mappedAppointment = this.mapAppointmentFromAppo(element);
          mappedAppointments.push(mappedAppointment);
        });
        // .then(() => {
          console.log("store appointsBatchMapRes: ", mappedAppointments);
          this.databaseService.addAppointments(mappedAppointments).subscribe(() => {
            response.next(true);
          }, err => {
            response.error(err);
          });
        // });
      }
    });
    /*
    this.databaseService.addAppointments(appointments).subscribe(() => {
      response.next(true);
    }, err => {
      response.error(err);
    });
    */
    return response.asObservable().pipe(take(1));
  }

  private getAppointmentListFromDB(): Observable<any[]> {
    console.log(`[calendarRepository][getAppointmentListFromDB]`);

    const response = new Subject<any[]>();

    let observable: Observable<any[]>;


    observable = this.databaseService.getAllAppointments();

    // get from DB
    observable.subscribe(appointment => {
      // it's required for 'getCurrentConversations' selector to work properly
      // appointment.forEach(c => {
      //   c.query = query.originalQuery;
      // });
      console.log(`[calendarRepository][getAppointmentListFromDB] res: `, appointment);
      response.next(appointment);
    }, error => {
      console.error(`[calendarRepository][getAppointmentListFromDB]`, error);

      response.next([]);
    });

    return response.asObservable().pipe(take(1));
  }

  createNewMeeting(payload) {
    return this.appService.createNewMeeting(payload);
  }

  updateScheduledMeeting(payload) {
    return this.appService.updateScheduledMeeting(payload);
  }

}
