
<!--
  ~ VNCmail : A whole new experience in enterprise email communication.
  ~ Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
  ~
  ~ This program is free software: you can redistribute it and/or modify
  ~ it under the terms of the GNU Affero General Public License as published by
  ~ the Free Software Foundation, version 3 of the License.
  ~
  ~ This program is distributed in the hope that it will be useful,
  ~ but WITHOUT ANY WARRANTY; without even the implied warranty of
  ~ MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
  ~ GNU Affero General Public License for more details.
  ~
  ~ You should have received a copy of the GNU Affero General Public License
  ~ along with this program. Look for COPYING file in the top folder.
  ~ If not, see http://www.gnu.org/licenses/.
  -->

<div class="vp-participants-dialog">
  <div class="header-block">
      <div>{{'CALENDARS.PARTICIPANTS' | translate}}</div>
      <button mat-icon-button aria-label="icon-button" (click)="close()"  matTooltip="{{ 'CLOSE' | translate }}" class="close-button">
        <ng-container [ngTemplateOutlet]="closeIcon"></ng-container>
    </button>
  </div>
  <div class="participants-dialog-content" *ngIf="participantsList.length == data?.participantsList?.at?.length">
    <div class="list-block" *ngFor="let i of  participantsList">
      <div class="image-name">
        <vp-avatar [type]="'mail-detail-avatar-40'" [user]="{firstLastCharacters: i.a.charAt(0), color: '#317bbc', email: i.a}"></vp-avatar>
        <span>{{i.name ? i.name : i.d}}</span>
      </div>
      <div (mouseover)="openProfile($event, i)" (mouseout)="hideProfile()"> <ng-container [ngTemplateOutlet]="infoIcon"></ng-container></div>
    </div>
  </div>
</div>

<div #profileDialog class="profile-info-box" (mouseleave)="hideProfile(true)" (mouseover)="showProfile()" *ngIf="selectedParticipant">
  <div class="profile-info-box__top">
    <div class="profile-avatar">
      <vp-avatar [type]="'mail-detail-avatar-40'" [user]="{firstLastCharacters: selectedParticipant.a.charAt(0), color: '#317bbc', email: selectedParticipant.a}"></vp-avatar>
    </div>
    <div class="profile-info">
      <div class="profile-name">{{ selectedParticipant.a }}</div>
    </div>
  </div>
  <div class="profile-info-box__bottom">
    <label>{{ 'EMAIL' | translate }}</label>
    <div class="email-info">
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" class="ng-star-inserted"><g  fill="none" fill-rule="evenodd"><g  fill="#8b96a0"><g ><path  d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z" transform="translate(-6 -6) translate(6 6)"></path></g></g></g></svg>
      <div class="email-address">{{ selectedParticipant.a }}</div>
      <vnc-icon-container matTooltip="{{ 'COPY_TO_CLIPBOARD' | translate }}" id=""copyEmailBtn (click)="copyEmail(selectedParticipant.a)">
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="ng-star-inserted"><path d="M19 5c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H8c-1.1 0-2-.9-2-2V7c0-1.1.9-2 2-2zm0 2H8v14h11V7zm-4.064-5a1 1 0 0 1 .117 1.993L14.936 4H7.333c-1.235 0-2.246.96-2.328 2.174L5 6.334v11.683a1 1 0 0 1-1.993.116L3 18.017V6.333a4.333 4.333 0 0 1 4.123-4.328L7.333 2h7.603z" fill="#8B96A0" fill-rule="nonzero"></path></svg>
      </vnc-icon-container>
    </div>

  </div>
</div>


<ng-template  #closeIcon>
  <svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <title>Icon/product/close-new</title>
      <g id="Icon/product/close-new" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <path d="M18.9497475,5.05025253 C19.3402718,5.44077682 19.3402718,6.0739418 18.9497475,6.46446609 L13.4142136,12 L18.9497475,17.5355339 C19.3402718,17.9260582 19.3402718,18.5592232 18.9497475,18.9497475 C18.5592232,19.3402718 17.9260582,19.3402718 17.5355339,18.9497475 L12,13.4142136 L6.46446609,18.9497475 C6.0739418,19.3402718 5.44077682,19.3402718 5.05025253,18.9497475 C4.65972824,18.5592232 4.65972824,17.9260582 5.05025253,17.5355339 L10.5857864,12 L5.05025253,6.46446609 C4.65972824,6.0739418 4.65972824,5.44077682 5.05025253,5.05025253 C5.44077682,4.65972824 6.0739418,4.65972824 6.46446609,5.05025253 L12,10.5857864 L17.5355339,5.05025253 C17.9260582,4.65972824 18.5592232,4.65972824 18.9497475,5.05025253 Z" id="Path" fill="#8B96A0"></path>
      </g>
  </svg>
</ng-template>
<ng-template  #infoIcon>
<svg width="18px" height="18px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="ng-star-inserted"><g id="Icon/product/info-outline" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M11,17 L13,17 L13,11 L11,11 L11,17 L11,17 Z M12,2 C6.48,2 2,6.48 2,12 C2,17.52 6.48,22 12,22 C17.52,22 22,17.52 22,12 C22,6.48 17.52,2 12,2 L12,2 Z M12,20 C7.59,20 4,16.41 4,12 C4,7.59 7.59,4 12,4 C16.41,4 20,7.59 20,12 C20,16.41 16.41,20 12,20 L12,20 Z M11,9 L13,9 L13,7 L11,7 L11,9 L11,9 Z" id="icon" fill="#8B96A0"></path></g></svg>
</ng-template>

