/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2021 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */


import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class ElectronService {
  app: any;
  remote: any;
  shell: any;
  win: any;
  ipcRenderer: any;
  desktopCapturer: any;
  store: any;
  electronLog: any;
  oldcount: number = 0;
  powerMonitor: any;
  onFocused$ = new Subject<any>();
  onBlurred$ = new Subject<any>();
  static downloadFile(url: string, fileName?: string) {
      const element = document.createElement("a");
      element.setAttribute("href", url);
      element.setAttribute("download", fileName);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
  }

  constructor() {
    if (this.isElectron) {
      this.remote = window.electron.remote;
      this.ipcRenderer = window.electron.ipcRenderer;
      this.desktopCapturer = {
        getSources: (opts) => this.ipcRenderer.invoke("DESKTOP_CAPTURER_GET_SOURCES", opts)
      };
      this.shell = window.electron.shell;
      this.app = this.remote.app;
      this.win = this.remote.getCurrentWindow();
      this.electronLog = this.remote.require("electron-log");
      this.powerMonitor = this.remote.powerMonitor;
      this.md5 = window.electron.blueimpMD5;
      const Store = window.electron.electronStore;
      this.store = new Store();

      // https://electronjs.org/docs/api/web-contents#contentssendchannel-arg1-arg2-
      this.ipcRenderer.on("app_cycle", (event, message) => {
        if (message === "focus") {
          this.onFocused$.next(Date.now());
        }
      });
    }
  }

  get isElectron(): boolean {
    return window && window.process && window.process.type;
  }

  get isWindows(): boolean {
    return window && window.process && window.process.platform === "win32";
  }

  get isMacOS(): boolean {
    return window && window.process && window.process.platform === "darwin";
  }

  get isLinux(): boolean {
    return window && window.process && window.process.platform === "linux";
  }

  get appVersion(): string {
    return this.app.getVersion();
  }

  remoteLog(tag: any, msg?: any) {
    if (this.isElectron) {
      try {
        this.electronLog.info(tag, msg);
      } catch (error) {
        console.error("electronLog error: ", error);
      }
    }
  }

  getSystemIdleState() {
    if (this.isElectron) {
      let systemIdleState = "unknown";
      try {
        systemIdleState = this.powerMonitor.getSystemIdleState(30);
      } catch (error) {
        console.error("getSystemIdleState error: ", error);
        this.remoteLog("getSystemIdleState error: ", error);
      }
      return systemIdleState;
    }
  }

  setBadge(count: number): void {
    console.log("[setBadge]", count);
    if (this.isWindows) {
      this.ipcRenderer.sendSync("update-badge", count ? count : null);
    } else {
      this.app.setBadgeCount(count);

      if (this.isMacOS && count) {
        if (count > this.oldcount) {
          this.app.dock.bounce();
        }
        this.oldcount = count;
      }

    }
  }

  openExternalUrl(url: string): void {
    this.shell.openExternal(url);
  }

  showApp(): void {
    this.win.show();
  }

  md5(str: string): string {
    return this.md5(str);
  }

  setToStorage(key: string, value: any): void {
    this.store.set(key, value);
  }

  getFromStorage(key: string): any {
    if (this.store) {
      return this.store.get(key);
    }
    return "";
  }

  deleteFromStorage(key: string): void {
    this.store.delete(key);
  }

  clearStorage(): void {
    this.store.clear();
  }
}
