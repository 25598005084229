
/*
 * VNCmail : A whole new experience in enterprise email communication.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Component, OnInit, Inject, OnDestroy, NgZone, ViewChild, TemplateRef, ElementRef } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MailService } from "src/app/mail/shared/services/mail-service";
import { CommonUtils } from "src/app/common/utils/common-util";
import { ToastService } from "src/app/common/providers/toast.service";

@Component({
  selector: "vp-participants-dialog",
  templateUrl: "./participants-dialog.component.html",
  styleUrls: ["./participants-dialog.component.scss"],
})
export class ParticipantsDialogComponent implements OnInit {
  @ViewChild("profileDialog", {static: false}) profileDialog: ElementRef;
  selectedParticipant: any;
  hideTimer: any;
  participantsList: any[] = [];

  constructor(
    private dialogRef: MatDialogRef<ParticipantsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private mailService: MailService,
    private el: ElementRef,
    private toastService: ToastService,
  ) {
    console.log("ParticipantsDialogComponent", this.data);
    // this.participantsList = this.data.participantsList.at;
    setTimeout(() => {
      this.data.participantsList.at.map(async (pl) => {
        this.mailService.getNameByFullEmail(pl.a).then(res => {
          pl.name = res;
          this.participantsList.push(pl);
        }).catch(error => {

        });
      });
    }, 100);


  }

  ngOnInit() {
  }

  close(): void {
    this.dialogRef.close();
  }

  openProfile(event, participant) {
    this.selectedParticipant = participant;
    this.selectedParticipant = participant,

    this.showProfile();
    setTimeout(() => {
      let top = event.pageY + 20;
      if (top + 184 > document.body.clientHeight) {
        top = event.pageY - 184 - 48;
      }
      this.profileDialog.nativeElement.style.top = top + "px";
      this.profileDialog.nativeElement.style.left = (this.el.nativeElement.getBoundingClientRect().left + 10) + "px";
      this.profileDialog.nativeElement.style.visibility = "visible";
    }, 100);
  }

  showProfile() {
    if (this.hideTimer) {
      clearTimeout(this.hideTimer);
    }
  }

  hideProfile(forkHide?: boolean) {
    if (forkHide && this.profileDialog?.nativeElement) {
      this.selectedParticipant = null;
    } else {
      this.hideTimer = setTimeout(() => {
        this.selectedParticipant = null;
      }, 500);
    }

  }

  copyEmail(email) {
    this.toastService.show("COPIED_TO_CLIPBOARD");
    CommonUtils.copyToClipboard([email]);
  }
}
